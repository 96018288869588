import React from "react";
import { Box } from "@theme-ui/components";

const CloseEllipse = ({ color = "svgStrokePrimary", size = "iconSmall", ...props }) => (
  <Box
    bg="windowBg"
    sx={{
      alignItems: "center",
      borderRadius: "full",
      justifyContent: "center",
      display: "flex",
      height: "2rem",
      width: "2rem",
    }}
    {...props}>
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="24.153"
      height="24.148"
      viewBox="0 0 17.414 17.414"
      sx={{ width: size, height: size, stroke: color }}>
      <g transform="translate(-978.793 -662.793)">
        <path
          d="M16 16L0 0"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          transform="translate(979.5 663.5)"
        />
        <path
          d="M0 16L16 0"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          transform="translate(979.5 663.5)"
        />
      </g>
    </Box>
  </Box>
);

export default CloseEllipse;
