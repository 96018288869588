import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FacadeAPI } from "../../../rest/RestClient";

import { PORTIN_TYPE } from "../../../components/portin-type/Constants";
import { FLOW } from "../../../pages/phone-number/Constants";
import { NONE_CODE } from "../../../common/Constants";
import { getItems } from "./ItemsSlice";

const INITIAL_BUNDLE_STATE = {
  randomPhoneNumbers: [],
  sessionItemId: "",
  // Flow flags
  flow: FLOW.NONE,
  portinType: PORTIN_TYPE.NONE,

  // GUI values
  province: "",
  poolCode: NONE_CODE,
  phoneNumber: "",
  temporaryNumber: "",
  portinMobileDetails: {
    address: null,
    carrierName: "",
    city: null,
    firstName: null,
    lastName: null,
    oldAccountNumber: "",
    phoneNumber: "",
    postalCode: null,
    province: undefined,
    imei: "",
    pinPassword: "",
  },
  portinLandlineDetails: {
    address: null,
    carrierName: "",
    city: null,
    firstName: null,
    lastName: null,
    oldAccountNumber: "",
    phoneNumber: "",
    postalCode: null,
    province: undefined,
    portInDate: "",
  },
  addressForRegistrationDetails: {
    address: null,
    city: null,
    province: null,
    postalCode: null,
    location: undefined,
  },
  stepCompleted: false,
};
const INITIAL_STATE = {
  // Get Pools API Response
  getPools: {
    loading: false,
    success: false,
    error: null,
  },
  pools: [],
  // Get Random Phone Numbers API Response
  getRandomPhoneNumbers: {
    loading: false,
    success: false,
    error: null,
  },
  getRandomTemporaryNumber: {
    loading: false,
    success: false,
    error: null,
  },
  deletePhoneNumber: {
    loading: false,
    success: false,
    error: null,
  },
  getPhoneNumberObj: {
    loading: false,
    success: false,
    error: null,
  },
  // Save Phone Number API Response
  savePhoneNumber: {
    loading: false,
    success: false,
    error: null,
  },
};

function savePhoneNumberDetailsOnState(state, action) {
  if (!action.payload || action.payload.length === 0) {
    return;
  }

  for (let i = 0; i < action.payload.length; i++) {
    const phoneObj = action.payload[i];
    let phoneStateObj = JSON.parse(JSON.stringify(INITIAL_BUNDLE_STATE));
    phoneStateObj.flow = phoneObj.flow;
    phoneStateObj.stepCompleted = true;
    if (phoneStateObj.flow === FLOW.PICK) {
      phoneStateObj.poolCode = phoneObj.poolCode;
      phoneStateObj.phoneNumber = phoneObj.number;
    } else if (phoneStateObj.flow === FLOW.KEEP) {
      phoneStateObj.portinType = phoneObj.portInType;
      phoneStateObj.temporaryNumber = phoneObj.temporaryNumber;
      if (phoneStateObj.portinType === PORTIN_TYPE.MOBILE) {
        phoneStateObj.portinMobileDetails.address = phoneObj.portInAddress;
        phoneStateObj.portinMobileDetails.carrierName = phoneObj.portInCarrier;
        phoneStateObj.portinMobileDetails.city = phoneObj.portInCity;
        phoneStateObj.portinMobileDetails.firstName = phoneObj.portInFirstName;
        phoneStateObj.portinMobileDetails.lastName = phoneObj.portInLastName;
        phoneStateObj.portinMobileDetails.oldAccountNumber = phoneObj.portInOldAccountNumber;
        phoneStateObj.portinMobileDetails.phoneNumber = phoneObj.number;
        phoneStateObj.portinMobileDetails.postalCode = phoneObj.portInPostalCode;
        phoneStateObj.portinMobileDetails.province = phoneObj.portInProvince;
        phoneStateObj.portinMobileDetails.imei = phoneObj.portInMeidSim;
        phoneStateObj.portinMobileDetails.pinPassword = phoneObj.portInPassword;
      } else if (phoneStateObj.portinType === PORTIN_TYPE.LANDLINE) {
        phoneStateObj.portinLandlineDetails.address = phoneObj.portInAddress;
        phoneStateObj.portinLandlineDetails.carrierName = phoneObj.portInCarrier;
        phoneStateObj.portinLandlineDetails.city = phoneObj.portInCity;
        phoneStateObj.portinLandlineDetails.firstName = phoneObj.portInFirstName;
        phoneStateObj.portinLandlineDetails.lastName = phoneObj.portInLastName;
        phoneStateObj.portinLandlineDetails.oldAccountNumber = phoneObj.portInOldAccountNumber;
        phoneStateObj.portinLandlineDetails.phoneNumber = phoneObj.number;
        phoneStateObj.portinLandlineDetails.postalCode = phoneObj.portInPostalCode;
        phoneStateObj.portinLandlineDetails.province = phoneObj.portInProvince;
        phoneStateObj.portinLandlineDetails.portInDate = phoneObj.portInDate;
      }

      phoneStateObj.addressForRegistrationDetails.address = phoneObj.portInRegAddress;
      phoneStateObj.addressForRegistrationDetails.city = phoneObj.portInRegCity;
      phoneStateObj.addressForRegistrationDetails.province = phoneObj.portInRegProvince;
      phoneStateObj.addressForRegistrationDetails.postalCode = phoneObj.portInRegPostalCode;
      phoneStateObj.addressForRegistrationDetails.location = phoneObj.portInRegLocation;
    }
    state[i] = phoneStateObj;
  }
}

export const getPools = createAsyncThunk("phoneNumber/pools", async () => {
  return await FacadeAPI.GET("/msisdn/pools");
});

export const getRandomPhoneNumbers = createAsyncThunk(
  "phoneNumber/getRandomPhoneNumbers",
  async ({ poolCode, prefix }, { rejectWithValue }) => {
    try {
      return await FacadeAPI.GET(`/msisdn/random`, { poolCode: poolCode, prefix: prefix });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getRandomTemporaryNumber = createAsyncThunk(
  "phoneNumber/getRandomTemporaryNumber",
  async ({ prefix, province, rateCentre }, { rejectWithValue }) => {
    try {
      return await FacadeAPI.GET(
        `/msisdn/randomTemporaryNumber`,
        {
          prefix: prefix,
          province: province,
          rateCentre: rateCentre,
        },
        {
          expectedResponse: "text",
        }
      );
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deletePhoneNumber = createAsyncThunk(
  "session/deletePhoneNumber",
  async (arg, { getState, rejectWithValue }) => {
    const phoneNumber = getState().phoneNumber.phoneNumber;
    if (phoneNumber === "") {
      return;
    }
    const sessionId = getState().session.sessionId;
    try {
      await FacadeAPI.DELETE(`/session/${sessionId}/phoneNumber`, {}, { expectedResponse: "" });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const savePhoneNumber = createAsyncThunk(
  "session/phoneNumber",
  async (request, { getState, dispatch, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    let result;
    try {
      result = await FacadeAPI.PUT(`/session/${sessionId}/phoneNumber`, request, false);
    } catch (err) {
      return rejectWithValue(err);
    }
    dispatch(getItems());
    return result;
  }
);

export const getPhoneNumberObj = createAsyncThunk(
  "session/getPhoneNumberObj",
  async (arg, { getState }) => {
    const sessionId = getState().session.sessionId;
    return await FacadeAPI.GET(`/session/${sessionId}/phoneNumbers`);
  }
);

const phoneNumberSlice = createSlice({
  name: "phoneNumber",
  initialState: INITIAL_STATE,
  reducers: {
    setPhoneNumberBundleInitialState(state, action) {
      const activeBundleIndex = action.payload;
      let newBundleState = {};
      newBundleState[activeBundleIndex] = JSON.parse(JSON.stringify(INITIAL_BUNDLE_STATE));
      return {
        ...state,
        ...newBundleState,
      };
    },
    setPhoneNumberState(state, action) {
      const activeTabIndex = action.payload.activeTabIndex;
      let newBundleState = {};
      newBundleState[activeTabIndex] = { ...state[activeTabIndex], ...action.payload.newState };
      return {
        ...state,
        ...newBundleState,
      };
    },
    clearGetRandomPhoneNumbersApiResponse(state) {
      return {
        ...state,
        getRandomPhoneNumbers: INITIAL_STATE.getRandomPhoneNumbers,
      };
    },
    clearGetRandomTemporaryNumbersApiResponse(state) {
      return {
        ...state,
        getRandomTemporaryNumber: INITIAL_STATE.getRandomTemporaryNumber,
      };
    },
    clearApiResponses(state) {
      return {
        ...state,
        getRandomPhoneNumbers: INITIAL_STATE.getRandomPhoneNumbers,
        getRandomTemporaryNumber: INITIAL_STATE.getRandomTemporaryNumber,
        savePhoneNumber: INITIAL_STATE.savePhoneNumber,
        deletePhoneNumber: INITIAL_STATE.deletePhoneNumber,
      };
    },
    clearSavePhoneNumberApiResponse(state) {
      return {
        ...state,
        savePhoneNumber: INITIAL_STATE.savePhoneNumber,
      };
    },
  },
  extraReducers: {
    [getPools.pending]: (state) => {
      state.getPools.loading = true;
      state.getPools.success = false;
      state.getPools.error = null;
      state.pools = [];
    },
    [getPools.fulfilled]: (state, action) => {
      state.getPools.loading = false;
      state.getPools.success = true;
      state.pools = action.payload;
    },
    [getPools.rejected]: (state, action) => {
      state.getPools.loading = false;
      state.getPools.error = action.error;
    },

    [getRandomPhoneNumbers.pending]: (state) => {
      state.getRandomPhoneNumbers.loading = true;
      state.getRandomPhoneNumbers.success = false;
      state.getRandomPhoneNumbers.error = null;
    },
    [getRandomPhoneNumbers.fulfilled]: (state) => {
      state.getRandomPhoneNumbers.loading = false;
      state.getRandomPhoneNumbers.success = true;
    },
    [getRandomPhoneNumbers.rejected]: (state, action) => {
      state.getRandomPhoneNumbers.loading = false;
      state.getRandomPhoneNumbers.error = action.payload;
    },

    [getRandomTemporaryNumber.pending]: (state) => {
      state.getRandomTemporaryNumber.loading = true;
      state.getRandomTemporaryNumber.success = false;
      state.getRandomTemporaryNumber.error = null;
    },
    [getRandomTemporaryNumber.fulfilled]: (state) => {
      state.getRandomTemporaryNumber.loading = false;
      state.getRandomTemporaryNumber.success = true;
    },
    [getRandomTemporaryNumber.rejected]: (state, action) => {
      state.getRandomTemporaryNumber.loading = false;
      state.getRandomTemporaryNumber.error = action.payload;
    },

    [getPhoneNumberObj.pending]: (state) => {
      state.getPhoneNumberObj.loading = true;
      state.getPhoneNumberObj.success = false;
      state.getPhoneNumberObj.error = null;
    },
    [getPhoneNumberObj.fulfilled]: (state, action) => {
      state.getPhoneNumberObj.loading = false;
      state.getPhoneNumberObj.success = true;
      savePhoneNumberDetailsOnState(state, action);
    },
    [getPhoneNumberObj.rejected]: (state, action) => {
      state.getPhoneNumberObj.loading = false;
      state.getPhoneNumberObj.error = action.error;
    },

    [savePhoneNumber.pending]: (state) => {
      state.savePhoneNumber.loading = true;
      state.savePhoneNumber.success = false;
      state.savePhoneNumber.error = null;
    },
    [savePhoneNumber.fulfilled]: (state) => {
      state.savePhoneNumber.loading = false;
      state.savePhoneNumber.success = true;
    },
    [savePhoneNumber.rejected]: (state, action) => {
      state.savePhoneNumber.loading = false;
      state.savePhoneNumber.error = action.payload;
    },

    [deletePhoneNumber.pending]: (state) => {
      state.deletePhoneNumber.loading = true;
      state.deletePhoneNumber.success = false;
      state.deletePhoneNumber.error = null;
    },
    [deletePhoneNumber.fulfilled]: (state) => {
      state.deletePhoneNumber.loading = false;
      state.deletePhoneNumber.success = true;
    },
    [deletePhoneNumber.rejected]: (state, action) => {
      state.deletePhoneNumber.loading = false;
      state.deletePhoneNumber.error = action.error;
    },
  },
});

export const {
  setPhoneNumberState,
  clearApiResponses,
  setPhoneNumberBundleInitialState,
  clearGetRandomPhoneNumbersApiResponse,
  clearGetRandomTemporaryNumbersApiResponse,
  clearSavePhoneNumberApiResponse,
} = phoneNumberSlice.actions;
export default phoneNumberSlice.reducer;
