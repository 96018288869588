import React from "react";
import { Box } from "@theme-ui/components";

const TooltipInfo = ({ color = "svgStrokePrimary", size = "iconMedium", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    sx={{ width: size, height: size, stroke: color, flexShrink: 0 }}
    {...props}>
    <g fill="#fff" stroke={color} strokeWidth="1" opacity="0.4">
      <circle cx="12" cy="12" r="12" stroke="none" />
      <circle cx="12" cy="12" r="11.5" fill="none" />
    </g>
    <g transform="translate(10.133 5.813)" opacity="0.4">
      <path
        d="M0,0V3.611"
        transform="translate(3.611 11.453) rotate(90)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M0,0V1.8"
        transform="translate(1.804 4.832) rotate(90)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M12022,10207v6.618"
        transform="translate(-12020.196 -10202.168)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <circle cx="1.556" cy="1.556" r="1.556" transform="translate(0.311 -0.813)" fill={color} />
    </g>
  </Box>
);

export default TooltipInfo;
