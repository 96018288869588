import { baseTheme } from "./base-theme";

export const btlTheme = {
  ...baseTheme,
  shadows: {
    ...baseTheme.shadows,
    sm: "none",
  },
  colors: {
    ...baseTheme.colors,
    windowBg: "#f3f3f3",
    headerBg: "#FFFFFF",
    invertedTextColor: "#333",
    secondaryBg: "#FFFFFF",
    ternaryBg: "#FFFFFF",
    secondary: "#d0302a",
    ternary: "#333",
    quaternary: "#333",
    cardBg: "#fff",
    cardBorderColor: "#f3f3f3",
    cardBorderHoverColor: "#f3f3f3",
    selectedCardBg: "#fff",
    selectedCardTextColor: "#333",
    svgStrokePrimary: "#db8b8b", // "#bc544b",
    svgStrokeSecondary: "#db8b8b", // "#bc544b",
    bulletBgPrimary: "#d0302a",
    hrTriangle: "#d3d3d3",
    estimatedTaxesColor: "#d0302a",
    noResultsTextColor: "#d0302a",
    button: {
      ...baseTheme.colors.button,
      background: "#d0302a",
      disabledBackground: "#f3f3f3",
      hoverBackground: "#333333",
      color: "#ffffff",
      hoverColor: "#d0302a",
      disabledColor: "#8c8c8c",
    },
  },
};
