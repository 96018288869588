import React from "react";
import { Heading } from "@theme-ui/components";

const Heading4 = ({ children, ...props }) => {
  return (
    <Heading variant="heading4" {...props}>
      {children}
    </Heading>
  );
};

export default Heading4;
