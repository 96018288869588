import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";

const INITIAL_STATE = {
  loadAccountCreditCard: {
    loading: false,
    success: false,
    error: null,
  },
  creditCard: null,
};

export const loadAccountCreditCard = createAsyncThunk(
  "accountCreditCard/loadAccountCreditCard",
  async (request, { getState, rejectWithValue }) => {
    const accountId = getState().personalInformation.accountId;
    try {
      return await FacadeAPI.GET(`/accountDetails/${accountId}/creditCard`, request, {
        noTimeout: true,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const accountCreditCardSlice = createSlice({
  name: "accountCreditCard",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [loadAccountCreditCard.pending]: (state) => {
      state.loadAccountCreditCard.loading = true;
      state.loadAccountCreditCard.success = false;
      state.loadAccountCreditCard.error = null;
      state.creditCard = null;
    },
    [loadAccountCreditCard.fulfilled]: (state, action) => {
      state.loadAccountCreditCard.loading = false;
      state.loadAccountCreditCard.success = true;
      state.creditCard = action.payload;
    },
    [loadAccountCreditCard.rejected]: (state, action) => {
      state.loadAccountCreditCard.loading = false;
      state.loadAccountCreditCard.success = false;
      state.loadAccountCreditCard.error = action.error;
    },
  },
});

export default accountCreditCardSlice.reducer;
