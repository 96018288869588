import React from "react";
import { Box } from "@theme-ui/components";

const Chevron = ({ color = "primary", size = "iconSmall", rotate = "-90", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 18"
    {...props}
    sx={{ width: size, height: size, fill: color, ...props.sx }}>
    <path
      transform={`rotate(${rotate} 11.752121925354004,11.690443992614748) `}
      d="m8.99091,18.50426c-0.14124,0 -0.28261,-0.06055 -0.39044,-0.1815c-0.21579,-0.24204 -0.21579,-0.634 0,-0.87588l5.132,-5.75645l-5.132,-5.75645c-0.21579,-0.24204 -0.21579,-0.634 0,-0.87588s0.56522,-0.24204 0.78088,0l5.52243,6.19439c0.21579,0.24204 0.21579,0.634 0,0.87588l-5.52243,6.19439c-0.10782,0.12095 -0.2492,0.1815 -0.39044,0.1815z"
    />
  </Box>
);

export default Chevron;
