import React from "react";
import { Box } from "@theme-ui/components";

const ChooseDifferentAddress = ({
  fill = "#fff",
  color1 = "disabledCardTextColor",
  color2 = "svgStrokePrimary",
  size = "iconLarge",
  ...props
}) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.278 74.68"
    sx={{ width: size, height: size }}
    {...props}>
    <g transform="translate(1.5 1.5)">
      <g>
        <path
          d="M43.346 13.7v27.122H4.6V13.7"
          fill={fill}
          stroke={color1}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          transform="translate(1.554 2.71)"
        />
        <path
          d="M13.98 11a6.128 6.128 0 0 1-6.154 6.154A5.939 5.939 0 0 1 1.9 11"
          fill={fill}
          stroke={color1}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          transform="translate(-1.9 -.744)"
        />
        <path
          d="M19.68 11a6.128 6.128 0 0 1-6.154 6.154A5.939 5.939 0 0 1 7.6 11"
          fill={fill}
          stroke={color1}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          transform="translate(5.391 -.744)"
        />
        <path
          d="M25.208 11A6.154 6.154 0 0 1 12.9 11"
          fill={fill}
          stroke={color1}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          transform="translate(12.171 -.744)"
        />
        <path
          d="M19 11a6.154 6.154 0 0 0 12.308 0"
          fill={fill}
          stroke={color1}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          transform="translate(19.974 -.744)"
        />
        <path
          d="M52.953 16.756L46.344 6.5H8.51L1.9 16.756z"
          fill={fill}
          stroke={color1}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          transform="translate(-1.9 -6.5)"
        />
        <path
          d="M0 0H9.117V9.117H0z"
          fill={fill}
          stroke={color1}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          transform="rotate(180 18.519 16.288)"
        />
        <path
          d="M0 0H9.117V19.057H0z"
          fill={fill}
          stroke={color1}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3px"
          transform="rotate(180 11.247 21.331)"
        />
      </g>
      <g
        stroke={color2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3px"
        transform="translate(41.253 12.075)">
        <path
          fill={fill}
          d="M61.025 32.315A20.53 20.53 0 0 0 40.057 11.8C29.117 12.258 20 21.375 20 32.315a19.74 19.74 0 0 0 2.735 10.256L36.41 69.009c1.6 3.191 6.382 3.191 8.205 0L58.29 42.571a19.169 19.169 0 0 0 2.735-10.256z"
          transform="translate(-20 -11.798)"
        />
        <circle cx="7.521" cy="7.521" r="7.521" fill="none" transform="translate(12.991 12.995)" />
      </g>
    </g>
  </Box>
);

export default ChooseDifferentAddress;
