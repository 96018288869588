import React from "react";
import { Box } from "@theme-ui/components";

const HomeDelivery = ({ color = "svgStrokePrimary", size = "iconLarge", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 54"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <g transform="translate(-979.228 -188.64)">
      <line
        x2="18.322"
        transform="translate(997.406 233.047)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M41.54,38.641h3.913V31.9a9.6,9.6,0,0,0-1.417-5,10.629,10.629,0,0,0-4.041-3.8,11.713,11.713,0,0,0-5.562-1.4H29.441V17.471H1v21.17H5.033"
        transform="translate(983 194.406)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M13.7,38.64a4.234,4.234,0,1,1-4.235-4.234A4.234,4.234,0,0,1,13.7,38.64Z"
        transform="translate(983 194.406)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M39.776,38.64a4.234,4.234,0,1,1-4.235-4.234,4.234,4.234,0,0,1,4.235,4.234Z"
        transform="translate(984.444 194.406)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M36.1,16.819l7.912-7.91L36.1,1"
        transform="translate(984.444 193)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="28.579"
        transform="translate(999.873 201.982)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="21.17"
        transform="translate(989.288 194.146)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x1="8.005"
        transform="translate(984 201.982)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        opacity="0.4"
      />
      <line
        y2="10.621"
        transform="translate(1012.437 219.14)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        opacity="0.4"
      />
      <line
        x1="6.404"
        transform="translate(1015.784 223.157)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        opacity="0.4"
      />
    </g>
  </Box>
);

export default HomeDelivery;
