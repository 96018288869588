import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";

export const getApiStatus = createAsyncThunk("apiStatus/getApiStatus", async () => {
  return await FacadeAPI.GET("/status");
});

const apiStatusSlice = createSlice({
  name: "apiStatus",
  initialState: {
    loading: false,
    error: null,

    apiStatus: null,
  },
  reducers: {},
  extraReducers: {
    [getApiStatus.pending]: (state) => {
      state.loading = true;
    },
    [getApiStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.apiStatus = action.payload;
    },
    [getApiStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default apiStatusSlice.reducer;
