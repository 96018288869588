import React, { forwardRef } from "react";
import { Box } from "@theme-ui/components";
import SVG from "../svg";

const RadioChecked = ({ ...props }) => (
  <SVG {...props}>
    <path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
  </SVG>
);

const RadioUnchecked = ({ ...props }) => (
  <SVG {...props}>
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
  </SVG>
);

const RadioIcon = ({ ...props }) => (
  <>
    <RadioChecked
      {...props}
      sx={{
        display: "none",
        "input:checked ~ &": {
          display: "block",
        },
      }}
    />
    <RadioUnchecked
      {...props}
      sx={{
        display: "block",
        "input:checked ~ &": {
          display: "none",
        },
      }}
    />
  </>
);

const OSRadio = forwardRef(({ id, className, variant = "radio", onChange, ...props }, ref) => (
  <Box
    className={className}
    id={id}
    sx={{
      ":hover": {
        cursor: "pointer",
      },
    }}>
    <Box
      ref={ref}
      as="input"
      type="radio"
      onChange={onChange}
      {...props}
      sx={{
        position: "absolute",
        opacity: 0,
        zIndex: -1,
        width: 1,
        height: 1,
        overflow: "hidden",
      }}
    />
    <Box
      as={RadioIcon}
      aria-hidden="true"
      variant={variant}
      className={className}
      sx={{
        mr: 2,
        ...props.sx,
      }}
    />
  </Box>
));

export default OSRadio;
