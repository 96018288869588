import React from "react";
import { Box } from "@theme-ui/components";

const SuccessIcon = ({ size = "iconSmall", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    sx={{ width: size, height: size }}
    {...props}>
    <g transform="translate(-768 -1395.502)">
      <circle cx="11" cy="11" r="11" transform="translate(768 1395.502)" fill="#39bf11" />
      <path
        d="m14715.718-1073.2 4.328 4.327 7.514-7.514"
        transform="translate(-13942.639 2479.381)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
    </g>
  </Box>
);

export default SuccessIcon;
