import React from "react";
import { Flex } from "@theme-ui/components";
import { OSContainer } from "../../components/base";
import { OSIcon } from "../../components/icons";
import BaseSkeleton from "./BaseSkeleton";

const DocumentSkeleton = () => {
  return (
    <>
      <OSContainer variant="action-section-order-confirmation">
        <Flex
          mx={0}
          my={2}
          sx={{
            flexGrow: "8",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 5,
          }}>
          <OSIcon name="document" />
          <BaseSkeleton width="20rem" height="2rem" bg="#fff" centered={false} />
        </Flex>
        <Flex py={1} sx={{ gap: 4 }}>
          <BaseSkeleton width="5rem" height="2rem" bg="#fff" rounded />
          <BaseSkeleton width="5rem" height="2rem" bg="#fff" rounded />
        </Flex>
      </OSContainer>
    </>
  );
};

export default DocumentSkeleton;
