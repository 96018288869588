import { baseTheme } from "../config/themes/base-theme";
import { strataTheme } from "../config/themes/strata-theme";
import { elTheme } from "../config/themes/el-theme";
import { btlTheme } from "../config/themes/btl-theme";
import { chatTheme } from "../config/themes/chat-theme";

import strata_logo from "../resources/images/logos/strata_logo.png";
import btl_logo from "../resources/images/logos/btl_logo.png";
import btl_logo_mobile from "../resources/images/logos/btl_logo_mobile.png";
import base_header_background from "../resources/images/header-background.png";
import el_logo from "../resources/images/logos/eastlink_logo.svg";
import sky_switch_logo from "../resources/images/logos/sky_switch.jpg";
import base_logo from "../resources/images/logos/base_logo.svg";
import base_logo_mobile from "../resources/images/logos/base_logo_mobile.svg";
import chat_logo from "../resources/images/logos/chat_logo.png";

export const Providers = {
  el: {
    theme: elTheme,
    logo: { desktopValue: el_logo, mobileValue: el_logo },
    appleStoreLink: "https://itunes.apple.com/ca/app/eastlink-wireless-selfcare/id1099228386",
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.eastlink.selfcare",
  },

  base: {
    theme: baseTheme,
    headerBackgroundImage: base_header_background,
    logo: { desktopValue: base_logo, mobileValue: base_logo_mobile },
  },

  chat: {
    theme: chatTheme,
    logo: { desktopValue: chat_logo, mobileValue: chat_logo },
  },

  skySwitch: {
    theme: elTheme,
    logo: { desktopValue: sky_switch_logo, mobileValue: sky_switch_logo },
  },

  btl: {
    theme: btlTheme,
    logo: { desktopValue: btl_logo, mobileValue: btl_logo_mobile },
  },

  strata: {
    theme: strataTheme,
    logo: { desktopValue: strata_logo, mobileValue: strata_logo },
  },
};
