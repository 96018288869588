import ContentLoader from "react-content-loader";
import { Box } from "@theme-ui/components";

export default ({ width, height, bg = "#f3f3f3", centered = true, rounded = false }) => {
  return (
    <Box sx={{ width, height, mx: centered ? "auto" : "initial" }}>
      <ContentLoader
        speed={2}
        style={{ width: "100%", height: "100%" }}
        backgroundColor={bg}
        foregroundColor="#ecebeb">
        <rect width="100%" height="100%" rx={rounded ? "15" : "0"} ry={rounded ? "15" : "0"} />
      </ContentLoader>
    </Box>
  );
};
