import React from "react";
import { Box } from "@theme-ui/components";

const Search = ({ color = "svgStrokePrimary", size = "iconSmall", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.937 22.944"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <g transform="translate(-0.026 -0.019)">
      <g>
        <path
          d="M8.45,16.9a8.451,8.451,0,1,0-5.975-2.475A8.45,8.45,0,0,0,8.45,16.9Z"
          transform="translate(1.026 1.026)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M6.06,6.06,0,0"
          transform="translate(15.489 15.489)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </Box>
);

export default Search;
