const PAYMENT_METHOD = {
  CARD_PAYMENT: "CARD_PAYMENT",
  PAYPAL_PAYMENT: "PAYPAL_PAYMENT",
  GOOGLE_PAY_PAYMENT: "GOOGLE_PAY_PAYMENT",
  APPLE_PAY_PAYMENT: "APPLE_PAY_PAYMENT",
  PAY_IN_STORE: "PAY_IN_STORE",
};
const PAYMENT_METHOD_CARD = { NEW: "NEW", EXISTING: "EXISTING" };

export { PAYMENT_METHOD, PAYMENT_METHOD_CARD };
