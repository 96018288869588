import React from "react";
import { Flex, Heading } from "@theme-ui/components";
import { useIntl } from "react-intl";

import CartItem from "./CartItem";
import Separator from "../separator";
import { Heading2 } from "../heading";
import { ITEM_CATEGORY } from "../../common/Constants";

/**
 * @typedef {Object} ItemAttribute
 * @property {Number} id                          - Item attribute id.
 * @property {String} code                        - Item attribute code.
 * @property {String} value                       - Item attribute value.
 */
/**
 * @typedef {Object} ItemPrice
 * @property {Number} price                        - The price.
 * @property {String} type                         - Price type.
 * @property {Number} termsAmount                  - Price terms - amount.
 * @property {String} termsUnit                    - Price terms - unit.
 */
/**
 * @typedef {Object} SessionItem
 * @property {Number} id                          - Item's id.
 * @property {String} sku                         - Item's sku.
 * @property {String} category                    - Item's category.
 * @property {String} thumbnailURL                - Item's thumbnail URL.
 * @property {String} shortDescription            - Item's short description.
 * @property {ItemPrice[]} prices                 - List of item's prices.
 * @property {ItemAttribute[]} attributes         - List of item's attributes.
 */
/**
 * Renders the cart items.
 *
 * @param {Object} props Component props.
 * @param {Boolean} props.showShipping True if the shipping item should be displayed.
 * @param {SessionItem[]} props.items Cart items.
 */

const CartContent = ({ showShipping, plusTax, showPrices, items }) => {
  const intl = useIntl();

  const bundles = items.filter((item) => item.category === ITEM_CATEGORY.BUNDLE);
  const accessories = items.filter((item) => item.category === ITEM_CATEGORY.ACCESSORY);
  const shipping = items.find((item) => item.category === ITEM_CATEGORY.SHIPPING);

  let lineNo = 0;
  const buildCartItemComponent = (index, item) => {
    if (!showShipping && item.category === ITEM_CATEGORY.SHIPPING) {
      return null;
    }

    lineNo++;

    let lineDesc = `${item.contactFirstName} ${item.contactLastName}`.trim();
    lineDesc = lineDesc ? lineDesc : intl.formatMessage({ id: "lbl.line" }) + ` ${lineNo}`;

    return (
      <React.Fragment key={index}>
        {index > 0 && <Separator mt={4} mb={2} />}
        <CartItem
          item={item}
          plusTax={plusTax}
          showPrices={showPrices}
          lineDescription={lineDesc}
        />
      </React.Fragment>
    );
  };

  const buildAccessoriesGroup = (items) => {
    return (
      <React.Fragment key="accessories">
        {bundles && bundles.length > 0 && <Separator mt={4} mb={2} />}
        <Heading2 mb={3}>{intl.formatMessage({ id: "lbl.accessories" })}</Heading2>
        {items
          .slice()
          .sort((a, b) => a.id - b.id)
          .map((item, index) => (
            <CartItem key={index} item={item} plusTax={plusTax} showPrices={showPrices} />
          ))}
      </React.Fragment>
    );
  };

  return (
    <Flex
      px={[8, 8, 9]}
      py={[4, 4, 9]}
      bg="secondaryBg"
      sx={{
        flexDirection: "column",
        borderTopLeftRadius: ["0", "0", "xl"],
        borderTopRightRadius: ["0", "0", "xl"],
      }}>
      <Heading
        variant="shoppingCartTitle"
        mt={2}
        mb={6}
        sx={{ display: ["none", "none", "block"] }}>
        {intl.formatMessage({ id: "lbl.shopping-cart" })}
      </Heading>

      {bundles &&
        bundles.length > 0 &&
        bundles
          .slice()
          .sort((a, b) => a.id - b.id)
          .map((item, index) => buildCartItemComponent(index, item))}
      {accessories && accessories.length > 0 && buildAccessoriesGroup(accessories)}
      {showShipping && shipping && buildCartItemComponent(items.length - 1, shipping)}
    </Flex>
  );
};

export default CartContent;
