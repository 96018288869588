import React from "react";
import { Box } from "@theme-ui/components";
import { FormInput } from "../../components/base";
import { useIntl } from "react-intl";
import { NONE_CODE } from "../../common/Constants";

const FieldType = {
  STRING: 1,
  MULTISELECTION: 2, // nr for accounts
  NUMERIC: 3,
  DROPDOWN: 4,
  DATE_TIME: 5,
  IP: 6,
  CHECKDIGIT: 7, // nr yet for OS
  AUTOGENERATED: 8, // nr yet for OS
  PASSWORD: 9, // nr for accounts
  CUSTOM: 10, // nr for accounts
};
const UserDefinedFields = ({ udfs, updateUDFs, reactHookFormHandle }) => {
  const intl = useIntl();
  const changeHandler = (event, udfName) => {
    let udfsUpdated = udfs.map((udfIt) => {
      if (udfIt.name === udfName) {
        return { ...udfIt, value: event.target.value };
      } else {
        return udfIt;
      }
    });
    updateUDFs(udfsUpdated);
  };
  return (
    <Box
      sx={{
        display: "grid",
        gridGap: [2, 2, 2],
        gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"],
        gridAutoRows: "min-content",
      }}>
      {udfs.map((udf) => {
        switch (udf.type) {
          case FieldType.STRING:
          case FieldType.IP:
            return (
              <FormInput
                key={udf.name}
                section="miscInfo"
                isMandatory={udf.mandatory}
                label={udf.label}
                defaultValue={udf.value}
                {...reactHookFormHandle}
                onChange={(event) => changeHandler(event, udf.name)}
                maxLength={udf.maxLen}
                name={udf.name}
                type="text"
              />
            );

          case FieldType.NUMERIC:
            return (
              <FormInput
                key={udf.name}
                section="miscInfo"
                isMandatory={udf.mandatory}
                Label={udf.label}
                defaultValue={udf.value}
                {...reactHookFormHandle}
                validations={{
                  pattern: {
                    value: /^[0-9]+$/i,
                    message: intl.formatMessage({ id: "err.invalid-numeric-field" }),
                  },
                }}
                onChange={(event) => changeHandler(event, udf.name)}
                maxLength={udf.maxLen}
                name={udf.name}
                type="text"
              />
            );

          case FieldType.DROPDOWN:
            return (
              <FormInput
                key={udf.name}
                section="miscInfo"
                type="select"
                isMandatory={udf.mandatory}
                label={udf.label}
                name={udf.name}
                value={udf.value}
                {...reactHookFormHandle}
                validations={{
                  validate: {
                    required: (value) => !udf.mandatory || value !== NONE_CODE,
                  },
                }}
                onChange={(event) => changeHandler(event, udf.name)}>
                {[{ code: NONE_CODE, description: intl.formatMessage({ id: "lbl.none" }) }]
                  .concat(
                    udf.validVal.split(",").map((lang1) => {
                      return { code: lang1, description: lang1 };
                    })
                  )
                  .map((lang) => (
                    <option key={lang.code} value={lang.code}>
                      {lang.description}
                    </option>
                  ))}
              </FormInput>
            );

          case FieldType.DATE_TIME:
            return (
              <FormInput
                key={udf.name}
                section="miscInfo"
                isMandatory={udf.mandatory}
                label={udf.label}
                defaultValue={udf.value}
                {...reactHookFormHandle}
                onChange={(event) => changeHandler(event, udf.name)}
                name={udf.name}
                type="date"
              />
            );
          default:
            return null;
        }
      })}
    </Box>
  );
};
export default UserDefinedFields;
