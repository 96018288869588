import React from "react";
import { Box } from "@theme-ui/components";

const PaymentInformation = ({ color = "svgStrokePrimary", size = "iconLarge", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 54"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <g transform="translate(2 2)">
      <g transform="translate(2 18)">
        <path
          d="M49.9,41.119V17.525A3.132,3.132,0,0,0,46.779,14.4H9.021A3.132,3.132,0,0,0,5.9,17.525v23.75A3.132,3.132,0,0,0,9.021,44.4H46.779A3.38,3.38,0,0,0,49.9,41.119Z"
          transform="translate(-5.9 -14.4)"
          fill="#fff"
          stroke={color}
          strokeWidth="2"
        />
        <g transform="translate(6.557 18.281)">
          <line x2="6.714" fill="#fff" stroke={color} strokeLinecap="round" strokeWidth="2" />
          <line
            x2="3.591"
            transform="translate(10.138)"
            fill="#fff"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            x2="6.714"
            transform="translate(17.154)"
            fill="#fff"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            x2="3.435"
            transform="translate(27.45)"
            fill="#fff"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
        <line
          x2="13.739"
          transform="translate(6.557 23.594)"
          fill="#fff"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          d="M15.152,25.17H11.717A1.733,1.733,0,0,1,10,23.452V20.017A1.733,1.733,0,0,1,11.717,18.3h3.435a1.733,1.733,0,0,1,1.717,1.717v3.435A1.733,1.733,0,0,1,15.152,25.17Z"
          transform="translate(-3.599 -12.205)"
          fill="#fff"
          stroke={color}
          strokeWidth="2"
        />
      </g>
      <g transform="translate(22.014 0)">
        <g>
          <g fill="#fff">
            <path
              d="M14.052,0A14.052,14.052,0,1,1,0,14.052,14.052,14.052,0,0,1,14.052,0Z"
              stroke="none"
            />
            <path
              d="M 14.05168437957764 2.000001907348633 C 10.83256530761719 2.000001907348633 7.806114196777344 3.253591537475586 5.529853820800781 5.529851913452148 C 3.253583908081055 7.806112289428711 1.999994277954102 10.83254241943359 1.999994277954102 14.05165195465088 C 1.999994277954102 17.27076148986816 3.253583908081055 20.29719161987305 5.529853820800781 22.57345199584961 C 7.806114196777344 24.84971237182617 10.83256530761719 26.10330200195312 14.05168437957764 26.10330200195312 C 17.27080535888672 26.10330200195312 20.29724502563477 24.84971237182617 22.57351493835449 22.57345199584961 C 24.84977340698242 20.29720306396484 26.10336494445801 17.27076148986816 26.10336494445801 14.05165195465088 C 26.10336494445801 10.83254241943359 24.84977340698242 7.806102752685547 22.57351493835449 5.529851913452148 C 20.29724502563477 3.253591537475586 17.27080535888672 2.000001907348633 14.05168437957764 2.000001907348633 M 14.05168437957764 1.9073486328125e-06 C 21.81221389770508 1.9073486328125e-06 28.10336494445801 6.291141510009766 28.10336494445801 14.05165195465088 C 28.10336494445801 21.81216239929199 21.81221389770508 28.10330200195312 14.05168437957764 28.10330200195312 C 6.291154861450195 28.10330200195312 -5.7220458984375e-06 21.81216239929199 -5.7220458984375e-06 14.05165195465088 C -5.7220458984375e-06 6.291141510009766 6.291154861450195 1.9073486328125e-06 14.05168437957764 1.9073486328125e-06 Z"
              stroke="none"
              fill="rgba(51,42,134,0.4)"
            />
          </g>
        </g>
        <g transform="translate(12.246 7.813)" opacity="0.4">
          <path
            d="M0,0V3.611"
            transform="translate(3.611 11.453) rotate(90)"
            fill="#332a86"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            d="M0,0V1.8"
            transform="translate(1.804 4.832) rotate(90)"
            fill="#332a86"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            d="M12022,10207v6.618"
            transform="translate(-12020.196 -10202.168)"
            fill="#332a86"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <circle
            cx="1.556"
            cy="1.556"
            r="1.556"
            transform="translate(0.311 -0.813)"
            fill="#332a86"
          />
        </g>
      </g>
    </g>
  </Box>
);

export default PaymentInformation;
