import React from "react";
import { Box } from "@theme-ui/components";

const PhoneNumber = ({ color = "#48c0b6", size = "iconSmall", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.502 17.238"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <g transform="translate(0.501 0.501)">
      <g transform="translate(0 0)">
        <g transform="translate(0.001)">
          <path
            d="M17.318,13.407V15.7a1.529,1.529,0,0,1-1.667,1.529,15.131,15.131,0,0,1-6.6-2.347A14.909,14.909,0,0,1,4.465,10.3,15.131,15.131,0,0,1,2.118,3.667,1.529,1.529,0,0,1,3.64,2H5.933A1.529,1.529,0,0,1,7.462,3.315,9.817,9.817,0,0,0,8,5.463a1.529,1.529,0,0,1-.344,1.613l-.971.971a12.233,12.233,0,0,0,4.587,4.587l.971-.971a1.529,1.529,0,0,1,1.613-.344A9.817,9.817,0,0,0,16,11.855a1.529,1.529,0,0,1,1.315,1.552Z"
            transform="translate(-2.113 -1)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <path
          d="M7.581,6.83A7.22,7.22,0,0,0,.37,14.041m3.687-1a2.524,2.524,0,0,1,2.524-2.523"
          transform="translate(23.33 -0.37) rotate(90)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          opacity="0.4"
        />
      </g>
    </g>
  </Box>
);

export default PhoneNumber;
