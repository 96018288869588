import React from "react";
import { Box } from "@theme-ui/components";

const EditEllipse = ({ color = "svgStrokePrimary", size = "iconSmall", ...props }) => (
  <Box
    bg="windowBg"
    sx={{
      alignItems: "center",
      borderRadius: "full",
      justifyContent: "center",
      display: "flex",
      height: "2rem",
      width: "2rem",
    }}
    {...props}>
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="24.153"
      height="24.148"
      viewBox="0 0 24.153 24.148"
      sx={{ width: size, height: size, stroke: color }}>
      <g transform="translate(-432 -309.852)">
        <g>
          <g>
            <path
              fill="none"
              fillRule="evenodd"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M439 331.5l-6.5 2 2-6.5 12.83-12.831 4.5 4.5L439 331.5z"
            />
            <path
              d="M447.33 314.169l3.086-3.086a2.007 2.007 0 0 1 2.828 0l1.672 1.672a2 2 0 0 1 0 2.828l-3.086 3.086"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M449.58 316.419L438 328"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M434.5 327l1 1h2.5v2.5l1 1"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M433.5 330.5l2 2"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M448.83 312.669l4.5 4.5"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </Box>
  </Box>
);

export default EditEllipse;
