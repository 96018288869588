const Branding = {
  DEFAULT_ISSUING_COUNTRY: "CA",
  existingClient: {
    // visible: false,
    username: {
      // visible: true,
      // label: "Test",
      // mandatory: true,
      minLength: 5,
      maxLength: 10,
    },
  },
  personalDetails: {
    visible: true,
    gender: {
      visible: false,
    },
    title: {
      visible: false,
    },
  },
  governmentID: {
    visible: true,
    secondIDType: {
      visible: false,
    },
    secondIDNumber: {
      visible: false,
    },
  },
  creditScore: {
    visible: true,
  },
  miscInfo: {
    visible: false,
  },
};
export { Branding };
