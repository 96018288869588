import React from "react";
import _ from "lodash";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { Box, Flex, Text } from "@theme-ui/components";
import { ComponentType, NONE_ME_SRV_CODE } from "../../../common/Constants";
import { OSSwitch } from "../../../components/base";
import { Heading3 } from "../../../components/heading";
import Separator from "../../../components/separator";
import { selectMEServiceOrSubProduct } from "../../../config/redux/slices";
import FeatureGroups from "./FeatureGroups";
import OSProductContent from "./OSProductContent";
import * as PlanUtils from "./PlanUtils";
import ServiceFee from "./ServiceFee";

const MutualExclusiveGroup = ({
  services,
  subProducts,
  parentProductCode,
  isMainPlan,
  outerContainerAlreadyHasElements,
  onSelectService,
  onValidateSelected,
  untouchedMandatoryFields,
  plusTax = true,
  allServicesMap,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const toggleServiceSelection = (srv) => {
    !srv.instances[0].selected && onSelectService(srv.rcFee, srv.setupFee, 1);
    const allServices = allServicesMap.get(parentProductCode);
    allServices
      .filter((service) => service.instances[0].selected)
      .forEach((service) => onSelectService(service.rcFee, service.setupFee, -1));
    onValidateSelected(parentProductCode, srv);
  };

  /**
   * Render a service row, containing the service name and the switch/checkbox component.
   * @param {Number} index The service index.
   * @param {Object} service The service object.
   * @returns {JSX.Element}
   **/
  const renderMEServiceRow = (index, service) => {
    let meSrvControlComponent = (
      <OSSwitch
        id={
          "choice-" + parentProductCode + "-" + service.code + "-" + service.instances[0].selected
        }
        name="serviceValue"
        checked={service.instances[0].selected}
        disabled={service.instances[0].selected}
        onChange={() => {
          onServiceOrSubProductUpdate(parentProductCode, service.code, true);
          toggleServiceSelection(service);
        }}
        ml="auto"
      />
    );

    return (
      <React.Fragment key={service.code + index}>
        {PlanUtils.canDisplayService(service) && (
          <Flex sx={{ alignItems: "center" }}>
            {service.osDescription ? (
              PlanUtils.getOsDescriptionDetails(service.osDescription)
            ) : (
              <Text pr={5} variant={"lineDescription"} sx={{ flex: 1, textAlign: "left" }}>
                {service.type === ComponentType.RADIO && service.code === NONE_ME_SRV_CODE
                  ? intl.formatMessage({ id: "lbl.none" })
                  : service.description}
              </Text>
            )}
            <Flex
              sx={{
                gap: [5, 20, 40],
                justifyContent: "flex-end",
                alignItems: "center",
              }}>
              <ServiceFee rcFee={service.rcFee} setupFee={service.setupFee} plusTax={plusTax} />
              {service.visible && meSrvControlComponent}
            </Flex>
          </Flex>
        )}
        <FeatureGroups
          service={service}
          parentProductCode={parentProductCode}
          isMainPlan={isMainPlan}
        />
        <Box mb={6} />
      </React.Fragment>
    );
  };
  /**
   * renderMESubProductRow
   * @param index
   * @param subProduct
   * @returns {JSX.Element}
   */
  const renderMESubProductRow = (index, subProduct, isLast) => {
    let subProductGroupsMap = PlanUtils.groupSubProducts(
      subProduct.subproducts.filter((subProd) => PlanUtils.canDisplayProduct(subProd))
    );
    let serviceGroupsMap = PlanUtils.groupServices(
      PlanUtils.getServicesForDisplay(subProduct.services)
    );
    const subProductDesc = subProduct.osDescription
      ? subProduct.osDescription
      : subProduct.description;
    return (
      <React.Fragment key={subProduct.code + index}>
        <Flex sx={{ flexDirection: "column" }} mt={[1, 0]} pb={subProduct.selected ? 4 : 0}>
          <Flex mb={isLast ? 6 : 3} sx={{ width: "100%", alignItems: "center" }}>
            <Heading3 color="primary" sx={{ width: "75%" }}>
              {subProduct.code === NONE_ME_SRV_CODE
                ? intl.formatMessage({ id: "lbl.none" })
                : subProductDesc}
            </Heading3>

            {!subProduct.assignedByDefault && (
              <OSSwitch
                id={"product-" + subProduct.code + "-" + subProduct.selected}
                name="serviceValue"
                checked={subProduct.selected}
                onChange={() =>
                  onServiceOrSubProductUpdate(parentProductCode, subProduct.code, false)
                }
                ml="auto"
                mr={6}
              />
            )}
          </Flex>

          {subProduct.selected && PlanUtils.canDisplayProduct(subProduct) && (
            <Flex
              sx={{
                border: 1,
                borderColor: "border",
                borderRadius: "2xl",
                px: 6,
                pt: 6,
                flexDirection: "column",
                width: "100%",
              }}>
              <OSProductContent
                productCode={subProduct.code}
                subProductsGroupsMap={subProductGroupsMap}
                servicesGroupsMap={serviceGroupsMap}
                onSelectService={onSelectService}
                onValidateSelected={onValidateSelected}
                untouchedMandatoryFields={untouchedMandatoryFields}
                plusTax={plusTax}
              />
            </Flex>
          )}
        </Flex>
      </React.Fragment>
    );
  };
  /**
   * Update service or subProduct state at service or subProduct update event.
   * @param parentProductCode
   * @param entityCode
   * @param isService
   */
  const onServiceOrSubProductUpdate = (parentProductCode, entityCode, isService) => {
    dispatch(
      selectMEServiceOrSubProduct({
        parentProductCode,
        entityCode,
        isService,
      })
    );
  };

  if (!services && !subProducts) {
    return null;
  }

  let rows = [];

  if (isMainPlan && outerContainerAlreadyHasElements) {
    rows.push(
      <Separator
        bg="cardBorderColor"
        key={parentProductCode + "sep" + (services != null ? services.length : 0)}
      />
    );
  }

  _.each(services, (service, index) => {
    rows.push(renderMEServiceRow(index, services[index]));
  });

  _.each(subProducts, (subProduct, index) => {
    if (subProduct.code !== NONE_ME_SRV_CODE) {
      rows.push(renderMESubProductRow(index, subProducts[index], index === subProducts.length - 1));
    }
  });

  return <>{rows}</>;
};

export default MutualExclusiveGroup;
