import { combineReducers } from "redux";

import apiStatusReducer from "../slices/ApiStatusSlice";
import sessionReducer from "../slices/SessionSlice";
import personalInformationReducer from "../slices/PersonalInformationSlice";
import phoneNumberReducer from "../slices/PhoneNumberSlice";
import configurationReducer from "../slices/ConfigurationSlice";
import countriesReducer from "../slices/CountriesSlice";
import statesReducer from "../slices/StatesSlice";
import ippayReducer from "../slices/IPPaySlice";
import identityTypesReducer from "../slices/IdentityTypesSlice";
import accountUDFsReducer from "../slices/AccountUDFsSlice";
import authReducer from "../slices/AuthSlice";
import maClassReducer from "../slices/MaClassSlice";
import itemsReducer from "../slices/ItemsSlice";
import additionalServicesReducer from "../slices/AdditionalServicesSlice";
import shippingDetailsReducer from "../slices/ShippingDetailsSlice";
import taxReducer from "../slices/TaxSlice";
import brandingReducer from "../slices/BrandingSlice";
import itemPriceReducer from "../slices/ItemPriceSlice";
import documentsReducer from "../slices/DocumentsSlice";
import accountCreditCardReducer from "../slices/AccountCreditCardSlice";
import portabilityReducer from "../slices/PortabilitySlice";
import groupManagementReducer from "../slices/GroupManagementSlice";
import existingLinesReducer from "../slices/ExistingLinesSlice";
import discountsReducer from "../slices/DiscountsSlice";
import serviceAgreementReducer from "../slices/ServiceAgreementSlice";
import accountPinReducer from "../slices/PINSlice";

export default combineReducers({
  branding: brandingReducer,
  apiStatus: apiStatusReducer,
  session: sessionReducer,
  personalInformation: personalInformationReducer,
  configuration: configurationReducer,
  ippay: ippayReducer,
  phoneNumber: phoneNumberReducer,
  countries: countriesReducer,
  states: statesReducer,
  identityTypes: identityTypesReducer,
  accountUDFs: accountUDFsReducer,
  auth: authReducer,
  maClass: maClassReducer,
  items: itemsReducer,
  additionalServices: additionalServicesReducer,
  shippingDetails: shippingDetailsReducer,
  tax: taxReducer,
  itemPrice: itemPriceReducer,
  documents: documentsReducer,
  accountCreditCard: accountCreditCardReducer,
  portability: portabilityReducer,
  groupManagement: groupManagementReducer,
  existingLines: existingLinesReducer,
  discounts: discountsReducer,
  serviceAgreement: serviceAgreementReducer,
  accountPin: accountPinReducer,
});
