import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Text } from "@theme-ui/components";
import AmountPlain from "../../components/AmountPlain";
import Spinner from "../../components/spinner/Spinner";

import { useHistory } from "react-router-dom";
import _ from "lodash";

import Bundle from "./Bundle";
import Accessories from "./accessories/Accessories";
import Layout from "../../components/layout";
import { StyledModalMessage } from "../../components/modals";
import { OSContainer } from "../../components/base";
import {
  clearDeleteItemApiResponse,
  clearUpdateItemApiResponse,
  clearGetAccessoriesPriceOffersApiResponse,
  clearGetCreditLevelDetails,
  clearGetBundleDiscounts,
  clearGetServicesDescriptionFromProductDefinition,
  clearLoadGoodModelUDFs,
  getAccessoriesPriceOffers,
  getItems,
  updateBundleOffers,
  updateDeviceOffers,
  updateItem,
  getCreditLevelDetails,
  saveGroupOperations,
  clearGetBundlePriceApiResponse,
  getExistingLines,
  getBundleDiscounts,
  removeBundleDiscounts,
  loadExistingPlan,
  getServicesDescriptionFromProductDefinition,
  updateItemExtraInfo,
  deleteSession,
  getPosParameter,
  getBundlePriceOffers,
  clearGetTotalDevicesFullPrice,
} from "../../config/redux/slices";
import Store from "store2";
import { CHECKOUT_STEPS } from "../../components/header/Constants";
import {
  ITEM_CATEGORY,
  ComponentType,
  PRICE_TYPE,
  ACCOUNT_STATUS,
  OLD_INSTALLMENT_OPTION,
  PACKAGE_MODIFIED,
  PERSONAL_INFORMATION_FLOW,
  EXTRA_INFO,
  CPE_SKU_CODE,
  NONE_CODE,
  INVALID_FIELDS,
  POS_PARAMETER,
  PRICING_OPTION,
} from "../../common/Constants";
import { getNextStep } from "../../common/StepsUtilities";
import MultipleTabHeader from "../../components/tab/MultipleTabHeader";
import {
  isServiceDiscountEasyTabAssociated,
  isServiceDiscountFromRule,
} from "../../components/plan-price-offer/PlanPriceOffer";
import ErrorHandler from "../../components/error-handler";
import {
  getExtraInfoValues,
  getJsonIgnoreMultiple,
  updateExtraInfo,
} from "../../common/StringUtilities";
import {
  checkEasyTabFlexCompatibility,
  getTotalDevicesFullPrice,
} from "../../config/redux/slices/ItemPriceSlice";
import {
  calculateItemsUsedInstallmentAmount,
  calculateTotalOldInstallmentPayments,
} from "../../config/redux/helpers/ItemPriceSliceHelpers";

const DeviceAndPlan = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { planFlags, ...itemsState } = useSelector((state) => state.items);
  const itemPriceState = useSelector((state) => state.itemPrice);
  const groupManagementState = useSelector((state) => state.groupManagement);
  const existingLinesState = useSelector((state) => state.existingLines);
  const discountsState = useSelector((state) => state.discounts);
  const serviceAgreementState = useSelector((state) => state.serviceAgreement);

  const [loadingBundle, setLoadingBundle] = useState(true);
  const [selectedDevicePriceOffer, setSelectedDevicePriceOffer] = useState(null);
  const updatedPlan = useSelector((state) => state.additionalServices?.updatedPlan);
  const personalInformationData = useSelector((state) => state.personalInformation);
  const [groupManagementError, setGroupManagementError] = useState(null);
  const [existingDeviceDetails, setExistingDeviceDetails] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(!Store("tabindex") ? 0 : Store("tabindex"));
  const [numberOfBundles, setNumberOfBundles] = useState(0);
  const [linesTitles, setLinesTitles] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [accessoryItems, setAccessoryItems] = useState([]);
  const [currentBundlePriceOffers, setCurrentBundlePriceOffers] = useState([]);
  const [changeItem, setChangeItem] = useState(null);
  const [moveToNext, setMoveToNext] = useState();
  const [isEasyTabOfferSelected, setEasyTabOfferSelected] = useState(false);
  const [selfcareProfile, setSelfcareProfile] = useState("");
  const [untouchedMandatoryFields, setUntouchedMandatoryFields] = useState([]);
  const [incompatibleDeviceServices, setIncompatibleDeviceServices] = useState("");
  const [extraInfo, setExtraInfo] = useState("");
  const [simType, setSimType] = useState("");
  const [bYODInfo, setBYODInfo] = useState("");
  const [installmentMessages, setInstallmentMessages] = useState({});

  // 1. Load session items.
  useEffect(() => {
    const loadInitialData = async () => {
      dispatch(getPosParameter(POS_PARAMETER.OS_TERMS_AND_CONDITIONS_URL));
      if (
        (itemsState.getItems.success &&
          itemsState.getItems.items &&
          itemsState.getItems.items.length > 0) ||
        itemsState.getItems.loading
      )
        return;
      await dispatch(clearGetBundlePriceApiResponse());
      dispatch(clearGetTotalDevicesFullPrice());
      dispatch(getItems());
      dispatch(getPosParameter(POS_PARAMETER.ONLINE_STORE_CHAT_URL));
    };

    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Load existing lines.
  useEffect(() => {
    let getExistingLinesRequest = {
      masterAccountId: personalInformationData.accountId,
      accountStatuses: [ACCOUNT_STATUS.ACTIVE, ACCOUNT_STATUS.SUSPENDED],
    };

    if (
      personalInformationData.loaded &&
      personalInformationData.flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE &&
      !existingLinesState.getExistingLines.success
    )
      dispatch(getExistingLines({ getExistingLinesRequest }));
  }, [
    personalInformationData.loaded,
    personalInformationData.flow,
    personalInformationData.accountId,
    existingLinesState.getExistingLines.success,
    dispatch,
  ]);

  // Get account credit level details
  useEffect(() => {
    if (personalInformationData.loaded && !personalInformationData.getCreditLevelDetails.success) {
      dispatch(getCreditLevelDetails(personalInformationData.accountId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalInformationData.loaded, personalInformationData.getCreditLevelDetails.success]);

  const eligibleOnlyWithPlanChange = useCallback(() => {
    if (itemPriceState.bundlePriceOffers && itemPriceState.bundlePriceOffers.length > 0) {
      if (!selectedDevicePriceOffer || selectedDevicePriceOffer.code !== currentItem.sku) {
        return (
          (currentItem.pricingOption === PRICING_OPTION.EASYTAB ||
            currentItem.pricingOption === PRICING_OPTION.EASYTABFLEX) &&
          itemPriceState.bundlePriceOffers
            .flatMap((p) => p.price)
            .map((p) => p.extraInfo)
            .map((e) => getExtraInfoValues(e))
            .some((info) => info[EXTRA_INFO.ELIGIBLE_ONLY_WITH_PLAN_CHANGE] === "Y")
        );
      }
    }
    return false;
  }, [currentItem, itemPriceState, selectedDevicePriceOffer]);

  // Compute installment info message
  const computeInstallmentInfoMessage = useCallback(() => {
    setInstallmentMessages({});
    if (
      currentItem &&
      itemPriceState.bundlePriceOffers &&
      itemPriceState.bundlePriceOffers.length > 0
    ) {
      if (
        currentItem.category !== ITEM_CATEGORY.BUNDLE ||
        !currentItem.sku ||
        currentItem.sku == "" ||
        currentItem.oldInstallmentOption === OLD_INSTALLMENT_OPTION.KEEP
      ) {
        return;
      }

      let bundleDevices = itemsState.getItems.items.filter(
        (item) => item.category === ITEM_CATEGORY.BUNDLE && item.sku && item.sku !== ""
      );
      let totalOldInstallmentPayment = calculateTotalOldInstallmentPayments(bundleDevices);
      let creditLevelDetails = personalInformationData.getCreditLevelDetails.creditLevelDetails;
      let availableAmount =
        Math.round(
          (creditLevelDetails.installmentAvailableAmount +
            totalOldInstallmentPayment +
            Number.EPSILON) *
            100
        ) / 100;
      let flexDeferredPrice = itemPriceState.bundlePriceOffers
        .map((itemPrices) => itemPrices.price)
        .flat()
        .find((price) => price.type === PRICE_TYPE.FLEX_DEFERRED_AMOUNT);

      let easyTabPrice = itemPriceState.bundlePriceOffers
        .map((itemPrices) => itemPrices.price)
        .flat()
        .find((price) => price.type === PRICE_TYPE.RECURRING);
      let selectedPricingOption =
        currentItem?.extraInfo?.length > 0
          ? getExtraInfoValues(currentItem.extraInfo)[EXTRA_INFO.SELECTED_PRICING_OPTION] ??
            currentItem.pricingOption
          : currentItem.pricingOption;
      let isRequestedPaymentOptionAvailable =
        (selectedPricingOption === PRICING_OPTION.EASYTABFLEX && flexDeferredPrice?.price > 0) ||
        (selectedPricingOption === PRICING_OPTION.EASYTAB && easyTabPrice?.price > 0);
      let changePlanMsg = "";
      if (eligibleOnlyWithPlanChange()) {
        changePlanMsg = "lbl.incompatiblePlanWithEasyTab";
      }
      if (
        (creditLevelDetails.supportFlex &&
          availableAmount >= itemPriceState.totalDevicesFullPrice &&
          isRequestedPaymentOptionAvailable) ||
        currentItem.pricingOption === PRICING_OPTION.RETAIL
      ) {
        if (changePlanMsg.length > 0) {
          setInstallmentMessages({
            changePlanMsg: changePlanMsg,
          });
        }
        return;
      }

      let usedInstallmentAmount = calculateItemsUsedInstallmentAmount(
        bundleDevices.filter((item) => item.id !== currentItem.id)
      );
      availableAmount =
        Math.round((availableAmount - usedInstallmentAmount + Number.EPSILON) * 100) / 100;

      let basedOnResultsMsg = "";
      let paymentOptionMsg = "";
      let limitedCreditMsg = "";
      let increaseUpfrontPaymentMsg = "";
      let forThisLineMsg = bundleDevices.length > 1 ? "lbl.forThisLine" : "";
      let minDownPaymentPrice = itemPriceState.bundlePriceOffers
        .map((itemPrices) => itemPrices.price)
        .flat()
        .find((price) => price.type === PRICE_TYPE.NONE);
      let minimumUpfrontPaymentMsg =
        minDownPaymentPrice?.price > 0 ? "lbl.minimumUpfrontPayment" : "";

      if (creditLevelDetails.supportFlex) {
        //Credit Score A/A+
        if (availableAmount > 0) {
          if (
            flexDeferredPrice?.price > 0 ||
            (selectedPricingOption === PRICING_OPTION.EASYTAB && easyTabPrice?.price > 0)
          ) {
            if (minDownPaymentPrice?.price > 0) {
              basedOnResultsMsg = "lbl.basedOnCreditCheckResult";
              paymentOptionMsg = "lbl.easyTabAndFlexPaymentOption";
            }
          } else if (easyTabPrice?.price > 0) {
            basedOnResultsMsg = "lbl.basedOnCreditCheckFlexIneligible";
            paymentOptionMsg = "lbl.easyTabPaymentOption";
          }
        } else {
          basedOnResultsMsg = "lbl.basedOnCreditCheckAndBalanceFlexIneligible";
          paymentOptionMsg = "lbl.payFullPrice";
        }
      } else {
        basedOnResultsMsg = "lbl.basedOnCreditCheckEasyTabOrFlexIneligible";
        paymentOptionMsg = "lbl.payFullPrice";
        limitedCreditMsg = "lbl.limitedCreditMessage";
        if (
          !creditLevelDetails.installmentAllowed &&
          creditLevelDetails.installmentMaxFinancedAmount <= 0
        ) {
          //Credit score E
          forThisLineMsg = "";
        } else {
          if (availableAmount > 0 && easyTabPrice?.price > 0) {
            basedOnResultsMsg = "lbl.basedOnCreditCheckEligibleFor";
            paymentOptionMsg = "lbl.easyTabPaymentOption";
            increaseUpfrontPaymentMsg = "lbl.chooseToIncreaseUpfrontPayment";
          } else if (currentItem.pricingOption === PRICING_OPTION.EASYTAB) {
            basedOnResultsMsg = "lbl.basedOnCreditCheckAndBalanceEasyTabIneligible";
          }
          if (currentItem.pricingOption === PRICING_OPTION.EASYTABFLEX) {
            basedOnResultsMsg = "lbl.basedOnCreditCheckFlexIneligible";
          }
        }
      }
      setInstallmentMessages({
        basedOnResultsMsg: basedOnResultsMsg,
        paymentOptionMsg: paymentOptionMsg,
        forThisLineMsg: forThisLineMsg,
        minimumUpfrontPaymentMsg: minimumUpfrontPaymentMsg,
        limitedCreditMsg: limitedCreditMsg,
        increaseUpfrontPaymentMsg: increaseUpfrontPaymentMsg,
        changePlanMsg: changePlanMsg,
        limitedCreditAmount: creditLevelDetails.accountCreditLimit,
        balanceAmount: availableAmount,
        minimumPayment: minDownPaymentPrice?.price ?? 0,
      });
    }
  }, [
    itemsState.getItems.items,
    currentItem,
    itemPriceState.totalDevicesFullPrice,
    itemPriceState.bundlePriceOffers,
    personalInformationData.getCreditLevelDetails.creditLevelDetails,
    eligibleOnlyWithPlanChange,
  ]);

  useEffect(() => {
    if (!itemPriceState.getTotalDevicesFullPrice.success) {
      dispatch(getTotalDevicesFullPrice());
    }
  }, [dispatch, itemPriceState.getTotalDevicesFullPrice.success]);

  // If account credit level details and item price offers were loaded successfully, set the credit info message
  useEffect(() => {
    if (
      currentItem &&
      itemPriceState.getBundlePriceOffers.success &&
      personalInformationData.getCreditLevelDetails.success &&
      personalInformationData.getCreditLevelDetails.creditLevelDetails &&
      itemPriceState.getTotalDevicesFullPrice.success
    ) {
      computeInstallmentInfoMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentItem,
    personalInformationData.getCreditLevelDetails,
    itemPriceState.getBundlePriceOffers.success,
    itemPriceState.getTotalDevicesFullPrice.success,
  ]);

  // Check for non eligible easyTab flex items.
  useEffect(() => {
    if (!itemPriceState.checkEasyTabFlexCompatibility.success) {
      dispatch(checkEasyTabFlexCompatibility());
    }
  }, [dispatch, itemPriceState.checkEasyTabFlexCompatibility.success]);

  // Get tab title for a given session item
  const getTabTitle = (item) => {
    let tabTitle = {};
    if (item.category === ITEM_CATEGORY.BUNDLE) {
      tabTitle.firstName = `${item.contactFirstName}`.trim();
      tabTitle.lastName = `${item.contactLastName}`.trim();
      let existingSADetails =
        item.associatedSaID > 0
          ? existingLinesState.getExistingLines.existingLines.find((existingLine) => {
              return existingLine.id === item.associatedSaID;
            })
          : null;
      if (existingSADetails) {
        tabTitle.serviceNumber = existingSADetails.serviceNumber;
      }
    } else {
      tabTitle.accesoriesTitle = intl.formatMessage({ id: "lbl.accessories" });
    }
    return tabTitle;
  };

  // 2. If session items were successfully loaded, load device and plan price offers.
  useEffect(() => {
    // todo something incase of error
    if (
      itemsState.getItems.success &&
      personalInformationData.loaded &&
      (personalInformationData.flow !== PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE ||
        existingLinesState.getExistingLines.success)
    ) {
      let bundles = itemsState.getItems.items.filter(
        (item) => item.category === ITEM_CATEGORY.BUNDLE
      );
      const accessories = itemsState.getItems.items.filter(
        (item) => item.category === ITEM_CATEGORY.ACCESSORY
      );
      if (accessories.length > 0) {
        bundles.push(accessories[0]);
        setAccessoryItems(accessories);
      } else if (activeTabIndex > bundles.length - 1) {
        setActiveTabIndex(bundles.length - 1);
        return;
      }

      setSelfcareProfile(
        bundles[activeTabIndex].selfcareProfile &&
          bundles[activeTabIndex].selfcareProfile.length > 0
          ? bundles[activeTabIndex].selfcareProfile
          : ""
      );
      setSimType(bundles[activeTabIndex].simType);

      setNumberOfBundles(bundles.length);
      setCurrentItem(bundles[activeTabIndex]);

      Store.session.transact(
        "kwExtraData",
        (obj) => {
          obj.itemId = bundles[activeTabIndex].itemId;
        },
        {}
      );

      if (linesTitles.length !== bundles.length) {
        setLinesTitles(bundles.map(getTabTitle));
      }

      const priceOffersLoaded = Object.keys(itemPriceState.bundlePriceOffers).length > 0;
      if (!priceOffersLoaded) {
        dispatch(getBundlePriceOffers({ itemId: bundles[activeTabIndex].id }));
      }

      if (accessories.length > 0) {
        const accPriceOffers = itemPriceState.accessoriesPriceOffers;
        let isAccPriceOffersLoaded = accPriceOffers && accPriceOffers.length > 0;
        if (isAccPriceOffersLoaded) {
          const accSkus = accessories.map((item) => item.sku);
          const priceSkus = accPriceOffers.map((price) => price.code);
          isAccPriceOffersLoaded = _.without(accSkus, ...priceSkus).length === 0;
        }
        !isAccPriceOffersLoaded && dispatch(getAccessoriesPriceOffers());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    itemsState.getItems.success,
    existingLinesState.getExistingLines.success,
    personalInformationData.loaded,
    personalInformationData.flow,
    activeTabIndex,
  ]);

  // 3. If device and plan price offers were successfully loaded, set the loading indicator to false.
  useEffect(() => {
    const isItemsLoaded = !itemsState.getItems.loading && itemsState.getItems.success;
    if (!isItemsLoaded) {
      setLoadingBundle(true);
      return;
    }

    const isBundleOffersLoaded =
      itemPriceState.getBundlePriceOffers.success ||
      (itemPriceState.bundlePriceOffers && itemPriceState.getBundlePriceOffers.length > 0) ||
      (!itemPriceState.getBundlePriceOffers.loading &&
        itemPriceState.getBundlePriceOffers.error === null);

    const isAccPriceOffersLoaded =
      itemPriceState.getAccessoriesPriceOffers.success ||
      (itemPriceState.accessoriesPriceOffers && itemPriceState.accessoriesPriceOffers.length > 0) ||
      (!itemPriceState.getAccessoriesPriceOffers.loading &&
        itemPriceState.getAccessoriesPriceOffers.error);

    const isDiscountsLoaded =
      isBundleOffersLoaded &&
      discountsState.getBundleDiscounts.success &&
      !discountsState.getBundleDiscounts.loading;

    if (bundle && isBundleOffersLoaded) {
      setCurrentBundlePriceOffers(itemPriceState.bundlePriceOffers);
    }

    if (bundle) {
      setLoadingBundle(
        !isBundleOffersLoaded || !isDiscountsLoaded || existingLinesState.getExistingLines.loading
      );
    } else if (currentItem && currentItem.category === ITEM_CATEGORY.ACCESSORY) {
      setLoadingBundle(!isAccPriceOffersLoaded);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentItem,
    activeTabIndex,
    itemsState.getItems.loading,
    itemsState.getItems.success,
    itemPriceState.getBundlePriceOffers.success,
    itemPriceState.bundlePriceOffers,
    itemPriceState.getBundlePriceOffers.error,
    itemPriceState.getAccessoriesPriceOffers.success,
    itemPriceState.getAccessoriesPriceOffers.error,
    discountsState.getBundleDiscounts.success,
    discountsState.getBundleDiscounts.loading,
    existingLinesState.getExistingLines.loading,
  ]);

  // Load bundle discounts if not already loaded
  useEffect(() => {
    if (
      !itemPriceState.getBundlePriceOffers.loading &&
      itemPriceState.getBundlePriceOffers.success &&
      currentItem &&
      currentItem.packageCode &&
      !(currentItem.id in discountsState.getBundleDiscounts.prices) &&
      itemsState.getItems.success &&
      !discountsState.getBundleDiscounts.loading
    ) {
      dispatch(getBundleDiscounts({ itemId: currentItem.id }));
    }
  }, [
    currentItem,
    discountsState.getBundleDiscounts.loading,
    discountsState.getBundleDiscounts.prices,
    dispatch,
    itemPriceState.getBundlePriceOffers.loading,
    itemPriceState.getBundlePriceOffers.success,
    itemsState.getItems.success,
  ]);

  // Add current bundle discounts to plan prices
  useEffect(() => {
    if (
      currentItem &&
      discountsState.getBundleDiscounts.prices[currentItem.id] &&
      itemPriceState.bundlePriceOffers.length > 0
    ) {
      let currentOffers = [...itemPriceState.bundlePriceOffers];
      let currentPlanOffer = currentOffers.find((offer) => offer.code === "PLAN");
      let currentPlanOfferPrices = currentPlanOffer?.price;

      let discountServiceFromRulesIndex = currentPlanOfferPrices.findIndex(
        (price) =>
          ([PRICE_TYPE.PLAN_SERVICE_DISCOUNT, PRICE_TYPE.PLAN_SERVICE_IVD].includes(price.type) &&
            isServiceDiscountFromRule(price)) ||
          [
            PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING,
            PRICE_TYPE.PLAN_SERVICE_IVD_EXISTING,
          ].includes(price.type)
      );

      if (discountServiceFromRulesIndex === -1) {
        if (currentPlanOfferPrices) {
          let updatedCurrentPlanOfferPrices = [
            ...currentPlanOfferPrices,
            ...discountsState.getBundleDiscounts.prices[currentItem.id],
          ];

          const newPlanOffer = { ...currentPlanOffer, price: updatedCurrentPlanOfferPrices };
          const newOffers = currentOffers.map((offer) =>
            offer.code !== "PLAN" ? offer : newPlanOffer
          );
          setCurrentBundlePriceOffers(newOffers);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem, discountsState.getBundleDiscounts.prices, itemPriceState.bundlePriceOffers]);

  const updateCurrentItem = (item) => {
    setCurrentItem(item);
  };

  // if the device was deleted, calculate new price offers
  useEffect(() => {
    if (itemsState.deleteItem.success && itemsState.getItems.success) {
      dispatch(clearDeleteItemApiResponse());
      // hide old message until new installment calculations are finished
      setInstallmentMessages({});
      dispatch(clearGetTotalDevicesFullPrice());
      setSelectedDevicePriceOffer(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsState.deleteItem.success, itemsState.getItems.success]);

  // If the update item prices call is successful, perform a redirect to the next page.
  useEffect(() => {
    if (itemsState.updateItem.success && itemsState.getItems.success) {
      dispatch(clearUpdateItemApiResponse());
      dispatch(clearGetBundlePriceApiResponse());
      if (moveToNext) {
        dispatch(saveGroupOperations());
        setSelectedDevicePriceOffer(null);
        setCurrentItem(null);
        setMoveToNext(false);
        if (activeTabIndex === numberOfBundles - 1) {
          Store("tabindex", 0);
          Store.session.transact(
            "kwExtraData",
            (obj) => {
              delete obj.itemId;
            },
            {}
          );
          history.push(
            getNextStep(planFlags, CHECKOUT_STEPS.DEVICE_AND_PLAN, personalInformationData.flow)
              .route
          );
        } else {
          setActiveTabIndex(activeTabIndex + 1);
          Store("tabindex", activeTabIndex + 1);
          setTimeout(window.scrollTo(0, 0), 100);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsState.updateItem.success, planFlags, itemsState.getItems.success]);

  const onSelectDevicePriceOffer = (priceOffer) => {
    setSelectedDevicePriceOffer(priceOffer);
    priceOffer && setEasyTabOfferSelected(priceOffer.code !== currentItem.sku);
  };

  const bundle = itemsState.getItems.items.filter((item) => item.category === ITEM_CATEGORY.BUNDLE)[
    activeTabIndex
  ];

  const accessories =
    currentItem && currentItem.category === ITEM_CATEGORY.ACCESSORY ? currentItem : null;

  useEffect(() => {
    if (itemsState.getItems.success && existingLinesState.getExistingLines.success && bundle) {
      let existingSADetails = existingLinesState.getExistingLines.existingLines.find(
        (existingLine) => {
          return existingLine.id === bundle.associatedSaID;
        }
      );
      if (existingSADetails && bundle.oldInstallmentOption == OLD_INSTALLMENT_OPTION.PAY) {
        setExistingDeviceDetails({
          model: existingSADetails.handset?.model,
          remainingAmount: existingSADetails.installmentCompleteDetailsDTO.remainingAmount,
        });
      }
    }
  }, [
    itemsState.getItems.success,
    existingLinesState.getExistingLines.success,
    existingLinesState.getExistingLines.existingLines,
    bundle,
    activeTabIndex,
  ]);

  useEffect(() => {
    if (
      currentItem &&
      currentItem.associatedSaID > 0 &&
      existingLinesState.getExistingLines.success
    ) {
      let existingSADetails = existingLinesState.getExistingLines.existingLines.find(
        (existingLine) => {
          return existingLine.id === currentItem.associatedSaID;
        }
      );
      if (existingSADetails) {
        let loadExistingPlanRequest = {
          accountId: currentItem.associatedSaID,
          handsetSku: currentItem.sku,
          simSku: existingSADetails.sim.sku,
        };
        dispatch(loadExistingPlan(loadExistingPlanRequest));
      }
    }
  }, [
    currentItem,
    dispatch,
    existingLinesState.getExistingLines.success,
    existingLinesState.getExistingLines.existingLines,
  ]);

  useEffect(() => {
    if (currentItem && currentItem.associatedSaID > 0) {
      setExtraInfo(currentItem.extraInfo);
      if (currentItem.extraInfo && currentItem.extraInfo.length > 0) {
        let itemExtraInfo = getExtraInfoValues(currentItem.extraInfo);
        let incompatibleServices = itemExtraInfo["INCOMPATIBLE_SERVICES"];
        if (incompatibleServices && incompatibleServices.length > 0) {
          let getServicesDescriptionsRequest = {
            itemId: currentItem.id,
            services: incompatibleServices.split(","),
          };
          dispatch(getServicesDescriptionFromProductDefinition(getServicesDescriptionsRequest));
          //remove incompatible services from item extra info
          let newExtraInfo = _.entries(itemExtraInfo)
            .filter((keyValue) => keyValue[0] !== "INCOMPATIBLE_SERVICES")
            .map((keyValue) => keyValue[0] + "=" + keyValue[1])
            .join(";");
          setExtraInfo(newExtraInfo);
          let currentItemRequest = {
            ...currentItem,
            extraInfo: itemExtraInfo,
          };
          dispatch(updateItemExtraInfo(currentItemRequest));
        }
      }
    }
  }, [currentItem, dispatch, existingLinesState.getExistingLines.success]);

  useEffect(() => {
    if (bYODInfo && currentItem) {
      let byod = bYODInfo.byodSku === NONE_CODE ? CPE_SKU_CODE : bYODInfo.byodSku;
      let extraInfoArray = currentItem.extraInfo;
      extraInfoArray = updateExtraInfo(extraInfoArray, EXTRA_INFO.BYOD_SKU, byod);
      if (bYODInfo.bothSim) {
        extraInfoArray = updateExtraInfo(extraInfoArray, EXTRA_INFO.BOTH_SIM, bYODInfo.bothSim);
      }
      setExtraInfo(extraInfoArray);
    }
  }, [bYODInfo, currentItem]);

  useEffect(() => {
    if (serviceAgreementState.getServicesDescriptionFromProductDefinition.success) {
      setIncompatibleDeviceServices(
        serviceAgreementState.getServicesDescriptionFromProductDefinition.services.join(", ")
      );
    }
  }, [serviceAgreementState.getServicesDescriptionFromProductDefinition, dispatch]);

  const validGroupManagement = () => {
    const accessories = itemsState.getItems.items.filter(
      (item) => item.category === ITEM_CATEGORY.ACCESSORY
    );
    if (
      (accessories.length <= 0 && activeTabIndex !== numberOfBundles - 1) ||
      (accessories.length > 0 && activeTabIndex !== numberOfBundles - 2)
    ) {
      return true;
    }
    let oneMemberGroup = _.entries(groupManagementState.groupsView).find((groupViewEntry) => {
      let newGroup = _.every(groupViewEntry[1].lines, "eligible");
      let oneJoined =
        groupViewEntry[1].lines.filter(
          (line) => line.groupType === groupViewEntry[0] && line.didJoin
        ).length === 1;

      return newGroup && oneJoined;
    });
    if (oneMemberGroup) {
      let joinedLine = oneMemberGroup[1].lines.find(
        (line) => line.groupType === oneMemberGroup[0] && line.didJoin
      );
      setGroupManagementError(
        <FormattedMessage
          id="err.group-management-at-least-two-lines"
          values={{ line: joinedLine.serviceIdentifier }}
        />
      );
      return false;
    }
    return true;
  };

  const resetGroupManagementError = () => {
    setGroupManagementError(null);
  };

  const onNext = () => {
    if (bundle) {
      if (!validGroupManagement()) {
        return;
      }
      let untouchedMandatoryFields = [];
      if (bundle.sku == null && (bYODInfo === "" || bYODInfo?.byodSku === NONE_CODE)) {
        untouchedMandatoryFields.push(INVALID_FIELDS.AUTOCOMPLETE);
      }

      updatedPlan.subproducts
        .filter((prod) => prod.mandatoryExclusiveItem)
        .filter((prod) => prod.selected)
        .filter((prod) => prod.services.some((srv) => srv.visible))
        .filter(
          (prod) =>
            !prod.services.some(
              (srv) => srv.instances[0].selected && srv.type === ComponentType.RADIO && srv.visible
            )
        )
        .map((prod) => untouchedMandatoryFields.push(prod.code));
      const isSelfcareProfileEmpty = selfcareProfile === "";
      if (
        isSelfcareProfileEmpty &&
        personalInformationData.flow !== PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE
      ) {
        untouchedMandatoryFields.push(INVALID_FIELDS.SELFCARE_ACCESS);
      }

      if (untouchedMandatoryFields.length > 0) {
        setUntouchedMandatoryFields(untouchedMandatoryFields);
        return;
      }
    }
    setUntouchedMandatoryFields([]);

    let itemUpdateRequest = {
      itemId: currentItem.itemId,
      category: currentItem.category,
      id: currentItem.id,
    };

    if (bundle) {
      itemUpdateRequest = {
        ...itemUpdateRequest,
        sku: bundle.sku,
        planCode: bundle.planCode,
        planShortDescription: bundle.planShortDescription,
        shortDescription: bundle.shortDescription,
        selfcareProfile: selfcareProfile,
        extraInfo: extraInfo,
        simType: simType,
      };

      let packModified = getPackageModified();
      if (
        planFlags.additionalServicesExists ||
        packModified !== PACKAGE_MODIFIED.PACKAGE_NOT_MODIFIED
      ) {
        let updatedPlanModel = { ...updatedPlan, packageModified: packModified };
        itemUpdateRequest = {
          ...itemUpdateRequest,
          bundleModel: Object.assign({ jsonType: "osProduct" }, updatedPlanModel),
        };
      }

      let updatedPrices = selectedDevicePriceOffer?.price ?? [];
      const oldInstallmentPrice = currentItem.prices.find(
        (p) => p.type === PRICE_TYPE.OLD_INSTALLMENT
      );
      if (oldInstallmentPrice) {
        updatedPrices = [...updatedPrices, oldInstallmentPrice];
      }
      if (
        currentItem &&
        (selectedDevicePriceOffer || currentItem.sku == null) &&
        discountsState.getBundleDiscounts.prices[currentItem.id]
      ) {
        const discountsPrices = isEasyTabOfferSelected
          ? discountsState.getBundleDiscounts.prices[currentItem.id].filter((price) =>
              isServiceDiscountEasyTabAssociated(price)
            )
          : discountsState.getBundleDiscounts.prices[currentItem.id].filter(
              (price) => !isServiceDiscountEasyTabAssociated(price)
            );
        updatedPrices = [...updatedPrices, ...discountsPrices].filter(
          (price) => price.type !== "NONE"
        );
      }

      const tabFirstName = linesTitles[activeTabIndex].firstName;
      const tabLastName = linesTitles[activeTabIndex].lastName;

      itemUpdateRequest = {
        ...itemUpdateRequest,
        prices: updatedPrices,
        contactFirstName: tabFirstName,
        contactLastName: tabLastName,
      };
    }
    setMoveToNext(true);
    dispatch(updateItem(itemUpdateRequest));
    if (!bundle?.sku) {
      dispatch(clearLoadGoodModelUDFs());
    }
    setInstallmentMessages({});
  };

  const getPackageModified = () => {
    let packageModified = PACKAGE_MODIFIED.PACKAGE_NOT_MODIFIED;
    if (serviceAgreementState.loadExistingPlan.plan) {
      let product = Object.assign(
        { jsonType: "osProduct" },
        serviceAgreementState.loadExistingPlan.plan
      );

      if (product.code !== updatedPlan.code) {
        packageModified = PACKAGE_MODIFIED.PACKAGE_CHANGED;
      } else {
        //TODO: check why activationDate and rcStartDate are modified
        let propertiesToIgnore = ["visible", "activationDate", "rcStartDate", "packageModified"];
        let initialProductJson = getJsonIgnoreMultiple(product, propertiesToIgnore);
        let updatedPlanJson = getJsonIgnoreMultiple(updatedPlan, propertiesToIgnore);
        if (updatedPlanJson !== initialProductJson) {
          packageModified = PACKAGE_MODIFIED.PACKAGE_UPDATED;
        }
      }
    }
    return packageModified;
  };

  const resetDeleteItemState = () => {
    dispatch(clearDeleteItemApiResponse());
  };

  const resetUpdateItemState = () => {
    dispatch(clearUpdateItemApiResponse());
  };

  const resetGetPriceOffersState = () => {
    if (itemPriceState.getBundlePriceOffers.error) {
      dispatch(clearGetBundlePriceApiResponse());
    }
    if (itemPriceState.getAccessoriesPriceOffers.error) {
      dispatch(clearGetAccessoriesPriceOffersApiResponse());
    }
    if (personalInformationData.getCreditLevelDetails.error) {
      dispatch(clearGetCreditLevelDetails());
    }
    if (discountsState.getBundleDiscounts.error) {
      dispatch(clearGetBundleDiscounts());
    }
  };

  const retryFailedGetPriceOffers = () => {
    if (itemPriceState.getBundlePriceOffers.error) {
      dispatch(clearGetBundlePriceApiResponse());
      if (bundle && bundle.sku !== null) {
        const bundlePriceOffers = itemPriceState.bundlePriceOffers;
        const isDevicePriceOffersLoaded = bundlePriceOffers && bundlePriceOffers.length > 0;
        !isDevicePriceOffersLoaded && dispatch(getBundlePriceOffers({ itemId: bundle.id }));
      }
    }
    if (itemPriceState.getAccessoriesPriceOffers.error) {
      dispatch(clearGetAccessoriesPriceOffersApiResponse());
      if (accessories && accessories.sku !== null) {
        const accPriceOffers = itemPriceState.accessoriesPriceOffers;
        const isAccPriceOffersLoaded = accPriceOffers && accPriceOffers.length > 0;
        !isAccPriceOffersLoaded && dispatch(getAccessoriesPriceOffers());
      }
    }
    if (personalInformationData.getCreditLevelDetails.error) {
      dispatch(clearGetCreditLevelDetails());
      if (personalInformationData.accountId) {
        dispatch(getCreditLevelDetails(personalInformationData.accountId));
      }
    }
    if (discountsState.getBundleDiscounts.error) {
      dispatch(clearGetBundleDiscounts());
      if (
        currentItem &&
        selectedDevicePriceOffer &&
        !(currentItem.id in discountsState.getBundleDiscounts.prices)
      ) {
        dispatch(getBundleDiscounts({ itemId: currentItem.id }));
      }
    }
  };

  const onBack = (isBack) => {
    if (isBack) {
      dispatch(clearUpdateItemApiResponse());
      dispatch(removeBundleDiscounts(currentItem.id));
      dispatch(clearGetBundlePriceApiResponse());
      setActiveTabIndex(activeTabIndex - 1);
      setCurrentItem(null);
      setSelectedDevicePriceOffer(null);
      setChangeItem(null);
      setUntouchedMandatoryFields([]);
      setInstallmentMessages({});
      Store("tabindex", activeTabIndex - 1);
    }
  };
  const showTopMessageContainer =
    installmentMessages?.basedOnResultsMsg?.length > 0 ||
    installmentMessages?.changePlanMsg?.length > 0;

  const getNextLabel = () => {
    return intl.formatMessage({
      id: "btn.next",
    });
  };

  const calculateNewRcPrice = (deviceFullPrice, newDownPaymentValue, termsAmount) => {
    return (
      Math.round(((deviceFullPrice - newDownPaymentValue) / termsAmount + Number.EPSILON) * 100) /
      100
    );
  };

  const disableNextButton = () => {
    if (currentItem && currentItem.category === ITEM_CATEGORY.BUNDLE) {
      if (
        (itemPriceState.bundlePriceOffers && itemPriceState.bundlePriceOffers.length === 0) ||
        (currentItem.sku !== null && !selectedDevicePriceOffer)
      ) {
        return true;
      }
      const needsPlanChange = eligibleOnlyWithPlanChange();
      if (needsPlanChange) {
        return true;
      }
    }

    return false;
  };

  const onBundlePriceOffersChange = (value) => {
    const offers = [...currentBundlePriceOffers];
    const installmentDownPayment = offers.find(
      (offer) => offer.code !== "PLAN" && offer.code !== currentItem.sku
    );

    const rcPrice = installmentDownPayment.price.find((p) => p.type === PRICE_TYPE.RECURRING);
    const easyTabFlexDiscount =
      installmentDownPayment.price.find((p) => p.type === PRICE_TYPE.FLEX_DEFERRED_AMOUNT)?.price ??
      0;
    const modifiedRcPrice = {
      ...rcPrice,
      price: calculateNewRcPrice(
        rcPrice.fullPrice - easyTabFlexDiscount,
        value,
        rcPrice.termsAmount
      ),
    };

    const otPrice = installmentDownPayment.price.find((p) => p.type === PRICE_TYPE.ONE_TIME);
    const modifiedOtPrice = { ...otPrice, price: value };

    let priceOffer = installmentDownPayment.price.map((obj) =>
      obj.type === PRICE_TYPE.RECURRING ? modifiedRcPrice : obj
    );

    priceOffer = priceOffer.map((obj) =>
      obj.type === PRICE_TYPE.ONE_TIME ? modifiedOtPrice : obj
    );

    const modifiedinstallmentDownPayment = {
      ...installmentDownPayment,
      price: priceOffer,
    };

    const newOffers = offers.map((offer) =>
      offer.code !== "PLAN" && offer.code !== currentItem.sku
        ? modifiedinstallmentDownPayment
        : offer
    );

    let curentBundleOffer = {
      bundleId: bundle.id,
      newOffers: newOffers,
    };

    dispatch(updateDeviceOffers(curentBundleOffer));
    setCurrentBundlePriceOffers(newOffers);
    onSelectDevicePriceOffer(modifiedinstallmentDownPayment);
  };

  const updateBundlePrice = useCallback(
    (rcFee, setupFee, noInstances) => {
      let curentBundleOffer = {
        rcFee: rcFee,
        setupFee: setupFee,
        noInstances: noInstances,
        discounts: discountsState.getBundleDiscounts.prices[bundle.id],
      };

      dispatch(updateBundleOffers(curentBundleOffer));
    },
    [bundle?.id, discountsState.getBundleDiscounts.prices, dispatch]
  );

  const onAccessLevelModified = (s) => {
    setSelfcareProfile(s);
  };

  const resetIncompatibleServices = useCallback(() => {
    setIncompatibleDeviceServices("");
    dispatch(clearGetServicesDescriptionFromProductDefinition());
  }, [dispatch]);

  const deviceNotExist = !bundle || (bundle && bundle.sku === null);
  return (
    <Layout
      showCart={false}
      checkoutStep={CHECKOUT_STEPS.DEVICE_AND_PLAN.index}
      pageTitle={intl.formatMessage({ id: "label.your-device-and-plan" })}
      isMultipleTabHeaderPage={true}
      isInitialTab={activeTabIndex === 0}
      parentCallback={onBack}
      nextStepButton={() => (
        <Button
          id="submitDeviceAndPlan"
          variant="default-next"
          disabled={loadingBundle || changeItem || disableNextButton()}
          onClick={onNext}>
          {getNextLabel()}
        </Button>
      )}>
      <Spinner
        isOpen={itemsState.getItems.loading || existingLinesState.getExistingLines.loading}
      />
      <StyledModalMessage
        isOpen={groupManagementError !== null}
        onRequestClose={resetGroupManagementError}
        message={groupManagementError}>
        <Button variant="simple-action" onClick={resetGroupManagementError}>
          <FormattedMessage id="btn.ok" />
        </Button>
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={incompatibleDeviceServices.length > 0}
        onRequestClose={resetIncompatibleServices}
        message={intl.formatMessage(
          { id: "err.incompatible-services" },
          { services: incompatibleDeviceServices }
        )}>
        <Button variant="simple-action" onClick={resetIncompatibleServices}>
          <FormattedMessage id="btn.ok" />
        </Button>
      </StyledModalMessage>

      <ErrorHandler
        error={itemPriceState.checkEasyTabFlexCompatibility.error}
        closeHandler={() => dispatch(deleteSession())}
        clickHandler={() => dispatch(deleteSession())}
      />

      <ErrorHandler
        error={itemsState.updateItem.error}
        closeHandler={resetUpdateItemState}
        clickHandler={resetUpdateItemState}
      />

      <ErrorHandler
        error={itemsState.deleteItem.error}
        closeHandler={resetDeleteItemState}
        clickHandler={resetDeleteItemState}
      />

      {/*get price offers errors*/}
      <ErrorHandler
        error={itemPriceState.getBundlePriceOffers.error}
        closeHandler={resetGetPriceOffersState}
        clickHandler={retryFailedGetPriceOffers}
        buttonMessageId="btn.retry"
      />

      <ErrorHandler
        error={personalInformationData.getCreditLevelDetails.error}
        closeHandler={resetGetPriceOffersState}
        clickHandler={retryFailedGetPriceOffers}
        buttonMessageId="btn.retry"
      />

      <ErrorHandler
        error={itemPriceState.getAccessoriesPriceOffers.error}
        closeHandler={resetGetPriceOffersState}
        clickHandler={retryFailedGetPriceOffers}
        buttonMessageId="btn.retry"
      />

      <ErrorHandler
        error={discountsState.getBundleDiscounts.error}
        closeHandler={resetGetPriceOffersState}
        clickHandler={retryFailedGetPriceOffers}
        buttonMessageId="btn.retry"
      />

      {showTopMessageContainer && !deviceNotExist && !changeItem && (
        <OSContainer variant="top-message" sx={{ flexDirection: "column" }}>
          {installmentMessages.basedOnResultsMsg?.length > 0 && (
            <Text variant="deviceHeader">
              <FormattedMessage
                textComponent="span"
                id={installmentMessages.basedOnResultsMsg}
                values={{
                  forThisLine:
                    installmentMessages.forThisLineMsg?.length > 0
                      ? intl.formatMessage({ id: installmentMessages.forThisLineMsg })
                      : "",
                  sup: (chunks) => (
                    <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%" }}>
                      {chunks}
                    </Text>
                  ),
                }}
              />
            </Text>
          )}
          {installmentMessages.paymentOptionMsg?.length > 0 && (
            <Text variant="deviceHeader" mt={1} ml={3}>
              <FormattedMessage
                textComponent="span"
                id={installmentMessages.paymentOptionMsg}
                values={{
                  bullet: "\u2022",
                  maxEasyTabAmount: (
                    <AmountPlain color="topMessage" amount={installmentMessages.balanceAmount} />
                  ),
                  forThisLine:
                    installmentMessages.forThisLineMsg?.length > 0
                      ? intl.formatMessage({ id: installmentMessages.forThisLineMsg })
                      : "",
                  sup: (chunks) => (
                    <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%" }}>
                      {chunks}
                    </Text>
                  ),
                }}
              />
              {installmentMessages.minimumUpfrontPaymentMsg?.length > 0 && (
                <FormattedMessage
                  textComponent="span"
                  id={installmentMessages.minimumUpfrontPaymentMsg}
                  values={{
                    minPayment: (
                      <AmountPlain color="topMessage" amount={installmentMessages.minimumPayment} />
                    ),
                    sup: (chunks) => (
                      <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%" }}>
                        {chunks}
                      </Text>
                    ),
                  }}
                />
              )}
            </Text>
          )}
          {installmentMessages.limitedCreditMsg?.length > 0 && (
            <Text variant="deviceHeader" mt={1} ml={3}>
              <FormattedMessage
                textComponent="span"
                id={installmentMessages.limitedCreditMsg}
                values={{
                  bullet: "\u2022",
                  amount: (
                    <AmountPlain
                      color="topMessage"
                      amount={installmentMessages.limitedCreditAmount}
                    />
                  ),
                }}
              />
              {installmentMessages.increaseUpfrontPaymentMsg?.length > 0 && (
                <FormattedMessage
                  textComponent="span"
                  id={installmentMessages.increaseUpfrontPaymentMsg}
                  values={{
                    sup: (chunks) => (
                      <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%" }}>
                        {chunks}
                      </Text>
                    ),
                  }}
                />
              )}
            </Text>
          )}
          {installmentMessages.changePlanMsg?.length > 0 && (
            <Text variant="deviceHeader" mt={3}>
              <FormattedMessage
                textComponent="span"
                id={installmentMessages.changePlanMsg}
                values={{
                  bullet: "\u2022",
                  forThisLine:
                    installmentMessages.forThisLineMsg?.length > 0
                      ? intl.formatMessage({ id: installmentMessages.forThisLineMsg })
                      : "",
                  sup: (chunks) => (
                    <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%" }}>
                      {chunks}
                    </Text>
                  ),
                }}
              />
            </Text>
          )}
        </OSContainer>
      )}

      <OSContainer
        variant={showTopMessageContainer ? "page-content-no-round-top" : "page-content"}
        sx={{ flexDirection: "column" }}
        pt="0 !important">
        {numberOfBundles > 0 && (
          <MultipleTabHeader
            tabsSize={numberOfBundles}
            activeTabIndex={activeTabIndex}
            editModeAvailable={
              currentItem &&
              currentItem.category === ITEM_CATEGORY.BUNDLE &&
              !currentItem.associatedSaID
            }
            currentItem={currentItem}
            linesTitles={linesTitles}
            setLinesTitles={setLinesTitles}
            isTopMessageVisible={showTopMessageContainer}
          />
        )}

        {currentItem && currentItem.category === ITEM_CATEGORY.BUNDLE && (
          <Bundle
            bundle={bundle}
            activeTabIndex={activeTabIndex}
            numberOfBundles={numberOfBundles}
            bundlePriceOffers={currentBundlePriceOffers}
            selectedDevicePriceOffer={selectedDevicePriceOffer}
            updateParentBundlePrice={updateBundlePrice}
            onSelectDevicePriceOffer={onSelectDevicePriceOffer}
            loadingBundle={loadingBundle || !discountsState.getBundleDiscounts.success}
            onBundlePriceOffersChange={onBundlePriceOffersChange}
            changeItem={changeItem}
            setChangeItem={setChangeItem}
            existingDeviceDetails={existingDeviceDetails}
            isEasyTabOfferSelected={isEasyTabOfferSelected}
            onAccessLevelModified={onAccessLevelModified}
            selfcareProfile={selfcareProfile}
            untouchedMandatoryFields={untouchedMandatoryFields}
            updateCurrentItem={updateCurrentItem}
            groupManagementError={groupManagementError}
            linesTitles={linesTitles}
            simType={simType}
            onChangeSimType={setSimType}
            onChangeBYODInfo={setBYODInfo}
          />
        )}

        {currentItem && currentItem.category === ITEM_CATEGORY.ACCESSORY && (
          <Accessories
            items={accessoryItems}
            loadingBundle={loadingBundle}
            priceOffers={itemPriceState.accessoriesPriceOffers}
            numberOfBundles={numberOfBundles}
          />
        )}
      </OSContainer>
    </Layout>
  );
};

export default DeviceAndPlan;
