import React, { forwardRef, useRef } from "react";
import { Checkbox, Label } from "@theme-ui/components";

const OSCheckbox = forwardRef(
  ({ className, checked, sx, variant = "checkbox", onChange, disabled, ...props }, ref) => {
    const checkref = useRef();
    return (
      <Label variant={variant} ref={ref} onClick={() => checkref.current.blur()} {...props}>
        <Checkbox
          disabled={disabled}
          onChange={onChange}
          checked={checked}
          className={className}
          ref={checkref}
          sx={{
            ...sx,
          }}
        />
      </Label>
    );
  }
);

export default OSCheckbox;
