import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Text, Flex } from "@theme-ui/components";

import { FormInput, OSSection, OSContainer } from "../../../../components/base";
import { Heading3 } from "../../../../components/heading";
import { PORTIN_TYPE } from "../../../../components/portin-type/Constants";
import { NONE_CODE } from "../../../../common/Constants";
import { Branding } from "../../../../config/branding/Branding";
import { getCarriers } from "../../../../config/redux/slices";

import { OSIcon } from "../../../../components/icons";

const KeepMobilePhoneNumberForm = (props) => {
  const {
    defaults,
    province,
    setProvince,
    mask,
    placeholder,
    pattern,
    onChangeValidationData,
    onChangePortInNumber,
    isPortedNumberAlreadyUsed,
  } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const { register, errors, getValues, trigger, formState, control } = useFormContext({
    mode: "onChange",
  });
  const reactHookFormHandle = { register, errors };
  const statesData = useSelector((state) => state.portability.getProvincesFromRateCentres);
  const carriersData = useSelector((state) => state.portability.getCarriers);
  const portabilityConfigData = useSelector((state) => state.portability.getPortabilityConfig);

  useEffect(() => {
    if (statesData.provinces?.length > 0 && province !== undefined) {
      //should pass for empty province
      let provinceFromList = statesData.provinces.find((prov) => prov.iso === province);
      if (!provinceFromList) {
        provinceFromList = statesData.provinces.find((prov) => prov.description === province);
        setProvince(provinceFromList ? provinceFromList.iso : "");
      }
      let selectedProvince = provinceFromList ? province : "";
      getFilteredCarriers(selectedProvince);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statesData, province]);

  const onChangeProvince = (selectedProvince) => {
    setProvince(selectedProvince);
    onChangeValidationData();
  };

  const getFilteredCarriers = (selectedProvince) => {
    let province = selectedProvince !== NONE_CODE ? selectedProvince : "";
    dispatch(
      getCarriers({
        province: province,
        country: Branding.DEFAULT_ISSUING_COUNTRY,
        carrierType: PORTIN_TYPE.MOBILE,
      })
    );
  };

  const onChangeOldAccountId = () => {
    if (formState.isSubmitted) {
      trigger("pinPassword"); // retriggers validation
      trigger("imei");
      trigger("oldAccountNumber");
    }
  };

  return (
    <OSContainer variant="page-content" sx={{ flexDirection: "column" }} px={6}>
      <Heading3 color="primary" mb={3} sx={{ whiteSpace: "wrap" }}>
        <FormattedMessage id="lbl.old-service-provider-information" />
      </Heading3>
      <OSSection
        variant="page-section"
        name="keepMoPhNoContact"
        mt={2}
        sx={{
          display: "grid",
          gridGap: 2,
          gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(6, 1fr)"],
          gridAutoRows: "min-content",
        }}>
        <FormInput
          section="keepMoPhNoContact"
          wrapperProps={{ sx: { gridColumnStart: 1, gridColumnEnd: [1, 3, 4] } }}
          isMandatory
          label={intl.formatMessage({ id: "lbl.first-name-keep-phone-number" })}
          defaultValue={defaults.firstName}
          maxLength={100}
          name="firstName"
          {...reactHookFormHandle}
          type="text"
        />
        <FormInput
          section="keepMoPhNoContact"
          wrapperProps={{ sx: { gridColumnStart: [1, 3, 4], gridColumnEnd: [1, 4, 7] } }}
          isMandatory
          label={intl.formatMessage({ id: "lbl.last-name-keep-phone-number" })}
          defaultValue={defaults.lastName}
          maxLength={100}
          name="lastName"
          {...reactHookFormHandle}
          type="text"
        />
        <FormInput
          section="keepMoPhNoContact"
          wrapperProps={{ sx: { gridColumnStart: 1, gridColumnEnd: [1, 3, 4] } }}
          isMandatory
          label={intl.formatMessage({ id: "lbl.address" })}
          defaultValue={defaults.address}
          maxLength={100}
          name="address"
          {...reactHookFormHandle}
          type="text"
        />
        <FormInput
          section="keepMoPhNoContact"
          isMandatory
          label={intl.formatMessage({ id: "lbl.city" })}
          defaultValue={defaults.city}
          maxLength={70}
          name="city"
          {...reactHookFormHandle}
          type="text"
        />
        <FormInput
          key={"province" + province}
          section="keepMoPhNoContact"
          isMandatory
          label={intl.formatMessage({ id: "lbl.province" })}
          maxLength={100}
          name="province"
          defaultValue={province}
          {...reactHookFormHandle}
          type="select"
          onChange={(e) => onChangeProvince(e.target.value)}
          validations={{
            validate: {
              required: (value) => value !== NONE_CODE,
            },
          }}>
          {[
            {
              countryISO2: NONE_CODE,
              iso: NONE_CODE,
              description: intl.formatMessage({ id: "lbl.none" }),
            },
          ]
            .concat(statesData.provinces)
            .sort((s1, s2) => (s1.iso > s2.iso ? 1 : -1))
            .map((state) => (
              <option key={state.iso} value={state.iso}>
                {state.iso === NONE_CODE ? state.description : state.iso}
              </option>
            ))}
        </FormInput>
        <FormInput
          section="keepMoPhNoContact"
          isMandatory
          label={intl.formatMessage({ id: "lbl.postal-code-keep-phone-number" })}
          defaultValue={defaults.postalCode}
          maxLength={10}
          name="postalCode"
          {...reactHookFormHandle}
          type="text"
        />
      </OSSection>
      <OSSection
        name="keepPhoneNumber"
        mt={2}
        sx={{
          display: "grid",
          gridGap: 2,
          gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"],
          gridAutoRows: "min-content",
        }}>
        <FormInput
          id="yourNumber"
          control={control}
          section="keepPhoneNumber"
          isMandatory
          label={intl.formatMessage({ id: "lbl.your-number" })}
          mask={mask}
          placeholder={placeholder}
          maskChar="_"
          defaultValue={defaults.phoneNumber}
          validations={{
            validate: {
              pattern: (value) => {
                const regExp = new RegExp(pattern);
                return regExp.test(value);
              },
              validateAlreadyUsed: (value) => {
                if (!value) return true;
                if (isPortedNumberAlreadyUsed(value)) {
                  return intl.formatMessage({ id: "err.ported-number-already-used" });
                }
                return true;
              },
            },
          }}
          name="phoneNumber"
          {...reactHookFormHandle}
          type="mask"
          onBlur={(e) => onChangePortInNumber(e.target.value)}
          onChange={() => {
            onChangeValidationData(true);
          }}
        />
        <FormInput
          key={"carriers" + carriersData.carriers?.length}
          section="keepPhoneNumber"
          isMandatory
          label={intl.formatMessage({ id: "lbl.your-old-carrier-name" })}
          defaultValue={defaults.carrierName}
          name="carrierName"
          {...reactHookFormHandle}
          type="select"
          onChange={onChangeValidationData}
          validations={{
            validate: {
              required: (value) => value !== NONE_CODE,
            },
          }}>
          {[
            {
              serviceProviderIdentification: NONE_CODE,
              description: intl.formatMessage({ id: "lbl.none" }),
            },
          ]
            .concat(carriersData.carriers)
            .filter(
              (carrier) =>
                carrier.serviceProviderIdentification !==
                portabilityConfigData.portabilityConfig.wirelessSpId
            )
            .map((carrier) => (
              <option
                key={carrier.serviceProviderIdentification}
                value={carrier.serviceProviderIdentification}>
                {carrier.description}
              </option>
            ))}
        </FormInput>
        <Flex
          mt={2}
          sx={{
            alignItems: "center",
            gridColumnStart: 1,
            gridColumnEnd: [1, 4, 5],
          }}>
          <OSIcon name="tooltip-info" />
          <Text variant="headerStep" ml={[3, 2]}>
            {intl.formatMessage({ id: "lbl.port-in-imei-pin-acct-required" })}
          </Text>
        </Flex>
        <FormInput
          wrapperProps={{ sx: { gridColumnStart: 1 } }}
          label={intl.formatMessage({ id: "lbl.old-account-number" })}
          defaultValue={defaults.oldAccountNumber}
          maxLength={100}
          name="oldAccountNumber"
          {...reactHookFormHandle}
          type="text"
          errorProps={{
            sx: {
              overflow: ["normal", "visible !important"],
              whiteSpace: ["wrap", "nowrap !important", "pre"],
              textAlign: "left",
            },
          }}
          onChange={onChangeOldAccountId}
          validations={{
            validate: {
              oneRequired: () => {
                if (
                  !getValues("oldAccountNumber") &&
                  !getValues("pinPassword") &&
                  !getValues("imei")
                ) {
                  return intl.formatMessage({ id: "err.port-in-imei-pin-acct-required" });
                }
                return true;
              },
            },
          }}
        />
        <FormInput
          section="keepPhoneNumber"
          label={intl.formatMessage({ id: "lbl.pin-password" })}
          defaultValue={defaults.pinPassword}
          maxLength={100}
          name="pinPassword"
          {...reactHookFormHandle}
          type="text"
          onChange={onChangeOldAccountId}
          validations={{
            validate: {
              oneRequired: () => {
                if (
                  !getValues("oldAccountNumber") &&
                  !getValues("pinPassword") &&
                  !getValues("imei")
                ) {
                  return false;
                }
                return true;
              },
            },
          }}
        />
        <FormInput
          section="keepPhoneNumber"
          label={intl.formatMessage({ id: "lbl.imei" })}
          defaultValue={defaults.imei}
          maxLength={100}
          name="imei"
          {...reactHookFormHandle}
          type="text"
          onChange={onChangeOldAccountId}
          validations={{
            validate: {
              oneRequired: () => {
                if (
                  !getValues("oldAccountNumber") &&
                  !getValues("pinPassword") &&
                  !getValues("imei")
                ) {
                  return false;
                }
                return true;
              },
            },
          }}
        />
      </OSSection>
    </OSContainer>
  );
};

export default KeepMobilePhoneNumberForm;
