import React from "react";
import { Box } from "@theme-ui/components";

const Trash = ({ color = "svgStrokePrimary", size = "iconSmall", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 20"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <g transform="translate(0.5 0.5)">
      <g transform="translate(-3 -2)">
        <path
          d="M3,6H21"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M19,6,18,19a2,2,0,0,1-2,2H8a2,2,0,0,1-2-2L5,6M8,6V4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          y2="6"
          transform="translate(10 10)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          opacity="0.4"
        />
        <line
          y2="6"
          transform="translate(14 10)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          opacity="0.4"
        />
      </g>
    </g>
  </Box>
);

export default Trash;
