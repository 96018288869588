import React from "react";
import { Box } from "@theme-ui/components";

const SVG = ({ size = 22, fill = "secondary", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 22"
    {...props}
    sx={{
      width: size,
      height: size,
      fill: fill,
      ...props.sx,
    }}
  />
);

export default SVG;
