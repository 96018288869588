import React from "react";
import { Box } from "@theme-ui/components";

const PdfFile = ({ size = "iconMedium", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30.718 21.978"
    sx={{ width: size, height: size }}
    {...props}>
    <g>
      <g>
        <g>
          <path
            d="M6.5 11.133v11.01h16v-18H10.864V8.5H6.5z"
            transform="translate(-5.792 -3.431)"
            stroke="#939598"
            fill="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.419 0 0 4.419"
            transform="translate(.708 .707)"
            stroke="#939598"
            fill="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <path
        d="M17.546 14.694c-1.659 2.464-9.129-7.195-6.258-8.9C14.307 4 8.373 20.366 6.475 17.01c-1.626-2.874 13.182-5.451 11.071-2.316z"
        transform="translate(-2.914 -1.346)"
        stroke="#bf0000"
        fill="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Box>
);

export default PdfFile;
