import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Text, Label } from "@theme-ui/components";

import { NONE_CODE } from "../../../../common/Constants";
import { FormInput, OSCheckbox, OSContainer, OSSection } from "../../../../components/base";
import { getRateCentres } from "../../../../config/redux/slices";

import { Heading3 } from "../../../../components/heading";
import _ from "lodash";

const AddressForRegistrationForm = ({ defaults, oldProvince, npanxx, onChangeValidationData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { register, errors, clearErrors } = useFormContext({ mode: "onChange" });
  const reactHookFormHandle = { register, errors };

  const rateCentresData = useSelector((state) => state.portability.getRateCentres);
  const statesData = useSelector((state) => state.portability.getProvincesFromRateCentres);
  const personalInformationData = useSelector((state) => state.personalInformation);

  const [useRegAddressCheck, setUseRegAddressCheck] = useState(defaults.address);
  const [regProvince, setRegProvince] = useState(defaults?.province);
  const [regAddress, setRegAddress] = useState(defaults?.address);
  const [regCity, setRegCity] = useState(defaults?.city);
  const [regPostalCode, setRegPostalCode] = useState(defaults?.postalCode);
  const [location, setLocation] = useState(defaults?.location);

  useEffect(() => {
    let province = useRegAddressCheck ? regProvince : oldProvince;
    if (province && province !== NONE_CODE) {
      dispatch(getRateCentres(province));
    } else {
      dispatch(getRateCentres());
    }
  }, [dispatch, regProvince, useRegAddressCheck, oldProvince]);

  useEffect(() => {
    if (rateCentresData.success && rateCentresData.rateCentres.length > 0) {
      let rateCentreObj = rateCentresData.rateCentres.find(
        (rc) => rc.npa + rc.nxx === npanxx || rc.rateCentre === location
      );
      if (rateCentreObj) {
        setLocation(rateCentreObj.rateCentre);
      } else {
        setLocation(intl.formatMessage({ id: "lbl.none" }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [npanxx, rateCentresData.success]);

  const onChangeRegProvince = (selectedProvince) => {
    setRegProvince(selectedProvince);
    onChangeValidationData();
  };

  const onChangeLocation = (selectedLocation) => {
    setLocation(selectedLocation);
    onChangeValidationData();
  };

  const onChangeUseRegAddress = () => {
    if (useRegAddressCheck) {
      clearErrors(["regAddress", "regCity", "regProvince", "regPostalCode"]);
    } else {
      setRegAddress(defaults?.address ?? personalInformationData.regStreet);
      setRegCity(defaults?.city ?? personalInformationData.regCity);
      setRegPostalCode(defaults?.postalCode ?? personalInformationData.regPostalCode);
      setRegProvince(defaults?.province ?? personalInformationData.regProvince);
    }
    setUseRegAddressCheck(!useRegAddressCheck);
    onChangeValidationData();
  };

  return (
    <OSContainer variant="page-content" sx={{ flexDirection: "column" }} mt={10} px={6}>
      <Heading3 color="primary" mb={3} sx={{ whiteSpace: "wrap" }}>
        <FormattedMessage id="lbl.address-for-registration" />
      </Heading3>

      <Flex sx={{ flexDirection: "column" }}>
        <Label sx={{ alignItems: "center" }}>
          <OSCheckbox
            mr={[7, 1]}
            checked={useRegAddressCheck}
            onChange={() => onChangeUseRegAddress()}
          />
          <Text
            sx={{
              lineHeight: "relaxed",
              my: 3,
              cursor: "default",
              color: "primary",
              fontSize: "lg",
              fontWeight: "normal",
              whiteSpace: "normal",
            }}>
            <FormattedMessage id="lbl.i-want-to-portin-to-a-new-address" />
          </Text>
        </Label>
      </Flex>
      {useRegAddressCheck && (
        <OSSection
          name="addressForRegistration"
          mt={2}
          mb={5}
          sx={{
            display: "grid",
            gridGap: 2,
            gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(6, 1fr)"],
            gridAutoRows: "min-content",
          }}>
          <FormInput
            section="addressForRegistration"
            wrapperProps={{ sx: { gridColumnStart: 1, gridColumnEnd: [1, 3, 4] } }}
            isMandatory
            label={intl.formatMessage({ id: "lbl.address" })}
            maxLength={100}
            name="regAddress"
            defaultValue={regAddress}
            {...reactHookFormHandle}
            type="text"
          />
          <FormInput
            section="addressForRegistration"
            isMandatory
            label={intl.formatMessage({ id: "lbl.city" })}
            maxLength={70}
            name="regCity"
            defaultValue={regCity}
            {...reactHookFormHandle}
            type="text"
          />
          <FormInput
            key={"regProvince" + statesData.provinces?.length}
            section="keepMoPhNoContact"
            isMandatory
            label={intl.formatMessage({ id: "lbl.province" })}
            maxLength={100}
            onChange={(e) => onChangeRegProvince(e.target.value)}
            name="regProvince"
            defaultValue={regProvince}
            {...reactHookFormHandle}
            type="select"
            validations={{
              validate: {
                required: (value) => value !== NONE_CODE,
              },
            }}>
            {[
              {
                countryISO2: NONE_CODE,
                iso: NONE_CODE,
                description: intl.formatMessage({ id: "lbl.none" }),
              },
            ]
              .concat(statesData.provinces)
              .sort((s1, s2) => (s1.iso > s2.iso ? 1 : -1))
              .map((state) => (
                <option key={state.iso} value={state.iso}>
                  {state.iso === NONE_CODE ? state.description : state.iso}
                </option>
              ))}
          </FormInput>
          <FormInput
            section="keepMoPhNoContact"
            isMandatory
            label={intl.formatMessage({ id: "lbl.postal-code-address-for-registration" })}
            maxLength={10}
            name="regPostalCode"
            defaultValue={regPostalCode}
            {...reactHookFormHandle}
            type="text"
          />
        </OSSection>
      )}

      <OSSection
        name="locationSection"
        mt={2}
        sx={{
          display: "grid",
          gridGap: 2,
          gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"],
          gridAutoRows: "min-content",
          flexDirection: ["column", "column", "row"],
        }}>
        <FormInput
          key={"regLocation" + location}
          section="locationSection"
          isMandatory
          label={intl.formatMessage({ id: "lbl.location-address-for-registration" })}
          maxLength={100}
          name="regLocation"
          defaultValue={location ? location : null}
          {...reactHookFormHandle}
          type="select"
          onChange={(e) => onChangeLocation(e.target.value)}
          validations={{
            validate: {
              required: (value) => value !== intl.formatMessage({ id: "lbl.none" }),
            },
          }}>
          {[
            {
              rateCentre: intl.formatMessage({ id: "lbl.none" }),
            },
          ]
            .concat(_.uniqBy(rateCentresData.rateCentres, "rateCentre"))
            .sort((rc1, rc2) => (rc1.rateCentre > rc2.rateCentre ? 1 : -1))
            .map((rc) => (
              <option key={rc.rateCentre} value={rc.rateCentre}>
                {rc.rateCentre}
              </option>
            ))}
        </FormInput>
      </OSSection>
    </OSContainer>
  );
};

export default AddressForRegistrationForm;
