import React from "react";
import { Box } from "@theme-ui/components";

const Document = ({ color = "svgStrokePrimary", size = "iconSmall", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.207 25.206"
    {...props}
    sx={{ width: size, height: size, stroke: color, flexShrink: 0 }}>
    <g transform="translate(0.707 0.706)">
      <g transform="translate(-5.5 -2.5)" opacity="0.4">
        <path
          d="M2.352,24.5H16.647A1.853,1.853,0,0,0,18.5,22.648V2.352A1.853,1.853,0,0,0,16.647.5H5.5V3.648A1.853,1.853,0,0,1,3.647,5.5H.5V22.648A1.852,1.852,0,0,0,2.352,24.5"
          transform="translate(5 2)"
          fill="#fff"
        />
        <path
          d="M2.352,24.5H16.647A1.853,1.853,0,0,0,18.5,22.648V2.352A1.853,1.853,0,0,0,16.647.5H5.5V3.648A1.853,1.853,0,0,1,3.647,5.5H.5V22.648A1.852,1.852,0,0,0,2.352,24.5Z"
          transform="translate(5 2)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          x1="5"
          y2="5"
          transform="translate(5.5 2.501)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M5.5,9.3h7.978"
          transform="translate(5 2)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          d="M5.5,17.284h7.978"
          transform="translate(5 2)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          d="M5.5,13.3h4"
          transform="translate(5 2)"
          fill="none"
          stroke="#332a86"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </Box>
);

export default Document;
