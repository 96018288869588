import React from "react";
import { Box, Flex, Image } from "@theme-ui/components";
import { useIntl } from "react-intl";

import CheckoutStep from "./CheckoutStep";
import { CHECKOUT_STEP_STATE } from "./Constants";
import { useSelector } from "react-redux";
import { getSteps } from "../../common/StepsUtilities";

/**
 * Header Component.
 *
 * @param {*} param0.checkoutStep Checkout step number.
 *  *
 * @param {*} param0.paymentSuccess Checkout step number.
 */
const HeaderDesktop = ({
  checkoutStep: checkoutStepStaticIdx,
  showSteps = true,
  paymentSuccess,
}) => {
  const intl = useIntl();
  const brandingData = useSelector((state) => state.branding.data);
  const { planFlags } = useSelector((state) => state.items);
  const { flow } = useSelector((state) => state.personalInformation);

  /**
   * Compute step state based on provided checkout step number.
   *
   * @param {*} stepNumber Checkout step number.
   * @param {*} currentStepNumber Current checkout step number.
   *
   * @returns Checkout state. One of: ACTIVE, COMPLETED, NOT_COMPLETED.
   */
  const getCheckoutStepState = (stepNumber, currentStepNumber) => {
    if (paymentSuccess === true) return CHECKOUT_STEP_STATE.COMPLETED;
    if (stepNumber < currentStepNumber) return CHECKOUT_STEP_STATE.COMPLETED;
    if (stepNumber === currentStepNumber) return CHECKOUT_STEP_STATE.ACTIVE;
    return CHECKOUT_STEP_STATE.NOT_COMPLETED;
  };

  return (
    <Box
      sx={{
        backgroundColor: "headerBg",
        backgroundImage: brandingData.headerBackgroundImage
          ? `url(${brandingData.headerBackgroundImage})`
          : "",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}>
      <Box sx={{ width: "100%", maxWidth: "108.25rem" }} mx="auto" px={4}>
        <Flex sx={{ gap: "10", height: "8rem", alignItems: "center" }}>
          <Box>
            <Image src={brandingData.logo.desktopValue} sx={{ width: "14rem" }} />
          </Box>
          <Flex ml={[0, 0, 4, 28]} sx={{ justifyContent: "space-between", flex: 1 }}>
            {showSteps &&
              getSteps(planFlags, flow).map((step) => (
                <CheckoutStep
                  key={step.index}
                  number={step.index}
                  state={getCheckoutStepState(step.index, checkoutStepStaticIdx)}
                  name={intl.formatMessage({ id: step.label })}
                />
              ))}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default HeaderDesktop;
