import React from "react";
import { Box } from "@theme-ui/components";

const TickOrderConfirmation = ({ color = "svgStrokePrimary", size = "iconLarge", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 54"
    sx={{ width: size, height: size, stroke: color, flexShrink: 0 }}
    {...props}>
    <g transform="translate(-765 -1392.5)">
      <g transform="translate(771 1398.5)" fill="none" stroke={color} strokeWidth="2" opacity="0.4">
        <circle cx="21" cy="21" r="21" stroke="none" />
        <circle cx="21" cy="21" r="20" fill="none" />
      </g>
      <path
        d="M14715.717-1070.448l8.406,8.063,14.594-14"
        transform="translate(-13935.217 2488.885)"
        fill="none"
        stroke="#39bf11"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </g>
  </Box>
);

export default TickOrderConfirmation;
