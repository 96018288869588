const CHECKOUT_STEP_STATE = {
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
  NOT_COMPLETED: "NOT_COMPLETED",
};

const CHECKOUT_STEPS = {
  PERSONAL_INFORMATION: {
    index: 1,
    label: "header.personal-information",
    route: "/personal-information",
    srcButtonLabel: "",
  },
  UPDATE_DEVICE: {
    index: 2,
    label: "header.device-upgrade",
    route: "/update-device",
    srcButtonLabel: "btn.personal-information-next-step",
  },
  DEVICE_AND_PLAN: {
    index: 3,
    label: "header.device-and-plan",
    route: "/device-and-plan",
    srcButtonLabel: "btn.personal-information-next-step",
  },
  PHONE_NUMBER: {
    index: 4,
    label: "header.phone-number",
    route: "/phone-number",
    srcButtonLabel: "btn.next",
  },
  SHIPPING_DETAILS: {
    index: 5,
    label: "header.shipping-details",
    pageTitle: "lbl.shipping-details",
    route: "/shipping-details",
    srcButtonLabel: "btn.next",
  },
  SUMMARY: {
    index: 6,
    label: "header.summary",
    route: "/summary",
    srcButtonLabel: "btn.shipping-details-next-step",
  },
  PAYMENT_INFORMATION: {
    index: 7,
    label: "header.payment-information",
    route: "/payment-information",
    srcButtonLabel: "btn.summary-next-step",
  },
};

export { CHECKOUT_STEP_STATE, CHECKOUT_STEPS };
