import React, { useEffect } from "react";
import { ErrorCode, POS_PARAMETER } from "../../common/Constants";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, Text } from "@theme-ui/components";
import { useSelector, useDispatch } from "react-redux";
import { getPosParameter } from "../../config/redux/slices";

const GenericError = ({ message }) => {
  const dispatch = useDispatch();
  const posParameters = useSelector((state) => state.configuration.getPosParameter.posParameters);
  useEffect(() => {
    dispatch(getPosParameter(POS_PARAMETER.ONLINE_STORE_CHAT_URL));
  }, [dispatch]);

  return (
    <FormattedMessage
      id={message}
      values={{
        a: (chunks) => (
          <Link
            variant="chat-link"
            href={posParameters[POS_PARAMETER.ONLINE_STORE_CHAT_URL]}
            target="_blank">
            {chunks}
          </Link>
        ),
      }}
    />
  );
};

const GetErrorDescription = ({ error }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPosParameter(POS_PARAMETER.ONLINE_STORE_CHAT_URL));
  }, [dispatch]);

  if (!error || !error.errorCode) {
    return <GenericError message="err.generic" />;
  }
  // messages from core/api (ApiError.description) are rarely translated, define custom when needed
  switch (error.errorCode) {
    case ErrorCode.AUTHORIZATION_ERROR:
      return <FormattedMessage id="err.authorization_error" />;
    case ErrorCode.MISSING_SECURITY_RIGHT:
    case ErrorCode.ACCOUNT_CREATION_ERROR:
    case ErrorCode.GET_HANDSET_BY_SKU_FAILED:
    case ErrorCode.CHECK_RESOURCES_IN_STOCK_FAILED:
    case ErrorCode.INCOMPATIBLE_DEVICE_PLAN:
      return error.description;
    case ErrorCode.PERFORM_PAYMENT_FAILED:
      return <GenericError message="err.ippay-generic-error" />;
    case ErrorCode.DELETE_ITEM_ERROR:
      return <FormattedMessage id="lbl.error-on-removing-item" />;
    case ErrorCode.INVALID_SESSION_ID:
      return <GenericError message="err.invalid_session" />;
    case ErrorCode.GET_RANDOM_PHONE_NUMBERS_FAILED:
      return <GenericError message="err.get_random_phone_numbers_failed" />;
    case ErrorCode.VALIDATE_PORT_IN_CHECK_FAILED:
      return <GenericError message="err.validate_port_in_check_failed" />;
    case ErrorCode.INVALID_PORT_IN_CHECK_REQUEST_MANDATORY_FIELDS_MISSING:
      return intl.formatMessage(
        {
          id: "err.invalid_port_in_check_request_mandatory_fields_missing",
        },
        {
          firstField: intl.formatMessage({ id: "lbl.old-account-number" }),
          secondField: intl.formatMessage({ id: "lbl.pin-password" }),
          thirdField: intl.formatMessage({ id: "lbl.imei" }),
        }
      );
    case ErrorCode.INVALID_PORTED_NUMBER_LCA_NOT_AVAILABLE:
      return <GenericError message="err.invalid_ported_number_lca_not_available" />;
    case ErrorCode.INVALID_PORTED_NUMBER_LCA_NOT_OPENED:
      return <GenericError message="err.invalid_ported_number_lca_not_opened" />;
    case ErrorCode.INVALID_PORTED_NUMBER_PORT_IN_CHECK_STARTED:
      return <GenericError message="err.invalid_ported_number_port_in_check_started" />;
    case ErrorCode.INVALID_PORTED_NUMBER_ALREADY_ASSIGNED:
    case ErrorCode.INVALID_PORTED_NUMBER_LOCATION_NOT_SELLABLE:
    case ErrorCode.INVALID_PORTED_NUMBER_WRONG_WAREHOUSE:
      return <GenericError message="err.invalid_ported_number_not_available" />;
    case ErrorCode.INVALID_PORTED_NUMBER_NO_VALID_GL_SEGMENT:
      return <GenericError message="err.invalid_ported_number_no_valid_gl_segment" />;
    case ErrorCode.PHONE_NUMBER_ALREADY_IS_USED_ERROR:
      return <FormattedMessage id="err.phone_number_already_in_use" />;
    case ErrorCode.MISCONFIGURED_E911_IN_PACKAGE:
      return <GenericError message="lbl.phone-number-selection-link-error" />;
    case ErrorCode.NO_ITEMS_AVAILABLE:
      return <FormattedMessage id="err.no-item-available" />;
    case ErrorCode.NOT_ALLOWED_ACTION_IN_CART:
      return <FormattedMessage id="err.account-in-sale" />;
    case ErrorCode.ORDER_FAILED_DUE_TO_SUSPENDED_ACCOUNT:
      return <FormattedMessage id="err.order-failed-due-to-suspended-account" />;
    case ErrorCode.ORDER_FAILED_DUE_TO_PTC_ACCOUNT:
      return <FormattedMessage id="err.order-failed-due-to-ptc-account" />;
    case ErrorCode.ORDER_FAILED_DUE_TO_ACTIVE_HOT_BILLING:
      return <FormattedMessage id="err.order-failed-due-to-active-hot-billing" />;
    case ErrorCode.ORDER_FAILED_DUE_TO_ACTIVE_SAC:
      return <FormattedMessage id="err.order-failed-due-to-active-sac" />;
    case ErrorCode.ORDER_FAILED_DUE_TO_ACTIVE_SAS:
      return <FormattedMessage id="err.order-failed-due-to-active-sas" />;
    case ErrorCode.ORDER_FAILED_DUE_TO_SCHEDULED_CSC:
      return <FormattedMessage id="err.order-failed-due-to-scheduled-csc" />;
    case ErrorCode.ORDER_FAILED_DUE_TO_ACTIVE_DEBT_COLLECTION:
      return <FormattedMessage id="err.order-failed-due-to-active-debt-collection" />;
    case ErrorCode.ORDER_FAILED_DUE_TO_ACTIVE_PORT_OUT:
      return <FormattedMessage id="err.order-failed-due-to-active-port-out" />;
    case ErrorCode.SA_HAS_OPENED_FULFILLMENT:
      return <GenericError message="err.has-opened-fulfillment" />;
    case ErrorCode.SA_HAS_PARKED_CART:
      return <GenericError message="err.has-opened-fulfillment" />;
    case ErrorCode.SA_HAS_OPENED_PORT_OUT_PROCESS:
      return <GenericError message="err.has-opened-port-out-process" />;
    case ErrorCode.IS_CART_FULFILLMENT_CANCELED:
      return <GenericError message="err.handset-has-canceled-fulfillment" />;
    case ErrorCode.SA_HAS_OPENED_SPC_PROCESS:
      return <FormattedMessage id="err.has-spc-process" />;
    case ErrorCode.INSTALLMENT_PLAN_NOT_CONFIGURED:
      return (
        <FormattedMessage
          textComponent="span"
          id="err.misconfigured-installment-plan"
          values={{
            sup: (chunks) => (
              <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%" }}>
                {chunks}
              </Text>
            ),
          }}
        />
      );
    case ErrorCode.FINISH_SALE_WITH_PAYMENT_FAILED:
      return <GenericError message="err.finish_sale_with_payment_failed" />;
    case ErrorCode.FINISH_SALE_FAILED:
      return <GenericError message="err.finish_sale_failed" />;
    case ErrorCode.CREDITSCORE_ERROR:
    case ErrorCode.EMPTY_ACCOUNT_PARAMETERS_FOR_3RD_PARTY:
    case ErrorCode.CREDIT_SCORE_AUTHORITY_RESPONDS_WITH_ERROR:
    case ErrorCode.INVALID_CREDIT_SCORE_CONTACT_PARAMETERS:
    case ErrorCode.INQUIRING_NOT_SUPPORTED_FOR_COMMERCIAL_ACCOUNTS:
      return <GenericError message="err.creditScore-generic-error" />;
    case ErrorCode.VALIDATE_ACCOUNT_PIN_NO_MATCH:
      return <FormattedMessage id="err.incorrect-pin" />;
    case ErrorCode.VALIDATE_ACCOUNT_PIN_ACCT_LOCKED:
    case ErrorCode.VALIDATE_ACCOUNT_PIN_NOT_REGISTERED:
      return <GenericError message="err.incorrect-pin-account-locked" />;
    case ErrorCode.CART_IS_PROCESSING:
      return <FormattedMessage id="err.cart_processing" />;
    case ErrorCode.ADD_SA_FAILED:
    case ErrorCode.ABORT_ON_TIMEOUT_ERROR:
    default:
      return <GenericError message="err.generic" />;
  }
};

export default GetErrorDescription;
