import React from "react";
import { Box } from "@theme-ui/components";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { FormInput } from "../../../../components/base";

const DefaultAddressForm = ({ defaults, onSubmit }) => {
  const intl = useIntl();
  const { register, handleSubmit, errors } = useForm({ mode: "onTouched" });

  const reactHookFormHandle = { register, errors };

  return (
    <form id="defaultAddressForm" onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "grid",
          columnGap: 5,
          rowGap: 5,
          gridTemplateColumns: "repeat(2, 1fr)",
          gridAutoRows: "min-content",
        }}>
        <FormInput
          label={intl.formatMessage({ id: "lbl.buzzer-code" })}
          defaultValue={defaults.buzzerCode}
          maxLength="30"
          name="buzzerCode"
          type="text"
          {...reactHookFormHandle}
        />
        <FormInput
          wrapperProps={{ sx: { gridColumnStart: 1, gridColumnEnd: 3 } }}
          label={intl.formatMessage({ id: "lbl.package-delivery" })}
          defaultValue={defaults.shippingNote}
          maxLength="100"
          name="shippingNote"
          type="text"
          placeholder={intl.formatMessage({ id: "lbl.package-delivery-eg" })}
          {...reactHookFormHandle}
        />
      </Box>
    </form>
  );
};

export default DefaultAddressForm;
