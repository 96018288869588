import React from "react";
import { Box } from "@theme-ui/components";

const HRTriangle = ({
  trianglePosition = 459,
  width = 1266,
  stroke = "hrTriangle",
  fillColor = "none",
  ...props
}) => {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: width,
        height: "2rem",
        stroke: stroke,
        fill: fillColor,
        flexShrink: 0,
      }}
      {...props}>
      <path
        fill={fillColor}
        stroke={stroke}
        strokeLinejoin="round"
        d={`M0 0h${trianglePosition - 23}.8l23.49 20 23.49-20H${width}`}
        transform="translate(0 .5)"
      />
    </Box>
  );
};

export default HRTriangle;
