import React, { useCallback, useEffect } from "react";
import { Text } from "@theme-ui/components";
import { FormattedMessage } from "react-intl";
import qs from "qs";
import { useHistory } from "react-router-dom";

const PaymentCallback = () => {
  const history = useHistory();

  const parseParams = useCallback(() => {
    let qryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    if (!qryParams) {
      return {};
    }

    return qryParams;
  }, [history.location]);

  useEffect(() => {
    window.top.postMessage(
      JSON.stringify({
        queryParams: parseParams(),
      }),
      "*"
    );
  }, [parseParams]);

  return (
    <React.Fragment>
      <Text>
        <FormattedMessage id="lbl.loading" />
      </Text>
    </React.Fragment>
  );
};

export default PaymentCallback;
