import { Flex, Heading } from "@theme-ui/components";
import { FormattedMessage } from "react-intl";

import Amount from "../amount";
import { OSIcon } from "../icons";

const ShoppingCartTotal = ({ collapsed, setCollapsed, amount }) => {
  return (
    <Flex
      sx={{
        display: ["block", "block", "none"],
        bg: "secondaryBg",
        borderRadius: "xl",
        borderBottomLeftRadius: !collapsed && 0,
        borderBottomRightRadius: !collapsed && 0,
        p: [8, 8, 9],
        pb: !collapsed && [2, 8, 9],
      }}
      onClick={() => {
        setCollapsed(!collapsed);
      }}>
      <Flex sx={{ justifyContent: "space-between" }}>
        <Heading variant="shoppingCartTitle" color={"ternary"} sx={{ overflowWrap: "break-word" }}>
          <FormattedMessage id="lbl.shopping-cart" />
        </Heading>
        <Flex>
          <Amount type="bold_large" amount={amount} color={"ternary"} />
          <OSIcon
            name="chevron"
            rotate={collapsed ? "90" : "-90"}
            color={"ternary"}
            sx={{
              alignSelf: "center",
              pointerEvents: "none",
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default ShoppingCartTotal;
