import React from "react";
import DOMPurify from "dompurify";

const HTMLRenderer = ({ htmlString }) => {
  let unescapeHtml =
    htmlString &&
    htmlString
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">")
      .replaceAll("&quot;", '"')
      .replaceAll("&#039;", "'")
      .replaceAll("&amp;", "&");
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(unescapeHtml, { KEEP_CONTENT: false }),
      }}
    />
  );
};

export default HTMLRenderer;
