/**
 * Types of ErrorCodes.
 * @enum {string}
 */
const ErrorCode = {
  AUTHORIZATION_ERROR: "AUTHORIZATION_ERROR",
  MISSING_SECURITY_RIGHT: "MISSING_SECURITY_RIGHT",
  ABORT_ON_TIMEOUT_ERROR: "ABORT_ON_TIMEOUT_ERROR",
  API_OFFLINE: "API_OFFLINE",
  INVALID_SESSION_ID: "INVALID_SESSION_ID",
  DELETE_ITEM_ERROR: "DELETE_ITEM_ERROR",
  ACCOUNT_CREATION_ERROR: "ACCOUNT_CREATION_ERROR",
  GET_HANDSET_BY_SKU_FAILED: "GET_HANDSET_BY_SKU_FAILED",
  CHECK_RESOURCES_IN_STOCK_FAILED: "CHECK_RESOURCES_IN_STOCK_FAILED",
  INCOMPATIBLE_DEVICE_PLAN: "INCOMPATIBLE_DEVICE_PLAN",
  ADD_SA_FAILED: "ADD_SA_FAILED",
  GET_RANDOM_PHONE_NUMBERS_FAILED: "GET_RANDOM_PHONE_NUMBERS_FAILED",
  VALIDATE_PORT_IN_CHECK_FAILED: "VALIDATE_PORT_IN_CHECK_FAILED",
  INVALID_PORTED_NUMBER_LCA_NOT_AVAILABLE: "INVALID_PORTED_NUMBER_LCA_NOT_AVAILABLE",
  INVALID_PORTED_NUMBER_LCA_NOT_OPENED: "INVALID_PORTED_NUMBER_LCA_NOT_OPENED",
  INVALID_PORTED_NUMBER_PORT_IN_CHECK_STARTED: "INVALID_PORTED_NUMBER_PORT_IN_CHECK_STARTED",
  INVALID_PORTED_NUMBER_ALREADY_ASSIGNED: "INVALID_PORTED_NUMBER_ALREADY_ASSIGNED",
  INVALID_PORTED_NUMBER_LOCATION_NOT_SELLABLE: "INVALID_PORTED_NUMBER_LOCATION_NOT_SELLABLE",
  INVALID_PORTED_NUMBER_WRONG_WAREHOUSE: "INVALID_PORTED_NUMBER_WRONG_WAREHOUSE",
  INVALID_PORT_IN_CHECK_REQUEST_MANDATORY_FIELDS_MISSING:
    "INVALID_PORT_IN_CHECK_REQUEST_MANDATORY_FIELDS_MISSING",
  INVALID_PORTED_NUMBER_NO_VALID_GL_SEGMENT: "INVALID_PORTED_NUMBER_NO_VALID_GL_SEGMENT",
  PHONE_NUMBER_ALREADY_IS_USED_ERROR: "PHONE_NUMBER_ALREADY_IS_USED_ERROR",
  MISCONFIGURED_E911_IN_PACKAGE: "MISCONFIGURED_E911_IN_PACKAGE",
  NO_ITEMS_AVAILABLE: "NO_ITEMS_AVAILABLE",
  NOT_ALLOWED_ACTION_IN_CART: "NOT_ALLOWED_ACTION_IN_CART",
  ORDER_FAILED_DUE_TO_SUSPENDED_ACCOUNT: "ORDER_FAILED_DUE_TO_SUSPENDED_ACCOUNT",
  ORDER_FAILED_DUE_TO_PTC_ACCOUNT: "ORDER_FAILED_DUE_TO_PTC_ACCOUNT",
  ORDER_FAILED_DUE_TO_ACTIVE_HOT_BILLING: "ORDER_FAILED_DUE_TO_ACTIVE_HOT_BILLING",
  ORDER_FAILED_DUE_TO_ACTIVE_SAC: "ORDER_FAILED_DUE_TO_ACTIVE_SAC",
  ORDER_FAILED_DUE_TO_ACTIVE_SAS: "ORDER_FAILED_DUE_TO_ACTIVE_SAS",
  ORDER_FAILED_DUE_TO_SCHEDULED_CSC: "ORDER_FAILED_DUE_TO_SCHEDULED_CSC",
  ORDER_FAILED_DUE_TO_ACTIVE_DEBT_COLLECTION: "ORDER_FAILED_DUE_TO_ACTIVE_DEBT_COLLECTION",
  ORDER_FAILED_DUE_TO_ACTIVE_PORT_OUT: "ORDER_FAILED_DUE_TO_ACTIVE_PORT_OUT",
  SA_HAS_OPENED_FULFILLMENT: "SA_HAS_OPENED_FULFILLMENT",
  SA_HAS_PARKED_CART: "SA_HAS_PARKED_CART",
  SA_HAS_OPENED_PORT_OUT_PROCESS: "SA_HAS_OPENED_PORT_OUT_PROCESS",
  IS_CART_FULFILLMENT_CANCELED: "IS_CART_FULFILLMENT_CANCELED",
  PERFORM_PAYMENT_FAILED: "PERFORM_PAYMENT_FAILED",
  SA_HAS_OPENED_SPC_PROCESS: "SA_HAS_OPENED_SPC_PROCESS",
  INSTALLMENT_PLAN_NOT_CONFIGURED: "INSTALLMENT_PLAN_NOT_CONFIGURED",
  FINISH_SALE_FAILED: "FINISH_SALE_FAILED",
  FINISH_SALE_WITH_PAYMENT_FAILED: "FINISH_SALE_WITH_PAYMENT_FAILED",
  CREDITSCORE_ERROR: "CREDITSCORE_ERROR",
  EMPTY_ACCOUNT_PARAMETERS_FOR_3RD_PARTY: "EMPTY_ACCOUNT_PARAMETERS_FOR_3RD_PARTY",
  CREDIT_SCORE_AUTHORITY_RESPONDS_WITH_ERROR: "CREDIT_SCORE_AUTHORITY_RESPONDS_WITH_ERROR",
  INVALID_CREDIT_SCORE_CONTACT_PARAMETERS: "INVALID_CREDIT_SCORE_CONTACT_PARAMETERS",
  INQUIRING_NOT_SUPPORTED_FOR_COMMERCIAL_ACCOUNTS:
    "INQUIRING_NOT_SUPPORTED_FOR_COMMERCIAL_ACCOUNTS",
  VALIDATE_ACCOUNT_PIN_NO_MATCH: "VALIDATE_ACCOUNT_PIN_NO_MATCH",
  VALIDATE_ACCOUNT_PIN_ACCT_LOCKED: "VALIDATE_ACCOUNT_PIN_ACCT_LOCKED",
  VALIDATE_ACCOUNT_PIN_NOT_REGISTERED: "VALIDATE_ACCOUNT_PIN_NOT_REGISTERED",
  CART_IS_PROCESSING: "CART_IS_PROCESSING",
};

/**
 * code for none option contained in select components
 * @type {string}
 */
const NONE_CODE = "-1";

/**
 * Service code for "(None)" mutually exclusive option
 * @type {string}
 */
const NONE_ME_SRV_CODE = "NONE";

/**
 * Product/Service/Feature type option
 * @type {{RADIO: string, CHECKBOX: string}}
 */
const ComponentType = { RADIO: "RADIO", CHECKBOX: "CHECKBOX" };

/**
 * Price types.
 * @enum {string}
 */
const PRICE_TYPE = {
  RECURRING: "RECURRING",
  ONE_TIME: "ONE_TIME",
  PLAN_RECURRING: "PLAN_RECURRING",
  PLAN_ONE_TIME: "PLAN_ONE_TIME",
  PLAN_ADDITIONAL_RECURRING: "PLAN_ADDITIONAL_RECURRING",
  PLAN_ADDITIONAL_ONE_TIME: "PLAN_ADDITIONAL_ONE_TIME",
  PLAN_E911: "PLAN_E911",
  PLAN_CONNECTION_FEE: "PLAN_CONNECTION_FEE",
  OLD_INSTALLMENT: "OLD_INSTALLMENT",
  PLAN_SERVICE_DISCOUNT: "PLAN_SERVICE_DISCOUNT",
  PLAN_SERVICE_DISCOUNT_EXISTING: "PLAN_SERVICE_DISCOUNT_EXISTING",
  PLAN_SERVICE_IVD: "PLAN_SERVICE_IVD",
  PLAN_SERVICE_IVD_EXISTING: "PLAN_SERVICE_IVD_EXISTING",
  NONE: "NONE",
  FLEX_DEFERRED_AMOUNT: "FLEX_DEFERRED_AMOUNT",
  EASYTAB_DISCOUNT: "EASYTAB_DISCOUNT",
};

/**
 * Price terms units.
 */
const PRICE_TERMS_UNIT = {
  NONE: "NONE",
  MONTHS: "MONTHS",
};

/**
 * Personal information flows. Account with its details (e.g. card details) exist just for EXITING_CLIENT flow.
 * @type {{EXISTING_CLIENT: string, NEW_CUSTOMER: string}}
 */
const PERSONAL_INFORMATION_FLOW = {
  NEW_CUSTOMER: "NEW_CUSTOMER",
  EXISTING_ADD: "EXISTING_ADD",
  EXISTING_UPGRADE: "EXISTING_UPGRADE",
};

const canLogin = (flow) => {
  return [
    PERSONAL_INFORMATION_FLOW.EXISTING_ADD,
    PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE,
  ].includes(flow);
};

/**
 * Available item categories
 * @type {{BUNDLE: string, DEVICE: string, PLAN: string, SHIPPING: string, ACCESSORY: string}}
 */
const ITEM_CATEGORY = {
  BUNDLE: "BUNDLE",
  PLAN: "PLAN",
  DEVICE: "DEVICE",
  SHIPPING: "SHIPPING",
  ACCESSORY: "ACCESSORY",
};

const ITEM_ATTRIBUTE_CODE = {
  GOOD_TYPE: "GOOD_TYPE",
  GOOD_MODEL: "GOOD_MODEL",
  TAX_INCLUDED: "TAX_INCLUDED",
  FIRST_NAME: "FIRST_NAME",
  LAST_NAME: "LAST_NAME",
  ADDRESS: "ADDRESS",
  CITY: "CITY",
  STATE: "STATE",
  POSTAL_CODE: "POSTAL_CODE",
  PHONE_NUMBER: "PHONE_NUMBER",
  EMAIL_ADDRESS: "EMAIL_ADDRESS",
  SHIPPING_NOTE: "SHIPPING_NOTE",
  SHIPPING_DESCRIPTION: "SHIPPING_DESCRIPTION",
  SHIPPING_METHOD: "SHIPPING_METHOD",
  SHIPMENT_OPTION: "SHIPMENT_OPTION",
  IS_NEW_ADDRESS: "IS_NEW_ADDRESS",
  BUZZER_CODE: "BUZZER_CODE",
};

const HIDDEN_ATTRIBUTES = Object.keys(ITEM_ATTRIBUTE_CODE)
  .filter((key) => ITEM_ATTRIBUTE_CODE.SHIPPING_DESCRIPTION !== key)
  .map((key) => ITEM_ATTRIBUTE_CODE[key]);

const NEURAL_RESPONSE = {
  SUCCESS: "0001",
  ALERT: "0002",
  TIMEOUT: "0003",
  VALIDATIONS: "0004",
};

const POS_PARAMETER = {
  ONLINE_STORE_CHAT_URL: "ONLINE_STORE_CHAT_URL",
  ONLINE_STORE_PROVIDER: "ONLINE_STORE_PROVIDER",
  OS_TERMS_AND_CONDITIONS_URL: "OS_TERMS_AND_CONDITIONS_URL",
  ESIM_SUPPORT: "ESIM_SUPPORT",
};

const SYS_CONFIG_PARAMETER = {
  ECOMM_CPE_SIM_TYPE_VISIBILITY: "ECOMM_CPE_SIM_TYPE_VISIBILITY",
  ECOMM_SIM_TYPE_VISIBILITY: "ECOMM_SIM_TYPE_VISIBILITY",
};

const DOCUMENT_TYPE = {
  CONTRACT: "CONTRACT",
  DEVICE_PROTECTION_EASY_UP: "DEVICE_PROTECTION_EASY_UP",
  EASYTAB_FLEX: "EASYTAB_FLEX",
};

const ACCOUNT_STATUS = {
  NEW: "N",
  ACTIVE: "A",
  SUSPENDED: "S",
  CLOSED: "C",
  TERMINATED: "T",
  OLD: "O",
  ARCHIVED: "R",
};

const SECURITY_RIGHTS = {
  SCR_POS_ALLOW_DEFER_PAYMENTS: "SCR_POS_ALLOW_DEFER_PAYMENTS",
};

const OLD_INSTALLMENT_OPTION = {
  NONE: "NONE",
  KEEP: "KEEP",
  PAY: "PAY",
  PAY_FLEX: "PAY_FLEX",
};

const SELFCARE_PROFILE = {
  SELFCAREFULL: "SELFCAREFULL",
  SELFCARESA: "SELFCARESA",
};

const PACKAGE_MODIFIED = {
  PACKAGE_NOT_MODIFIED: 0,
  PACKAGE_CHANGED: 1,
  PACKAGE_UPDATED: 2,
};

const SESSION_STATUS = {
  CREATED: "CREATED",
  VALIDATE_IDENTITY: "VALIDATE_IDENTITY",
  VALIDATE_PIN: "VALIDATE_PIN",
  VALIDATED_CUSTOMER: "VALIDATED_CUSTOMER",
  FINALIZED_BUNDLE: "FINALIZED_BUNDLE",
  COLLECTED_SHIPPING: "COLLECTED_SHIPPING",
  PAID: "PAID",
  FINISHED: "FINISHED",
};

const SIM_TYPE = {
  SIM: "SIM",
  eSIM: "ESIM",
};

const INVALID_FIELDS = { AUTOCOMPLETE: "AUTOCOMPLETE", SELFCARE_ACCESS: "SELFCARE_ACCESS" };

const SIM_CAPABILITY = { SIM_ONLY: "SIM Only", ESIM_ONLY: "eSIM Only", ESIM_AND_SIM: "eSIM & SIM" };

const EXTRA_INFO = {
  EASY_TAB_ASSOCIATED: "EASY_TAB_ASSOCIATED",
  DISCOUNT_RULE_CODE: "DISCOUNT_RULE_CODE",
  DEFERRED_MONTHS: "DEFERRED_MONTHS",
  BYOD_SKU: "BYOD_SKU",
  BOTH_SIM: "BOTH_SIM",
  SELECTED_PRICING_OPTION: "SELECTED_PRICING_OPTION",
  HIDE_UPFRONT_PAYMENT: "HIDE_UPFRONT_PAYMENT",
  FLEX_END_DATE_PERIOD: "FLEX_END_DATE_PERIOD",
  FLEX_DEFERRED_AMOUNT: "FLEX_DEFERRED_AMOUNT",
  FLEX_RETURN: "FLEX_RETURN",
  ELIGIBLE_ONLY_WITH_PLAN_CHANGE: "ELIGIBLE_ONLY_WITH_PLAN_CHANGE",
};
const CPE_SKU_CODE = "CPEOUTGSM";

const PRICING_OPTION = {
  EASYTABFLEX: "EASYTABFLEX",
  EASYTAB: "EASYTAB",
  RETAIL: "RETAIL",
};

export {
  ErrorCode,
  NEURAL_RESPONSE,
  ComponentType,
  NONE_CODE,
  NONE_ME_SRV_CODE,
  PRICE_TYPE,
  PRICE_TERMS_UNIT,
  PERSONAL_INFORMATION_FLOW,
  ITEM_CATEGORY,
  ITEM_ATTRIBUTE_CODE,
  HIDDEN_ATTRIBUTES,
  POS_PARAMETER,
  SYS_CONFIG_PARAMETER,
  DOCUMENT_TYPE,
  ACCOUNT_STATUS,
  SECURITY_RIGHTS,
  OLD_INSTALLMENT_OPTION,
  PACKAGE_MODIFIED,
  canLogin,
  SELFCARE_PROFILE,
  SESSION_STATUS,
  SIM_TYPE,
  EXTRA_INFO,
  CPE_SKU_CODE,
  SIM_CAPABILITY,
  INVALID_FIELDS,
  PRICING_OPTION,
};
