import React from "react";
import { Box } from "@theme-ui/components";

const NoPickUp = ({ color = "svgStrokePrimary", size = "iconLarge", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 54"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <g transform="translate(-12206.875 1136.375)">
      <g transform="translate(12213 -1127)">
        <g transform="translate(15.904 11.04)" opacity="0.4">
          <g transform="translate(1.49 5)">
            <path
              d="M8.461,33.34V27.3a.3.3,0,0,1,.324-.264h4.353a.3.3,0,0,1,.324.264V33.34"
              transform="translate(-3.98 -14.685)"
              fill="none"
              stroke={color}
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <g
              transform="translate(11.481 11.437)"
              fill="none"
              stroke={color}
              strokeLinejoin="round"
              strokeWidth="2">
              <rect width="7" height="5" rx="0.7" stroke="none" />
              <rect x="1" y="1" width="5" height="3" rx="0.3" fill="none" />
            </g>
            <path
              d="M20.144,2.61V.8a.3.3,0,0,0-.3-.3H3.15a.3.3,0,0,0-.3.3V2.61L.5,7.5v.976A1.134,1.134,0,0,0,1.634,9.608H21.357a1.134,1.134,0,0,0,1.134-1.134V7.5Z"
              transform="translate(-0.5 -0.5)"
              fill="none"
              stroke={color}
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <rect
              width="20.016"
              height="9.547"
              transform="translate(1.078 9.108)"
              fill="none"
              stroke={color}
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </g>
        <path
          d="M0,11.97A11.976,11.976,0,0,1,12.234,0a12.221,12.221,0,0,1,11.7,11.967,11.515,11.515,0,0,1-1.6,5.983L14.361,33.375a2.749,2.749,0,0,1-4.786,0L1.6,17.952A11.182,11.182,0,0,1,0,11.97Z"
          transform="translate(0 0)"
          fill="#fff"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M62.782,26.749,53.7,35.833"
          transform="translate(-46.273 -18.703)"
          fill="#fff"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          d="M53.7,26.749l9.084,9.084"
          transform="translate(-46.272 -18.703)"
          fill="#fff"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </Box>
);

export default NoPickUp;
