import React from "react";
import { Flex, Text } from "@theme-ui/components";

import Amount from "../../../../components/amount";
import { OSRadio } from "../../../../components/base";

/**
 * Shipping Method.
 *
 * @param {Object} props Component props.
 *
 * @param {Object} props.data Shipping method object.
 * @param {String} props.data.title Shipping method title.
 * @param {String} props.data.description Shipping method description.
 * @param {Number} props.data.amount Shipping method price.
 * @param {String} props.data.icon Shipping method icon.
 *
 * @param {Boolean} props.data.selected True if this shipping method is selected.
 * @param {Function} props.onSelect On select event callback.
 */
const ShippingMethod = ({ data, selected = true, onSelect, ...props }) => {
  return (
    <Flex
      ref={data.ref}
      bg={selected ? "selectedCardBg" : "cardBg"}
      onClick={onSelect}
      p={4}
      {...props}
      sx={{
        width: "100%",
        maxWidth: ["100%", "100%", "20.75rem"],
        alignItems: "center",
        border: 1,
        borderColor: selected ? "secondary" : "cardBorderColor",
        borderRadius: "lg",
        boxShadow: "sm",
        cursor: "pointer",
        ":hover": !selected && {
          borderColor: "cardBorderHoverColor",
        },
      }}>
      <OSRadio checked={selected} onChange={onSelect} />

      <Flex sx={{ flexDirection: "column", width: "100%" }}>
        <Flex sx={{ justifyContent: "space-between", width: "100%" }}>
          <Text variant="deliveryType" mr={2}>
            {data.title}
          </Text>
          <Amount
            compact
            color="ternary"
            amount={data.amount}
            ml="auto"
            type="semibold_large"
            plusTax={data.tax > 0}
          />
        </Flex>
        <Text
          variant="summaryLabel"
          color={selected ? "selectedCardTextColor" : "quaternary"}
          mt={0.5}>
          {data.description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ShippingMethod;
