import React, { useLayoutEffect, useRef, useState } from "react";
import { Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";
import { useWindowSize } from "../../hooks";
import { OSIcon } from "../icons";
import HRTriangle from "../HRTriangle";

const SingleTabHeader = ({ messageLbl, icon }) => {
  const intl = useIntl();
  const windowSize = useWindowSize();

  const headerContainer = useRef(null);
  const option = useRef(null);

  const [hrTrianglePosition, setHrTrianglePosition] = useState(459);
  const [hrWidth, setHrWidth] = useState(0);

  useLayoutEffect(() => {
    setHrTrianglePosition(
      option.current.offsetLeft -
        headerContainer.current.offsetLeft +
        option.current.offsetWidth / 2
    );
    setHrWidth(
      headerContainer && headerContainer.current ? headerContainer.current.offsetWidth : 0
    );
  }, [windowSize.width]);

  return (
    <>
      <Flex sx={{ alignItems: "center" }} mb={7} px={[8, 8, 9]} ref={headerContainer}>
        <Flex
          sx={{
            alignItems: "center",
            height: "100%",
            justifyContent: "flex-start",
            maxWidth: ["auto", "auto", "20rem"],
          }}>
          <Text variant="heading3" color="ternary">
            {intl.formatMessage({ id: messageLbl })}
          </Text>
        </Flex>

        <Flex ref={option} ml={16}>
          <OSIcon name={icon} />
        </Flex>
      </Flex>

      <HRTriangle trianglePosition={hrTrianglePosition} width={hrWidth} />
    </>
  );
};

export default SingleTabHeader;
