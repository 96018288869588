import React from "react";
import { Box } from "@theme-ui/components";

const Receipt = ({ color = "svgStrokePrimary", size = "iconMedium", ...props }) => (
  <>
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 25"
      {...props}
      sx={{ width: size, height: size, stroke: color }}>
      <g transform="translate(0.5 0.5)">
        <g transform="translate(-5 -2)" opacity="0.4">
          <path
            d="M4,0H14a4,4,0,0,1,4,4V24l-3-3-3,3L9,21,6,24,3,21,0,24V4A4,4,0,0,1,4,0Z"
            transform="translate(5 2)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M12011,5842.375l2.82-2.821,2.82-2.821"
            transform="translate(-4351.876 -12613.438) rotate(45)"
            fill="none"
            stroke="#332a86"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            d="M12011,5842.375l2.82-2.821,2.82-2.821"
            transform="translate(-4351.876 -12609.448) rotate(45)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </Box>
  </>
);

export default Receipt;
