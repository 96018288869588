/**
 * Internal OS Exception - simulated with a function which returns a serializable object.
 * Redux Toolkit requires a serializable object else serialization errors are thrown.
 * Another workaround would be to configure middleware to ignore exceptions(which are not serializable)
 * by actionType/path, but this should be done for each async call.
 * See https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data for details
 *
 * @param errorCode
 */
export function OSMindException(errorCode) {
  return {
    name: "OSMindException",
    errorCode: errorCode,
    stack: new Error().stack,
  };
}
