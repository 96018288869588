import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";

export const getMaClass = createAsyncThunk("maAccountClass/getMaClass", async () => {
  return await FacadeAPI.GET("/accountDetails/maAccountClass");
});

const maClassSlice = createSlice({
  name: "maAccountClass",
  initialState: {
    loading: false,
    error: null,

    class: {},
  },
  reducers: {},
  extraReducers: {
    [getMaClass.pending]: (state) => {
      state.loading = true;
    },
    [getMaClass.fulfilled]: (state, action) => {
      state.loading = false;
      state.class = action.payload;
    },
    [getMaClass.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default maClassSlice.reducer;
