import React from "react";
import { Box } from "@theme-ui/components";

const Visa = ({ color = "svgStrokePrimary", size = "iconLarge", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 32"
    sx={{ height: size, width: size, fill: color, FlexShrink: 0 }}
    {...props}>
    <path
      d="M4,0H44q.2,0,.392.019t.388.058q.193.038.381.1T45.531.3q.181.075.355.168t.337.2q.163.109.315.234t.291.264q.139.139.264.291t.234.315q.109.163.2.337t.168.355q.075.182.132.369t.1.381q.038.193.058.388T48,4V28q0,.2-.019.392t-.058.388q-.038.193-.1.381t-.132.369q-.075.182-.168.355t-.2.337q-.109.163-.234.315t-.264.291q-.139.139-.291.264t-.315.234q-.163.109-.337.2t-.355.168q-.181.075-.369.132t-.381.1q-.193.038-.388.057T44,32H4q-.2,0-.392-.019t-.388-.057q-.193-.038-.381-.1T2.469,31.7q-.182-.075-.355-.168t-.337-.2q-.163-.109-.315-.234t-.291-.264q-.139-.139-.264-.291t-.234-.315q-.109-.163-.2-.337T.3,29.531q-.075-.182-.132-.369t-.1-.381q-.038-.193-.058-.388T0,28V4q0-.2.019-.392t.058-.388q.038-.193.1-.381T.3,2.469q.075-.182.168-.355t.2-.337q.109-.163.234-.315t.264-.291q.139-.139.291-.264T1.777.674q.163-.109.337-.2T2.469.3Q2.65.229,2.838.172t.381-.1q.193-.038.388-.058T4,0Z"
      fill="#fff"
    />
    <path
      d="M23.86,11.39l-2,9.25h-2.4l2-9.25Zm10.06,6,1.26-3.47.73,3.47Zm2.67,3.28h2.22l-1.94-9.25h-2a1.09,1.09,0,0,0-1,.68l-3.59,8.57H32.8l.49-1.38H36.3Zm-6.24-3C30.36,15.18,27,15,27,14c0-.33.32-.68,1-.77a4.48,4.48,0,0,1,2.36.41l.42-2a6.441,6.441,0,0,0-2.24-.41c-2.36,0-4,1.26-4,3.06,0,1.33,1.19,2.07,2.09,2.51s1.25.75,1.24,1.15c0,.62-.74.9-1.43.91a5,5,0,0,1-2.44-.6l-.43,2a7.162,7.162,0,0,0,2.66.49c2.51,0,4.16-1.24,4.17-3.16m-9.91-6.23-3.88,9.25H14l-1.91-7.39a1,1,0,0,0-.57-.81,9.889,9.889,0,0,0-2.36-.78l.06-.27h4.07a1.12,1.12,0,0,1,1.11.94l1,5.35,2.49-6.29Z"
      fill={color}
    />
  </Box>
);

export default Visa;
