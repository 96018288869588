import React from "react";
import { Box } from "@theme-ui/components";

const Contract = ({ color = "0c3865", size = "iconMedium", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.057 20.801"
    sx={{
      width: size,
      height: size,
      stroke: color,
      flexShrink: 0,
    }}
    {...props}>
    <g transform="translate(.378 .424)">
      <path
        d="M4.333 20.255H13.8a3.76 3.76 0 0 0 3.76-3.76.7.7 0 0 0-.7-.7h-3.609V1.347a.439.439 0 0 0-.739-.322l-2.15 2a.439.439 0 0 1-.6 0L7.174.617a.439.439 0 0 0-.6 0L3.987 3.025a.439.439 0 0 1-.6 0l-2.15-2a.439.439 0 0 0-.737.322V16.5a3.76 3.76 0 1 0 7.52 0 .7.7 0 0 1 .7-.7h4.571"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-.378 -.378)"
      />
      <path
        d="M0 0L5.684 0"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(5.5 6.005)"
      />
      <path
        d="M0 0L9.274 0"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(1.91 9.214)"
      />
      <path
        d="M0 0L9.274 0"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(1.91 12.422)"
      />
    </g>
  </Box>
);

export default Contract;
