import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { BaseModalBackground } from "./BaseModalBackground";

const ModalContext = React.createContext();

const ModalProvider = ({ backgroundComponent: propsBackgroundComponent, children }) => {
  const modalNode = useRef(null);
  const [stateModalNode, setStateModalNode] = useState(null);
  const [BackgroundComponent, setBackgroundComponent] = useState(BaseModalBackground);

  useEffect(() => {
    if (propsBackgroundComponent) {
      setBackgroundComponent(propsBackgroundComponent);
    }
  }, [setBackgroundComponent, propsBackgroundComponent]);

  useEffect(() => {
    setStateModalNode(modalNode.current);
  }, [setStateModalNode, modalNode]);

  return (
    <ModalContext.Provider
      value={{
        modalNode: stateModalNode,
        BackgroundComponent: BackgroundComponent,
      }}>
      {children}
      <div ref={modalNode} />
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  backgroundComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
};

export { ModalProvider, ModalContext };
