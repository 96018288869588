import React from "react";
import { Flex } from "@theme-ui/components";

import { OSIcon } from "../icons";
import BaseSkeleton from "./BaseSkeleton";

const StoreSkeleton = () => {
  return (
    <Flex
      sx={{
        border: 1,
        borderColor: "#e2e2e2",
        borderRadius: "2lg",
        boxShadow: "sm",
        bg: "cardBg",
        flexDirection: "column",
        maxWidth: ["100%", "17.625rem"],
        p: 5,
        width: "100%",
      }}>
      <BaseSkeleton width="100%" height="1.5rem" />
      <Flex sx={{ alignItems: "center" }} mt={4}>
        <OSIcon name="location-pin" mr={2} />
        <BaseSkeleton width="100%" height="1.5rem" />
      </Flex>
      <Flex sx={{ alignItems: "center" }} mt={4}>
        <OSIcon name="location-pin-distance" mr={2} />
        <BaseSkeleton width="100%" height="1.5rem" />
      </Flex>
      <Flex sx={{ alignItems: "center" }} my={4}>
        <OSIcon name="phone-number" mr={2} />
        <BaseSkeleton width="100%" height="1.5rem" />
      </Flex>
    </Flex>
  );
};

export default StoreSkeleton;
