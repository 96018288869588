import React, {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Box, Flex, Text } from "@theme-ui/components";
import Layout from "../../components/layout";
import { OSContainer } from "../../components/base";
import { CHECKOUT_STEPS } from "../../components/header/Constants";
import Amount from "../../components/amount";
import { PAYMENT_METHOD, PAYMENT_METHOD_CARD } from "./Constants";
import { OSIcon } from "../../components/icons";
import SingleTabHeader from "../../components/single-tab-header/SingleTabHeader";
import { useWindowSize } from "../../hooks";
import {
  clearIpPayInitiateHostedCCResponse,
  finishSession,
  initiateHostedCcOperation,
  loadAccountCreditCard,
  performCartPayment,
  getItems,
  getSecurityRight,
  checkAndRefreshToken,
} from "../../config/redux/slices";
import { canLogin, ErrorCode, PRICE_TYPE, SECURITY_RIGHTS } from "../../common/Constants";
import { SHIPPING_FLOW } from "../shipping-details/Constants";
import { StyledModalMessage } from "../../components/modals";
import Spinner from "../../components/spinner/Spinner";
import HRTriangle from "../../components/HRTriangle";
import ErrorHandler from "../../components/error-handler";
import {
  clearFinishSession,
  clearPerformCartPayment,
  getSession,
} from "../../config/redux/slices/SessionSlice";
import Storage from "store2";
import { storefrontURLs } from "../../config/security/Deployment";

const Option = forwardRef(
  ({ selected, isSelectionAllowed, onSelection, isCCOption, children, ...props }, ref) => {
    return (
      <Flex
        ref={ref}
        {...props}
        sx={{
          bg: selected && isSelectionAllowed && isCCOption ? "selectedCardBg" : "cardBg",
          color: selected && isSelectionAllowed && isCCOption ? "selectedCardTextColor" : "ternary",
          width: ["10rem", "13.25rem", "13.25rem"],
          height: ["8.313rem", "8.313rem", "8.313rem"],
          alignItems: "center",
          justifyContent: "center",
          border: 1,
          borderColor: selected && isSelectionAllowed ? "secondary" : "greyish",
          borderRadius: "2lg",
          boxShadow: "sm",
          cursor: isSelectionAllowed ? "pointer" : "default",
          ":hover": !selected &&
            isSelectionAllowed && {
              borderColor: "cardBorderHoverColor",
            },
          ...props.sx,
        }}
        onClick={isSelectionAllowed ? onSelection : undefined}>
        {children}
      </Flex>
    );
  }
);

const IFrame = ({ srcdoc }) => {
  return (
    <Box px={[5, 5, 5, 9]} sx={{ height: ["650px", "550px", "570px", "475px"] }}>
      <iframe srcDoc={srcdoc} width="100%" height="100%" style={{ border: 0 }} />
    </Box>
  );
};

const PaymentInformation = () => {
  const intl = useIntl();
  const history = useHistory();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentMethodCard, setPaymentMethodCard] = useState(null);
  const [errorOnIppay, setErrorOnIppay] = useState(false);
  const [hrTrianglePosition, setHrTrianglePosition] = useState(459);
  const [hrTrianglePositionIframe, setHrTrianglePositionIframe] = useState(459);
  const [hrWidth, setHrWidth] = useState(0);
  const [card, setCard] = useState(null);
  const [cartProcessing, setCartProcessing] = useState(false);

  const initiateHostedCcOperationState = useSelector(
    (state) => state.ippay.initiateHostedCcOperation
  );
  const personalInformationData = useSelector((state) => state.personalInformation);
  const sessionData = useSelector((state) => state.session);
  const itemsState = useSelector((state) => state.items);
  const accountCreditCardState = useSelector((state) => state.accountCreditCard);
  const cartPaymentState = useSelector((state) => state.session.performCartPayment);
  const shippingDetailsState = useSelector((state) => state.shippingDetails);
  const securityRights = useSelector(
    (state) => state.configuration.getSecurityRight.securityRights
  );
  const { loggedIn } = useSelector((state) => state.auth);

  const paymentContainer = useRef(null);
  const paymentMethodOption = useRef(null);
  const existingCardOption = useRef(null);
  const newCardOption = useRef(null);

  const paymentSuccessRef = useRef(null);

  const [iPPayForm, setIPPayForm] = useState(null);
  const [errorMessageIPPay, setErrorMessageIPPay] = useState("");

  useEffect(() => {
    !itemsState.getItems.success && dispatch(getItems());
    dispatch(getSecurityRight(SECURITY_RIGHTS.SCR_POS_ALLOW_DEFER_PAYMENTS));
  }, [dispatch, itemsState.getItems.success]);

  const amount = itemsState.getItems.items
    .map((item) => item.prices)
    .flat()
    .reduce((previous, current) => {
      if (current.type === PRICE_TYPE.ONE_TIME || current.type === PRICE_TYPE.OLD_INSTALLMENT) {
        return previous + current.price + current.tax;
      }
      return previous;
    }, 0);

  useEffect(() => {
    if (canLogin(personalInformationData.flow)) {
      dispatch(loadAccountCreditCard());
    }
  }, [dispatch, personalInformationData.flow]);

  const isCardPaymentMethodTypeOf = useCallback(
    (cardPaymentMethodType) => {
      return (
        selectedPaymentMethod === PAYMENT_METHOD.CARD_PAYMENT &&
        cardPaymentMethodType === paymentMethodCard
      );
    },
    [selectedPaymentMethod, paymentMethodCard]
  );

  useLayoutEffect(() => {
    if (isCardPaymentMethodTypeOf(PAYMENT_METHOD_CARD.EXISTING)) {
      setHrTrianglePosition(
        existingCardOption.current.offsetLeft -
          paymentContainer.current.offsetLeft +
          existingCardOption.current.offsetWidth / 2
      );
    }
    if (isCardPaymentMethodTypeOf(PAYMENT_METHOD_CARD.NEW)) {
      setHrTrianglePosition(
        newCardOption.current.offsetLeft -
          paymentContainer.current.offsetLeft +
          newCardOption.current.offsetWidth / 2
      );
    }
    setHrTrianglePositionIframe(
      paymentMethodOption.current.offsetLeft -
        paymentContainer.current.offsetLeft +
        paymentMethodOption.current.offsetWidth / 2
    );
    setHrWidth(
      paymentContainer && paymentContainer.current ? paymentContainer.current.offsetWidth : 0
    );
  }, [isCardPaymentMethodTypeOf, windowSize.width]);

  const performCardPayment = useCallback(() => {
    if ((isCardPaymentMethodTypeOf(PAYMENT_METHOD_CARD.NEW) || !card) && amount > 0) {
      dispatch(
        initiateHostedCcOperation({
          amount: amount,
          responseUrl: window.location.origin + encodeURIComponent(window.location.pathname),
          sessionId: sessionData.sessionId,
        })
      );
    } else if (isCardPaymentMethodTypeOf(PAYMENT_METHOD_CARD.EXISTING) && amount > 0) {
      dispatch(performCartPayment({ amountToPay: amount }));
    } else {
      dispatch(finishSession());
    }
  }, [amount, card, dispatch, isCardPaymentMethodTypeOf, sessionData.sessionId]);

  const performPayInStorePayment = useCallback(() => {
    if (amount > 0 && selectedPaymentMethod === PAYMENT_METHOD.PAY_IN_STORE) {
      dispatch(finishSession(true));
    } else {
      dispatch(finishSession());
    }
  }, [amount, dispatch, selectedPaymentMethod]);

  const performPaymentHandler = useCallback(async () => {
    try {
      if (loggedIn) {
        const refreshResult = await dispatch(checkAndRefreshToken());
        unwrapResult(refreshResult);
      }
      if (selectedPaymentMethod === PAYMENT_METHOD.CARD_PAYMENT) {
        return performCardPayment();
      }
      performPayInStorePayment();
    } catch (error) {
      console.log("checkAndRefreshToken ERROR");
      //do nothing
    }
  }, [dispatch, loggedIn, performCardPayment, performPayInStorePayment, selectedPaymentMethod]);

  useEffect(() => {
    if (initiateHostedCcOperationState.success) {
      setIPPayForm(`<meta name="viewport" content="width=device-width, initial-scale=1.0">
      <script nonce="randomsecret123456">window.onload=function(){document.forms.payform.submit()}</script>
      <body>
        <p><i>Loading...</i></p>
        <form name="payform" action="${
          initiateHostedCcOperationState.ipPayPaymentRequest.hostedURL
        }" method="POST">
          <input type="hidden" name="CustomerId" value="${
            initiateHostedCcOperationState.ipPayPaymentRequest.customerID
          }">
          <input type="hidden" name="MID" value="${
            initiateHostedCcOperationState.ipPayPaymentRequest.merchantID
          }">
          <input type="hidden" name="TerminalID" value="${
            initiateHostedCcOperationState.ipPayPaymentRequest.terminalID
          }">
          <input type="hidden" name="SaveForFuture" value="${
            initiateHostedCcOperationState.ipPayPaymentRequest.saveForFuture
          }">
          <input type="hidden" name="ReferenceId" value="${
            initiateHostedCcOperationState.ipPayPaymentRequest.referenceID
          }">
          <input type="hidden" name="Amount" value="${
            initiateHostedCcOperationState.ipPayPaymentRequest.amount
          }">
          <input type="hidden" name="CallbackURL" value="${
            window.location.origin + initiateHostedCcOperationState.ipPayPaymentRequest.callbackURL
          }">
          <input type="hidden" name="Token" value="${
            initiateHostedCcOperationState.ipPayPaymentRequest.token
          }">
          <input type="hidden" name="TransactionType" value="${
            initiateHostedCcOperationState.ipPayPaymentRequest.transactionType
          }">
          <input type="hidden" name="PaymentType" value="CC">
          <input type="hidden" name="UDField3" value="${
            initiateHostedCcOperationState.ipPayPaymentRequest.accountCode
          }">
        </form>
      </body>`);
    }
    dispatch(clearIpPayInitiateHostedCCResponse());
  }, [
    dispatch,
    initiateHostedCcOperationState.ipPayPaymentRequest,
    initiateHostedCcOperationState.success,
  ]);

  useEffect(() => {
    if (
      sessionData.getSession.success &&
      ((sessionData.posCartPaymentId && sessionData.posCartPaymentId !== -1) ||
        (amount > 0 && selectedPaymentMethod === PAYMENT_METHOD.PAY_IN_STORE) ||
        (amount === 0 &&
          (sessionData.status === "PROCESSING" || sessionData.status === "FINISHED")))
    ) {
      if (sessionData.cartStatus === "PAID") {
        dispatch(finishSession());
      } else if (sessionData.cartStatus === "PROCESSING") {
        //error
        setCartProcessing(true);
      } else if (
        sessionData.cartStatus === "CLOSED" ||
        sessionData.cartStatus === "PAYMENT_DEFERRED"
      ) {
        //move to confirmation
        history.push("/order-confirmation");
      } else if (sessionData.status === "FINISHED" && sessionData.posCartId === -1) {
        Storage.clear();
        window.location.replace(storefrontURLs.base);
      }
    }
  }, [
    dispatch,
    sessionData.posCartPaymentId,
    sessionData.cartStatus,
    sessionData.status,
    sessionData.posCartId,
    sessionData.getSession.success,
    history,
    amount,
    selectedPaymentMethod,
  ]);

  useEffect(() => {
    if (cartPaymentState.success) {
      dispatch(finishSession());
    }
  }, [dispatch, cartPaymentState.success]);

  useEffect(() => {
    if (sessionData.finishSession.success) {
      history.push("/order-confirmation");
    }
  }, [history, sessionData.finishSession.success]);

  const [qryParams, setQryParams] = useState(null);

  useEffect(() => {
    const handler = (event) => {
      let data = null;
      try {
        data = JSON.parse(event.data);
      } catch (e) {
        return;
      }
      if (data.queryParams) {
        setQryParams(data.queryParams);
      }
    };

    window.addEventListener("message", handler, false);
  }, []);

  useEffect(() => {
    if (qryParams && Object.keys(qryParams).length > 0) {
      setIPPayForm(null);
      if (qryParams.errorMessage) {
        setSelectedPaymentMethod(null);
        setPaymentMethodCard(null);
        setErrorOnIppay(true);
        setErrorMessageIPPay(qryParams.errorMessage);
        return;
      } else if (qryParams.amount > 0) {
        paymentSuccessRef.current.click();
        dispatch(finishSession());
        setSelectedPaymentMethod(null);
        setPaymentMethodCard(null);
        return;
      } else {
        setSelectedPaymentMethod(null);
        setPaymentMethodCard(null);
        return;
      }
    }
  }, [dispatch, history, qryParams]);

  const clearErrorOnIppay = () => {
    setErrorOnIppay(false);
  };

  const clearErrorOnFinishSale = () => {
    if (
      sessionData.finishSession.error &&
      sessionData.finishSession.error.errorCode == ErrorCode.AUTHORIZATION_ERROR
    ) {
      dispatch(clearFinishSession());
    } else if (sessionData.finishSession.error?.errorCode == ErrorCode.CART_IS_PROCESSING) {
      dispatch(clearFinishSession());
      dispatch(getSession());
    } else {
      Storage.clear();
      window.location.replace(storefrontURLs.base);
    }
  };

  const clearErrorOnHostedCcOperation = () => {
    dispatch(clearIpPayInitiateHostedCCResponse());
    setSelectedPaymentMethod(null);
  };

  const clearCartPayment = () => {
    dispatch(clearPerformCartPayment());
  };

  const clearCartProcessing = () => {
    setCartProcessing(false);
    dispatch(getSession());
  };

  const selectPaymentMethodHandler = (paymentMethod) => {
    if (isPayAllowed && paymentMethod === PAYMENT_METHOD.CARD_PAYMENT) {
      setCard(accountCreditCardState.creditCard);
      accountCreditCardState.creditCard && setPaymentMethodCard(PAYMENT_METHOD_CARD.EXISTING);
      setSelectedPaymentMethod(paymentMethod);
    }
    if (isPayInStoreAllowed && paymentMethod === PAYMENT_METHOD.PAY_IN_STORE) {
      setCard(null);
      setPaymentMethodCard(null);
      setSelectedPaymentMethod(paymentMethod);
    }
  };

  const hasExistingCard = card !== null;

  const isExistingCustomerCardPayment =
    selectedPaymentMethod === PAYMENT_METHOD.CARD_PAYMENT && canLogin(personalInformationData.flow);

  const isPayAllowed = amount > 0;

  const isPayInStoreAllowed =
    isPayAllowed && shippingDetailsState.shippingFlow === SHIPPING_FLOW.PICK_IN_STORE;

  const isPayInStoreVisible = securityRights[SECURITY_RIGHTS.SCR_POS_ALLOW_DEFER_PAYMENTS];

  const isPaymentDisabled =
    (isPayAllowed && !selectedPaymentMethod) ||
    sessionData.finishSession.loading ||
    initiateHostedCcOperationState.loading;

  const isPaymentWithNewCard =
    selectedPaymentMethod === PAYMENT_METHOD.CARD_PAYMENT &&
    (!isExistingCustomerCardPayment ||
      (isExistingCustomerCardPayment && !card) ||
      (isExistingCustomerCardPayment && card && paymentMethodCard === PAYMENT_METHOD_CARD.NEW));

  useEffect(() => {
    if (isPaymentWithNewCard) {
      performPaymentHandler();
    }
  }, [isPaymentWithNewCard, performPaymentHandler]);

  return (
    <Layout
      checkoutStep={CHECKOUT_STEPS.PAYMENT_INFORMATION.index}
      pageTitle={intl.formatMessage({ id: CHECKOUT_STEPS.PAYMENT_INFORMATION.label })}
      nextStepButton={() => {
        if (isPaymentWithNewCard) {
          return null;
        } else {
          return (
            <Button
              id="submitPayment"
              disabled={isPaymentDisabled}
              variant="default-next"
              onClick={performPaymentHandler}>
              {intl.formatMessage({
                id:
                  amount > 0 && selectedPaymentMethod !== PAYMENT_METHOD.PAY_IN_STORE
                    ? "btn.payment-information-next-step"
                    : "btn.payment-information-next-step-amount-0",
              })}
            </Button>
          );
        }
      }}>
      {/*payment or finish sale error */}
      <StyledModalMessage
        isOpen={errorOnIppay}
        onRequestClose={clearErrorOnIppay}
        message={errorMessageIPPay}>
        <Button variant="simple-action" onClick={clearErrorOnIppay}>
          <FormattedMessage id="btn.ok" />
        </Button>
      </StyledModalMessage>
      {/* cart is processing error*/}
      <StyledModalMessage
        isOpen={cartProcessing}
        onRequestClose={clearCartProcessing}
        message={intl.formatMessage({ id: "err.cart_processing" })}>
        <Button variant="simple-action" onClick={clearCartProcessing}>
          <FormattedMessage id="btn.retry" />
        </Button>
      </StyledModalMessage>

      <ErrorHandler
        error={sessionData.finishSession.error}
        buttonMessageId={
          sessionData.finishSession.error?.errorCode == ErrorCode.CART_IS_PROCESSING
            ? "btn.retry"
            : "btn.ok"
        }
        closeHandler={clearErrorOnFinishSale}
        clickHandler={clearErrorOnFinishSale}
      />
      <ErrorHandler
        error={cartPaymentState.error}
        closeHandler={clearCartPayment}
        clickHandler={clearCartPayment}
      />

      <ErrorHandler
        error={initiateHostedCcOperationState.error}
        closeHandler={clearErrorOnHostedCcOperation}
        clickHandler={clearErrorOnHostedCcOperation}
      />

      <Spinner
        isOpen={
          sessionData.finishSession.loading ||
          cartPaymentState.loading ||
          sessionData.getSession.loading
        }
      />

      <OSContainer variant="page-content" sx={{ flexDirection: "column" }}>
        <SingleTabHeader icon="payment-information" messageLbl="header.review-payment-details" />
        <Box
          ref={paymentContainer}
          mt={7.5}
          px={[8, 8, 8, 27.5]}
          mb={isExistingCustomerCardPayment && hasExistingCard && 10}>
          <Flex
            sx={{
              alignItems: "center",
              flexDirection: ["column", "column", "column", "row"],
              mb: [10, 10, 10],
              gap: [0, 10, 0, 10],
            }}>
            <Flex
              sx={{
                alignItems: "center",
                width: ["100%", "100%", "100%", "40%"],
                mb: [10, 10, 10, 0],
              }}>
              <Text variant="phoneNumberType" color="primary" sx={{ cursor: "default" }}>
                {intl.formatMessage({ id: "lbl.payment-option" })}
              </Text>
              <Amount
                type="bold_superlarge"
                compact
                amount={amount}
                color={"ternary"}
                mx={[2, 2, 3, 5]}
              />
            </Flex>

            <Flex
              sx={{
                alignItems: "center",
                flexDirection: "row",
                justifyContent: ["space-evenly", "center", "center", "flex-start"],
                gap: [0, 10, 0, 0],
                width: ["100%", "100%", "100%", "60%"],
              }}>
              <Option
                data_testid="card"
                ref={paymentMethodOption}
                isSelectionAllowed={isPayAllowed && !iPPayForm}
                onSelection={() => selectPaymentMethodHandler(PAYMENT_METHOD.CARD_PAYMENT)}
                selected={selectedPaymentMethod === PAYMENT_METHOD.CARD_PAYMENT}>
                <Flex
                  sx={{
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    justifyContent: "space-between",
                    py: 5,
                  }}>
                  <Flex
                    id="Icon-container"
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}>
                    <OSIcon name={isPayAllowed ? "visa-enabled" : "visa-disabled"} />
                    <OSIcon name={isPayAllowed ? "mastercard-enabled" : "mastercard-disabled"} />
                  </Flex>
                  <Text
                    variant="heading5"
                    sx={{ textAlign: "center" }}
                    my={0}
                    color={isPayAllowed ? "ternary" : "disabledCardTextColor"}>
                    <FormattedMessage id="lbl.credit-debit-card" />
                  </Text>
                </Flex>
              </Option>
              {isPayInStoreVisible && (
                <Text
                  ml={[0, 4, 10]}
                  mt={[0, 0, 15]}
                  mb={0}
                  variant="heading3normal"
                  sx={{
                    color: "button.disabledColor",
                    textAlign: "center",
                    textTransform: "lowercase",
                  }}>
                  <FormattedMessage id="lbl.or-caps" />
                </Text>
              )}
              {isPayInStoreVisible && (
                <Option
                  isSelectionAllowed={isPayInStoreAllowed}
                  onSelection={() => selectPaymentMethodHandler(PAYMENT_METHOD.PAY_IN_STORE)}
                  selected={selectedPaymentMethod === PAYMENT_METHOD.PAY_IN_STORE}
                  ml={[0, 4, 10]}>
                  <Flex
                    sx={{
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "100%",
                      width: "100%",
                      py: 5,
                    }}>
                    <Flex
                      id="Icon-Container"
                      sx={{
                        justifyContent: "center",
                      }}>
                      <OSIcon
                        name={isPayInStoreAllowed ? "pick-in-store" : "pick-in-store-disabled"}
                      />
                    </Flex>

                    <Text
                      variant="heading5"
                      sx={{ textAlign: "center" }}
                      my={0}
                      color={isPayInStoreAllowed ? "ternary" : "disabledCardTextColor"}>
                      <FormattedMessage id="lbl.pay-in-store" />
                    </Text>
                  </Flex>
                </Option>
              )}
            </Flex>
          </Flex>
        </Box>
        {isExistingCustomerCardPayment && hasExistingCard && (
          <Box mb={10}>
            <HRTriangle trianglePosition={hrTrianglePosition} width={hrWidth} />
            <Flex
              mt={7.5}
              px={[8, 8, 8, 27.5]}
              sx={{
                alignItems: "center",
                flexDirection: "row",
                justifyContent: ["space-evenly", "space-evenly", "space-evenly", "flex-start"],
                gap: [0, 0, 0, 20],
                width: ["100%", "100%", "100%", "60%"],
              }}>
              <Option
                data_testid="card_existing"
                ref={existingCardOption}
                isSelectionAllowed={!iPPayForm}
                onSelection={() => setPaymentMethodCard(PAYMENT_METHOD_CARD.EXISTING)}
                selected={paymentMethodCard === PAYMENT_METHOD_CARD.EXISTING}
                isCCOption={true}
                sx={{ width: ["12rem", "13.25rem", "13.25rem"] }}>
                <Box sx={{ width: "100%", pt: 8 }}>
                  <Flex sx={{ px: 4 }}>
                    <Flex sx={{ flexDirection: "column", width: "100%" }}>
                      <Text variant="headerStepLight" color="ternary">
                        <FormattedMessage id="lbl.pay-with" />
                      </Text>
                      <Text
                        variant="ccTitle"
                        sx={{
                          width: ["8rem", "8rem", "7rem"],
                          whiteSpace: "normal",
                        }}>
                        <FormattedMessage id="lbl.my-existing-card" />
                      </Text>
                    </Flex>
                    <OSIcon mt={2} name="round-checkbox" />
                  </Flex>
                  <Flex sx={{ px: 4 }}>
                    <Text
                      variant="subtitle1"
                      mb={card.code === "VISA" ? [6.5, 6.5, 6.5] : [4, 1, 1]}
                      color="primary">
                      {card.code} {card.number}
                    </Text>
                  </Flex>
                </Box>
              </Option>
              <Option
                ref={newCardOption}
                isSelectionAllowed={true}
                onSelection={() => setPaymentMethodCard(PAYMENT_METHOD_CARD.NEW)}
                selected={paymentMethodCard === PAYMENT_METHOD_CARD.NEW}
                isCCOption={true}
                sx={{ width: ["12rem", "13.25rem", "13.25rem"] }}>
                <Box sx={{ width: "100%", pt: 8 }}>
                  <Flex sx={{ px: 4 }}>
                    <Flex sx={{ flexDirection: "column", width: "100%" }}>
                      <Text variant="headerStepLight" color="ternary">
                        <FormattedMessage id="lbl.pay-with" />
                      </Text>
                      <Text
                        variant="ccTitle"
                        sx={{
                          width: ["8rem", "8rem", "8rem"],
                          whiteSpace: "normal",
                        }}>
                        <FormattedMessage id="lbl.diferent-card" />
                      </Text>
                    </Flex>
                    <OSIcon mt={2} name="insurance-card" />
                  </Flex>
                  <Flex sx={{ px: 4, pb: 6 }}>
                    <OSIcon name={isPayAllowed ? "visa-enabled" : "visa-disabled"} />
                    <OSIcon name={isPayAllowed ? "mastercard-enabled" : "mastercard-disabled"} />
                  </Flex>
                </Box>
              </Option>
            </Flex>
          </Box>
        )}
        {isPaymentWithNewCard && iPPayForm && (
          <>
            <HRTriangle trianglePosition={hrTrianglePositionIframe} width={hrWidth} />
            <IFrame srcdoc={iPPayForm} />
            <Box id="paymentSuccess" className="track" ref={paymentSuccessRef} />
          </>
        )}
      </OSContainer>
    </Layout>
  );
};

export default PaymentInformation;
