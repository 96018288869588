import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Button } from "@theme-ui/components";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import qs from "qs";

import PlanPriceOffer from "../../../components/plan-price-offer";
import { OSContainer } from "../../../components/base";
import { OSIcon } from "../../../components/icons";
import { Heading3, Heading4 } from "../../../components/heading";

import HTMLRenderer from "../../../components/html-renderer";
import { StyledModalMessage } from "../../../components/modals";
import {
  clearGetBundlePriceApiResponse,
  clearGetItemsApiResponse,
  deleteItem,
  deleteSession,
  saveGroupOperations,
} from "../../../config/redux/slices";
import { storefrontURLs } from "../../../config/security/Deployment";
import { PERSONAL_INFORMATION_FLOW, PRICE_TYPE } from "../../../common/Constants";
import DeferredDiscounts from "./DeferredDiscounts";
import {
  isServiceDiscountEasyTabAssociated,
  isServiceDiscountFromRule,
} from "../../../components/plan-price-offer/PlanPriceOffer";
const Plan = ({
  plan,
  priceOffers,
  setChangeItem,
  numberOfBundles,
  hasGroupManagement,
  isEasyTabOfferSelected,
  changeItem,

  ...props
}) => {
  const dispatch = useDispatch();
  const { externalCartId } = useSelector((state) => state.session);
  const intl = useIntl();
  const [modal, setModal] = useState({
    isChange: false,
    isRemove: false,
    message: "",
    onClick: null,
  });
  const [showIFrame, setshowIFrame] = useState(false);
  const { flow } = useSelector((state) => state.personalInformation);
  const serviceAgreementState = useSelector((state) => state.serviceAgreement);

  const onRemove = async () => {
    dispatch(clearGetBundlePriceApiResponse());
    dispatch(clearGetItemsApiResponse());
    dispatch(deleteItem({ itemId: plan.id }));
  };

  const onDelete = async () => {
    await dispatch(deleteSession());
  };

  const onChooseDifferentPlan = async () => {
    setChangeItem("PLAN");
    setshowIFrame(true);
    dispatch(saveGroupOperations());
  };

  useEffect(() => {
    const handler = (event) => {
      if (storefrontURLs.base.includes(event.origin)) {
        location.reload();
      }
    };
    window.addEventListener("message", handler, false);
    return () => {
      window.removeEventListener("message", handler, false);
    };
  }, []);

  const clearModal = () =>
    setModal({ isChange: false, isRemove: false, message: "", onClick: null });

  const getPlanLabel = () => {
    if (flow !== PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE) {
      return "lbl.plan";
    }
    if (serviceAgreementState) {
      let product = Object.assign(
        { jsonType: "osProduct" },
        serviceAgreementState.loadExistingPlan.plan
      );
      if (changeItem == "PLAN" || product.code !== plan.packageCode) return "lbl.your-new-plan";
      else return "lbl.your-current-plan";
    }
    return "lbl.plan";
  };

  const RenderPlanDescription = ({ description }) => {
    if (flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE) {
      const descList = description?.split(";");
      if (descList?.length > 1) {
        return (
          <ul style={{ margin: 0 }}>
            {descList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        );
      }
    }
    return <HTMLRenderer htmlString={plan.planDescription} />;
  };

  const changePlanMsg =
    flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE
      ? hasGroupManagement
        ? "lbl.change-plan-hug-sharing-msg"
        : "lbl.change-plan-hug-msg"
      : hasGroupManagement
      ? "lbl.change-plan-sharing-msg"
      : "lbl.change-plan-msg";

  return (
    <Box sx={{ width: "100%" }} {...props}>
      <StyledModalMessage
        isOpen={modal.isChange || modal.isRemove}
        message={modal.message}
        onRequestClose={clearModal}
        type="error">
        {modal.isChange && (
          <>
            <Button
              id={"confirmChangePlan-" + plan.itemId}
              className="track"
              mr={20}
              variant="simple-action"
              onClick={() => {
                modal.onClick();
                clearModal();
              }}>
              <FormattedMessage id={modal.isChange ? "btn.change-plan" : "btn.ok"} />
            </Button>
            <Button
              id={"cancelChangePlan-" + plan.itemId}
              className="track"
              variant="simple-action"
              onClick={() => {
                clearModal();
              }}>
              <FormattedMessage id="btn.cancel" />
            </Button>
          </>
        )}
        {modal.isRemove && (
          <>
            <Button
              id={"confirmRemovePlan-" + plan.itemId}
              className="track"
              mr={20}
              variant="simple-action"
              onClick={() => {
                modal.onClick();
                clearModal();
              }}>
              <FormattedMessage id={modal.isChange ? "btn.change-plan" : "btn.ok"} />
            </Button>
            <Button
              id={"cancelRemovePlan-" + plan.itemId}
              className="track"
              variant="simple-action"
              onClick={() => {
                clearModal();
              }}>
              <FormattedMessage id="btn.cancel" />
            </Button>
          </>
        )}
      </StyledModalMessage>
      <Flex
        sx={{
          alignItems: ["flex-start", "flex-start", "center"],
          justifyContent: "space-between",
          flexDirection: ["column", "column", "row"],
        }}>
        <Heading3 color="primary">
          {intl.formatMessage({
            id: getPlanLabel(),
          })}
        </Heading3>
        {!showIFrame && (
          <Flex
            mr={["none", "none", 10]}
            mt={[2, 2, "none"]}
            sx={{
              alignItems: "center",
              justifyContent: ["space-between", "space-between", "flex-start"],
              width: ["100%", "100%", "auto"],
            }}>
            <Flex
              id={"changePlan-" + plan.planCode + "-" + plan.itemId}
              className="track"
              onClick={() =>
                setModal({
                  isChange: true,
                  message: intl.formatMessage({
                    id: changePlanMsg,
                  }),
                  onClick: onChooseDifferentPlan,
                })
              }
              sx={{ cursor: "pointer", alignItems: "center" }}>
              <Text
                variant="subtitle1"
                mt={0}
                color="ternary"
                sx={{ cursor: "pointer", textDecoration: "underline" }}>
                {intl.formatMessage({ id: "lbl.choose-different-plan" })}
              </Text>
              <OSIcon name="chevron-right" ml={2} />
            </Flex>
            {flow !== PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE && (
              <Flex
                id={"removePlan-" + plan.planCode + "-" + plan.itemId}
                className="track"
                onClick={() =>
                  setModal({
                    isRemove: true,
                    message: intl.formatMessage({
                      id: numberOfBundles > 1 ? "lbl.remove-plan-msg" : "lbl.remove-last-plan-msg",
                    }),
                    onClick: numberOfBundles > 1 ? onRemove : onDelete,
                  })
                }
                sx={{ cursor: "pointer", alignItems: "center" }}>
                <Text
                  variant="subtitle1"
                  mt={0}
                  ml={8}
                  color="ternary"
                  sx={{ cursor: "pointer", textDecoration: "underline" }}>
                  {intl.formatMessage({ id: "lbl.remove-items" })}
                </Text>
                <OSIcon name="trash" ml={2} />
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
      {showIFrame ? (
        <Flex mt={5} bg="whiteSmoke" sx={{ borderRadius: "2lg", width: "100%" }}>
          <iframe
            src={
              storefrontURLs.plans +
              `?` +
              qs.stringify({ cartId: externalCartId, itemId: plan.itemId })
            }
            width="100%"
            height={window.innerHeight}
            style={{ border: 0 }}
          />
        </Flex>
      ) : (
        <OSContainer variant="device-and-plan-section">
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "flex-start",
              minWidth: ["50%", "25%", "55%", "70%"],
            }}>
            <Heading4>{plan.planShortDescription}</Heading4>
            <Box
              sx={{
                alignItems: ["center", "center", "flex-start"],
                flexDirection: "column",
                width: ["100%", "100%", "90%"],
              }}
              mt={5}>
              <Text variant="lineDescription">
                <RenderPlanDescription description={plan.planDescription}></RenderPlanDescription>
              </Text>
            </Box>
          </Flex>

          <Flex sx={{ flexDirection: "column", minWidth: ["15rem", "70%", "40%", "30%"] }}>
            {priceOffers.flatMap((p) => p.price).length > 0 && (
              <Flex
                sx={{
                  flexDirection: "column",
                  minWidth: ["15rem", "70%", "30%"],
                }}
                mt={[5, 5, "none"]}>
                {priceOffers.map((priceOffer, index) => {
                  return (
                    <PlanPriceOffer
                      key={index}
                      prices={priceOffer.price}
                      isEasyTabOfferSelected={isEasyTabOfferSelected}
                      mt={index > 0 && 4}
                    />
                  );
                })}
              </Flex>
            )}

            {priceOffers
              .flatMap((p) => p.price)
              .filter(
                (price) =>
                  price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT ||
                  price.type === PRICE_TYPE.PLAN_SERVICE_IVD
              ).length > 0 && (
              <Flex
                sx={{
                  flexDirection: "column",
                  minWidth: ["15rem", "70%", "30%"],
                }}
                mt={[5, 5, 5]}>
                {priceOffers.map((priceOffer, index) => {
                  return (
                    <DeferredDiscounts
                      key={index}
                      prices={priceOffer.price
                        .filter(
                          (price) =>
                            price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT ||
                            price.type === PRICE_TYPE.PLAN_SERVICE_IVD
                        )
                        .filter((price) =>
                          isEasyTabOfferSelected
                            ? isServiceDiscountEasyTabAssociated(price) ||
                              !isServiceDiscountFromRule(price)
                            : !isServiceDiscountEasyTabAssociated(price) ||
                              !isServiceDiscountFromRule(price)
                        )}
                    />
                  );
                })}
              </Flex>
            )}
          </Flex>
        </OSContainer>
      )}
    </Box>
  );
};

export default Plan;
