import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";

const INITIAL_STATE = {
  getRateCentres: {
    loading: false,
    error: null,
    success: false,
    rateCentres: [],
  },
  getCarriers: {
    loading: false,
    error: null,
    carriers: [],
  },
  getProvincesFromRateCentres: {
    loading: false,
    error: null,
    success: false,
    provinces: [],
  },
  getPortabilityConfig: {
    loading: false,
    error: null,
    success: false,
    portabilityConfig: {},
  },
  getMinMaxPortDate: {
    loading: false,
    error: null,
    success: false,
    minMaxPortDate: {},
  },
  validatePortInCheck: {
    loading: false,
    success: false,
    error: null,
    portInDetails: null,
  },
};
export const getRateCentres = createAsyncThunk(
  "portability/rateCentres",
  async (province = null) => {
    if (!province) {
      return [];
    }
    return await FacadeAPI.GET(`/portability/rateCentres/${province}`);
  }
);
export const getCarriers = createAsyncThunk(
  "portability/carriers",
  async ({ province, country, carrierType }) => {
    return await FacadeAPI.GET("/portability/carriers", {
      province: province,
      country: country,
      carrierType: carrierType,
    });
  }
);

export const getProvincesFromRateCentres = createAsyncThunk(
  "portability/provincesFromRateCentres",
  async ({ spId, country }) => {
    return await FacadeAPI.GET(`/portability/provincesFromRateCentres/${spId}/${country}`);
  }
);

export const getPortabilityConfig = createAsyncThunk("portability/portabilityConfig", async () => {
  return await FacadeAPI.GET("/portability/portabilityConfig");
});

export const getMinMaxPortDate = createAsyncThunk("portability/minMaxPortDate", async () => {
  return await FacadeAPI.GET("/portability/minMaxPortDate");
});

export const validatePortInCheck = createAsyncThunk(
  "portability/validatePortInCheck",
  async ({ request }, { rejectWithValue }) => {
    try {
      return await FacadeAPI.PUT("/portability/validatePortInCheck", request);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const portabilitySlice = createSlice({
  name: "portability",
  initialState: INITIAL_STATE,
  reducers: {
    clearValidatePortInCheckApiResponse(state) {
      return {
        ...state,
        validatePortInCheck: INITIAL_STATE.validatePortInCheck,
      };
    },
  },
  extraReducers: {
    [getRateCentres.pending]: (state) => {
      state.getRateCentres.loading = true;
      state.getRateCentres.success = false;
    },
    [getRateCentres.fulfilled]: (state, action) => {
      state.getRateCentres.loading = false;
      state.getRateCentres.success = true;
      state.getRateCentres.rateCentres = action.payload;
    },
    [getRateCentres.rejected]: (state, action) => {
      state.getRateCentres.loading = false;
      state.getRateCentres.error = action.error;
    },
    [getCarriers.pending]: (state) => {
      state.getCarriers.loading = true;
    },
    [getCarriers.fulfilled]: (state, action) => {
      state.getCarriers.loading = false;
      state.getCarriers.carriers = action.payload;
    },
    [getCarriers.rejected]: (state, action) => {
      state.getCarriers.loading = false;
      state.getCarriers.error = action.error;
    },
    [getProvincesFromRateCentres.pending]: (state) => {
      state.getProvincesFromRateCentres.loading = true;
      state.getProvincesFromRateCentres.success = false;
    },
    [getProvincesFromRateCentres.fulfilled]: (state, action) => {
      state.getProvincesFromRateCentres.loading = false;
      state.getProvincesFromRateCentres.success = true;
      state.getProvincesFromRateCentres.provinces = action.payload;
    },
    [getProvincesFromRateCentres.rejected]: (state, action) => {
      state.getProvincesFromRateCentres.loading = false;
      state.getProvincesFromRateCentres.error = action.error;
    },
    [getPortabilityConfig.pending]: (state) => {
      state.getPortabilityConfig.loading = true;
      state.getPortabilityConfig.success = false;
    },
    [getPortabilityConfig.fulfilled]: (state, action) => {
      state.getPortabilityConfig.loading = false;
      state.getPortabilityConfig.success = true;
      state.getPortabilityConfig.portabilityConfig = action.payload;
    },
    [getPortabilityConfig.rejected]: (state, action) => {
      state.getPortabilityConfig.loading = false;
      state.getPortabilityConfig.error = action.error;
    },
    [getMinMaxPortDate.pending]: (state) => {
      state.getMinMaxPortDate.loading = true;
      state.getMinMaxPortDate.success = false;
    },
    [getMinMaxPortDate.fulfilled]: (state, action) => {
      state.getMinMaxPortDate.loading = false;
      state.getMinMaxPortDate.success = true;
      state.getMinMaxPortDate.minMaxPortDate = action.payload;
    },
    [getMinMaxPortDate.rejected]: (state, action) => {
      state.getMinMaxPortDate.loading = false;
      state.getMinMaxPortDate.error = action.error;
    },
    [validatePortInCheck.pending]: (state) => {
      state.validatePortInCheck.loading = true;
      state.validatePortInCheck.success = false;
      state.validatePortInCheck.error = null;
    },
    [validatePortInCheck.fulfilled]: (state, action) => {
      state.validatePortInCheck.loading = false;
      state.validatePortInCheck.success = true;
      state.validatePortInCheck.portInDetails = action.payload.portInDetails;
    },
    [validatePortInCheck.rejected]: (state, action) => {
      state.validatePortInCheck.loading = false;
      state.validatePortInCheck.error = action.payload;
    },
  },
});

export const { clearValidatePortInCheckApiResponse } = portabilitySlice.actions;
export default portabilitySlice.reducer;
