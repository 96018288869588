import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Flex, Text, Button, Label } from "@theme-ui/components";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { OSIcon } from "../../../components/icons";
import { formatPhoneNo } from "../../../common/StringUtilities";
import KeepLandlinePhoneNumberForm from "../forms/keep-landline-phone-number-form";
import KeepMobilePhoneNumberForm from "../forms/keep-mobile-phone-number-form";
import PortinType from "../../../components/portin-type";
import { OSCheckbox, OSContainer, OSSection } from "../../../components/base";
import {
  getProvincesFromRateCentres,
  savePhoneNumber,
  getPortabilityConfig,
  clearValidatePortInCheckApiResponse,
  validatePortInCheck,
  getRandomTemporaryNumber,
  getPosParameter,
  setPhoneNumberState,
  setPhoneNumberBundleInitialState,
  getMinMaxPortDate,
  clearGetRandomTemporaryNumbersApiResponse,
} from "../../../config/redux/slices";
import Tooltip from "../../../components/tooltip";
import { FLOW } from "../Constants";
import { PORTIN_TYPE } from "../../../components/portin-type/Constants";
import { POS_PARAMETER } from "../../../common/Constants";
import { Branding } from "../../../config/branding/Branding";
import { useForm, FormProvider } from "react-hook-form";
import AddressForRegistrationForm from "../forms/address-for-registration";
import Spinner from "../../../components/spinner/Spinner";
import ErrorHandler from "../../../components/error-handler";

const KeepPhoneNumber = forwardRef(({ activeTabIndex, itemId, setPhoneNumberBundle }, ref) => {
  const methods = useForm({ mode: "onTouched" });
  const dispatch = useDispatch();
  const intl = useIntl();
  const phoneNumberData = useSelector((state) => state.phoneNumber);
  const phoneNumberState = useSelector((state) => state.phoneNumber[activeTabIndex]);
  const personalInformationData = useSelector((state) => state.personalInformation);
  const portabilityState = useSelector((state) => state.portability);
  const portabilityConfig = useSelector(
    (state) => state.portability.getPortabilityConfig.portabilityConfig
  );
  const minMaxPortDateData = useSelector((state) => state.portability.getMinMaxPortDate);
  const [province, setProvince] = useState(
    phoneNumberState.portinMobileDetails.province ??
      phoneNumberState.portinLandlineDetails.province ??
      personalInformationData.province
  );
  const [npanxx, setNpaNxx] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState();
  const [tempPhoneNumber, setTempPhoneNumber] = useState(phoneNumberState.temporaryNumber);
  const [mask, setMask] = useState("");
  const [pattern, setPattern] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [agreeCheck, setAgreeCheck] = useState(phoneNumberState.stepCompleted);
  const [phoneNumberLocalState, setPhoneNumberLocalState] = useState(phoneNumberState);

  const [portinType, setPortinType] = useState(
    phoneNumberState.portinType === PORTIN_TYPE.NONE
      ? PORTIN_TYPE.MOBILE
      : phoneNumberState.portinType
  );
  const getNumberWithoutSeparator = (phoneNumber) => {
    return phoneNumber.split(portabilityConfig.separatorType).join("");
  };
  const onChangeValidationData = (isPhoneNumber) => {
    if (portabilityState.validatePortInCheck.success) {
      dispatch(clearValidatePortInCheckApiResponse());
      setPhoneNumber("");
      setTempPhoneNumber("");
      setPhoneNumberBundle(null);
      setAgreeCheck(false);
      if (isPhoneNumber) setNpaNxx(null);
    }
  };

  const resetBundle = () => {
    setPhoneNumberBundle(null);
    setAgreeCheck(false);
    setNpaNxx(null);
  };

  const onChangePortInNumber = (portInNumber) => {
    if (portInNumber) {
      let number = portInNumber.replaceAll(/[_-]/g, "");
      if (number.length > 7) {
        let newNpaNxx = number.substring(1, 7);
        if (npanxx !== newNpaNxx) {
          setNpaNxx(newNpaNxx);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getPortabilityConfig());
    dispatch(getMinMaxPortDate());
    dispatch(getPosParameter(POS_PARAMETER.ONLINE_STORE_CHAT_URL));
  }, [dispatch]);

  useEffect(() => {
    if (portabilityState.getPortabilityConfig.success) {
      dispatch(
        getProvincesFromRateCentres({
          spId: portabilityState.getPortabilityConfig.portabilityConfig.spID,
          country: Branding.DEFAULT_ISSUING_COUNTRY,
        })
      );
      let tmpMask =
        "1" +
        portabilityConfig.separatorType +
        "999" +
        portabilityConfig.separatorType +
        "999" +
        portabilityConfig.separatorType +
        "9999";
      let tmpPlaceholder =
        "1" +
        portabilityConfig.separatorType +
        "___" +
        portabilityConfig.separatorType +
        "___" +
        portabilityConfig.separatorType +
        "____";
      let tmpPattern =
        "^([1]{1}" +
        portabilityConfig.separatorType +
        "[0-9]{3}" +
        portabilityConfig.separatorType +
        "[0-9]{3}" +
        portabilityConfig.separatorType +
        "[0-9]{4})$";
      setMask(tmpMask);
      setPlaceholder(tmpPlaceholder);
      setPattern(tmpPattern);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portabilityState.getPortabilityConfig.success]);

  // Validate again state from db
  useEffect(() => {
    if (
      phoneNumberData.getPhoneNumberObj.success &&
      portabilityState.getPortabilityConfig.success &&
      !portabilityState.validatePortInCheck.success
    ) {
      const portInDetails =
        phoneNumberState.portinType === PORTIN_TYPE.MOBILE
          ? phoneNumberState.portinMobileDetails
          : phoneNumberState.portinLandlineDetails;
      if (portInDetails.phoneNumber && phoneNumberState.stepCompleted) {
        let request = {
          portedNumber: formatPhoneNo(portInDetails.phoneNumber, portabilityConfig.separatorType),
          portedNumberWithoutSeparator: portInDetails.phoneNumber,
          province: portInDetails.province,
          regProvince:
            phoneNumberState.addressForRegistrationDetails.regProvince ?? portInDetails.province,
          carrier: {
            serviceProviderIdentification: portInDetails.carrierName,
          },
          rateCentre: phoneNumberState.addressForRegistrationDetails.location,
          portType: phoneNumberState.portinType,
          accountNumber: portInDetails.oldAccountNumber,
          pinPassword: portInDetails.pinPassword,
          meidSIM: portInDetails.imei,
        };

        dispatch(validatePortInCheck({ request }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    phoneNumberData.getPhoneNumberObj.success,
    portabilityState.getPortabilityConfig.success,
    activeTabIndex,
  ]);

  useEffect(() => {
    if (!minMaxPortDateData.success) {
      return;
    }
    const stateCopy = { ...phoneNumberLocalState };
    let addressForRegistrationDetails = {
      address:
        phoneNumberState.addressForRegistrationDetails.address ?? personalInformationData.regStreet,
      city: phoneNumberState.addressForRegistrationDetails.city ?? personalInformationData.regCity,
      province:
        phoneNumberState.addressForRegistrationDetails.province ??
        personalInformationData.regProvince,
      postalCode:
        phoneNumberState.addressForRegistrationDetails.postalCode ??
        personalInformationData.regPostalCode,
      location: phoneNumberState.addressForRegistrationDetails.location ?? "",
    };
    if (portinType === PORTIN_TYPE.MOBILE) {
      setPhoneNumberLocalState({
        ...stateCopy,
        flow: FLOW.KEEP,
        portinType: portinType,
        temporaryNumber: phoneNumberState.temporaryNumber ?? "",
        portinMobileDetails: {
          ...stateCopy.portinMobileDetails,
          address: phoneNumberState.portinMobileDetails.address ?? personalInformationData.address,
          city: phoneNumberState.portinMobileDetails.city ?? personalInformationData.city,
          firstName:
            phoneNumberState.portinMobileDetails.firstName ?? personalInformationData.firstName,
          lastName:
            phoneNumberState.portinMobileDetails.lastName ?? personalInformationData.lastName,
          postalCode:
            phoneNumberState.portinMobileDetails.postalCode ?? personalInformationData.postalCode,
          province:
            phoneNumberState.portinMobileDetails.province ?? personalInformationData.province,
          phoneNumber: phoneNumberState.portinMobileDetails.phoneNumber
            ? formatPhoneNo(
                phoneNumberState.portinMobileDetails.phoneNumber,
                portabilityConfig.separatorType
              )
            : "",
        },
        addressForRegistrationDetails: addressForRegistrationDetails,
      });
      setPhoneNumber(phoneNumberState.portinMobileDetails.phoneNumber);
      setNpaNxx(
        phoneNumberState.portinMobileDetails.phoneNumber
          ? phoneNumberState.portinMobileDetails.phoneNumber.substring(1, 7)
          : null
      );
      setProvince(
        phoneNumberState.portinMobileDetails.province ?? personalInformationData.province
      );
    } else if (portinType === PORTIN_TYPE.LANDLINE) {
      setPhoneNumberLocalState({
        ...stateCopy,
        flow: FLOW.KEEP,
        portinType: portinType,
        temporaryNumber: phoneNumberState.temporaryNumber ?? "",
        portinLandlineDetails: {
          ...stateCopy.portinLandlineDetails,
          address:
            phoneNumberState.portinLandlineDetails.address ?? personalInformationData.address,
          city: phoneNumberState.portinLandlineDetails.city ?? personalInformationData.city,
          firstName:
            phoneNumberState.portinLandlineDetails.firstName ?? personalInformationData.firstName,
          lastName:
            phoneNumberState.portinLandlineDetails.lastName ?? personalInformationData.lastName,
          postalCode:
            phoneNumberState.portinLandlineDetails.postalCode ?? personalInformationData.postalCode,
          province:
            phoneNumberState.portinLandlineDetails.province ?? personalInformationData.province,
          portInDate: phoneNumberState.portinLandlineDetails.portInDate
            ? phoneNumberState.portinLandlineDetails.portInDate
            : minMaxPortDateData.minMaxPortDate.from,
          phoneNumber: phoneNumberState.portinLandlineDetails.phoneNumber
            ? formatPhoneNo(
                phoneNumberState.portinLandlineDetails.phoneNumber,
                portabilityConfig.separatorType
              )
            : "",
        },
        addressForRegistrationDetails: addressForRegistrationDetails,
      });
      setPhoneNumber(phoneNumberState.portinLandlineDetails.phoneNumber);
      setNpaNxx(
        phoneNumberState.portinLandlineDetails.phoneNumber
          ? phoneNumberState.portinLandlineDetails.phoneNumber.substring(1, 7)
          : null
      );
      setProvince(
        phoneNumberState.portinLandlineDetails.province ?? personalInformationData.province
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portinType, minMaxPortDateData.success]);

  const getFormData = (values) => {
    let portInDetails;
    if (portinType === PORTIN_TYPE.MOBILE) {
      portInDetails = {
        flow: FLOW.KEEP,
        portinType: portinType,
        temporaryNumber: tempPhoneNumber,
        portinMobileDetails: {
          address: values.address,
          carrierName: values.carrierName,
          city: values.city,
          firstName: values.firstName,
          lastName: values.lastName,
          oldAccountNumber: values.oldAccountNumber,
          phoneNumber: getNumberWithoutSeparator(values.phoneNumber),
          postalCode: values.postalCode,
          province: values.province,
          imei: values.imei,
          pinPassword: values.pinPassword,
          portInDate: new Date(minMaxPortDateData.minMaxPortDate.from).toISOString().slice(0, 10),
        },
        addressForRegistrationDetails: {
          address: values.regAddress,
          city: values.regCity,
          province: values.regProvince,
          postalCode: values.regPostalCode,
          location: values.regLocation,
        },
      };
    } else if (portinType === PORTIN_TYPE.LANDLINE) {
      portInDetails = {
        flow: FLOW.KEEP,
        portinType: portinType,
        temporaryNumber: tempPhoneNumber,
        portinLandlineDetails: {
          address: values.address,
          carrierName: values.carrierName,
          city: values.city,
          firstName: values.firstName,
          lastName: values.lastName,
          oldAccountNumber: values.oldAccountNumber,
          phoneNumber: getNumberWithoutSeparator(values.phoneNumber),
          postalCode: values.postalCode,
          province: values.province,
          portInDate: values.portInDate,
        },
        addressForRegistrationDetails: {
          address: values.regAddress,
          city: values.regCity,
          province: values.regProvince,
          postalCode: values.regPostalCode,
          location: values.regLocation,
        },
      };
    }
    return portInDetails;
  };
  const onSubmit = (values) => {
    values.portinType = portinType;
    onValidatePortInCheck(values);
  };

  const loadTempNumber = async (phoneNumberWithoutseparator, province, rateCentre) => {
    const { payload } = await dispatch(
      getRandomTemporaryNumber({
        prefix: phoneNumberWithoutseparator.substring(0, 7),
        province: province,
        rateCentre: rateCentre,
      })
    );
    if (payload && !payload.errorCode) {
      setTempPhoneNumber(payload);
    }
  };

  const onValidatePortInCheck = async (values) => {
    let request = {
      portedNumber: values.phoneNumber,
      acctID: personalInformationData.accountId,
      portedNumberWithoutSeparator: getNumberWithoutSeparator(values.phoneNumber),
      province: values.province,
      regProvince: values.regProvince ?? values.province,
      carrier: {
        serviceProviderIdentification: values.carrierName,
      },
      rateCentre: values.regLocation,
      portType: portinType,
      accountNumber: values.oldAccountNumber,
      pinPassword: values.pinPassword,
      meidSIM: values.imei,
    };

    const { payload } = await dispatch(validatePortInCheck({ request }));
    if (payload && !payload.errorCode) {
      setPhoneNumber(getNumberWithoutSeparator(values.phoneNumber));
      loadTempNumber(
        getNumberWithoutSeparator(values.phoneNumber),
        request.regProvince,
        request.rateCentre
      );
    }
  };

  const onAgreeCheck = (values) => {
    const agree = !agreeCheck;
    if (agree && portabilityState.validatePortInCheck.success) {
      const portInData = getFormData(values);
      portInData.stepCompleted = true;
      setPhoneNumberBundle({ ...phoneNumberState, ...portInData });
    } else {
      setPhoneNumberBundle(null);
    }
    setAgreeCheck(agree);
  };

  const onChangePortInType = (type) => {
    dispatch(setPhoneNumberBundleInitialState(activeTabIndex));
    setPortinType(type);
    resetBundle();
    methods.reset();
  };

  useImperativeHandle(ref, () => ({
    onSavePhoneNumber: methods.handleSubmit(onSavePhoneNumber),
  }));

  const onSavePhoneNumber = (values) => {
    const portInData = getFormData(values);
    portInData.stepCompleted = true;
    let newState = portInData;
    dispatch(setPhoneNumberState({ activeTabIndex, newState }));
    const portInDetails =
      portinType === PORTIN_TYPE.MOBILE
        ? portInData.portinMobileDetails
        : portInData.portinLandlineDetails;

    // Save details to session.
    const request = {
      sessionItemId: itemId,
      flow: FLOW.KEEP,
      portInType: portinType,
      number: portInDetails.phoneNumber,
      temporaryNumber: tempPhoneNumber,
      portInAddress: portInDetails.address,
      portInCarrier: portInDetails.carrierName,
      portInCity: portInDetails.city,
      portInFirstName: portInDetails.firstName,
      portInLastName: portInDetails.lastName,
      portInOldAccountNumber: portInDetails.oldAccountNumber,
      portInPostalCode: portInDetails.postalCode,
      portInProvince: portInDetails.province,
      portInMeidSim: portInDetails.imei,
      portInPassword: portInDetails.pinPassword,
      portInRegAddress: portInData.addressForRegistrationDetails.address,
      portInRegCity: portInData.addressForRegistrationDetails.city,
      portInRegProvince: portInData.addressForRegistrationDetails.province,
      portInRegPostalCode: portInData.addressForRegistrationDetails.postalCode,
      portInRegLocation: portInData.addressForRegistrationDetails.location,
      portInDate: portInDetails.portInDate,
    };
    dispatch(savePhoneNumber(request));
  };

  const isPortedNumberAlreadyUsed = (portedNumber) => {
    if (portedNumber) {
      let number = portedNumber.replaceAll(/[_-]/g, "");
      let tabIndex = 0;
      while (tabIndex !== activeTabIndex && phoneNumberData[tabIndex]) {
        let phoneNumberTabData = phoneNumberData[tabIndex];
        let portInDetails =
          phoneNumberTabData.portinType === PORTIN_TYPE.MOBILE
            ? phoneNumberTabData.portinMobileDetails
            : phoneNumberTabData.portinLandlineDetails;
        if (portInDetails.phoneNumber === number) {
          return true;
        }

        tabIndex++;
      }
      return false;
    }

    return false;
  };

  return (
    <Flex mt={7.5} px={[8, 8, 9]} sx={{ flexDirection: "column", width: "100%" }}>
      {false && (
        <Flex sx={{ alignItems: ["flex-start", "center"], flexDirection: ["column", "row"] }}>
          <Text
            variant="description"
            sx={{
              color: "primary",
              cursor: "default",
              fontSize: "2xl",
              fontStyle: "normal",
              fontWeight: "bold",
              fontStretch: "normal",
            }}>
            {intl.formatMessage({ id: "lbl.what-is-your-number-type" })}
          </Text>
          <PortinType
            ml={["none", 3]}
            mt={[3, "none"]}
            type={portinType}
            onChange={onChangePortInType}
          />
        </Flex>
      )}

      <FormProvider {...methods}>
        <form id="keepPhoneNumberForm" onSubmit={methods.handleSubmit(onSubmit)}>
          {portinType === PORTIN_TYPE.MOBILE && phoneNumberLocalState.portinMobileDetails && (
            <KeepMobilePhoneNumberForm
              activeTabIndex={activeTabIndex}
              defaults={phoneNumberLocalState.portinMobileDetails}
              province={province}
              setProvince={setProvince}
              mask={mask}
              placeholder={placeholder}
              pattern={pattern}
              onChangePortInNumber={onChangePortInNumber}
              onChangeValidationData={onChangeValidationData}
              isPortedNumberAlreadyUsed={isPortedNumberAlreadyUsed}
            />
          )}
          {portinType === PORTIN_TYPE.LANDLINE && phoneNumberLocalState.portinLandlineDetails && (
            <KeepLandlinePhoneNumberForm
              defaults={phoneNumberLocalState.portinLandlineDetails}
              province={province}
              setProvince={setProvince}
              mask={mask}
              placeholder={placeholder}
              pattern={pattern}
              onChangePortInNumber={onChangePortInNumber}
              onChangeValidationData={onChangeValidationData}
              isPortedNumberAlreadyUsed={isPortedNumberAlreadyUsed}
            />
          )}
          <AddressForRegistrationForm
            defaults={phoneNumberLocalState.addressForRegistrationDetails}
            oldProvince={province}
            npanxx={npanxx}
            onChangeValidationData={onChangeValidationData}
          />
          <Flex sx={{ justifyContent: "flex-end" }} mt={7.5}>
            <Button form="keepPhoneNumberForm" type="submit" variant="simple-action-port-in">
              {intl.formatMessage({ id: "btn.submit" })}
            </Button>
          </Flex>
          <ErrorHandler
            error={portabilityState.validatePortInCheck.error}
            closeHandler={() => dispatch(clearValidatePortInCheckApiResponse())}
            clickHandler={() => dispatch(clearValidatePortInCheckApiResponse())}
          />
          <ErrorHandler
            error={phoneNumberData.getRandomTemporaryNumber?.error}
            closeHandler={() => dispatch(clearGetRandomTemporaryNumbersApiResponse())}
            clickHandler={() => dispatch(clearGetRandomTemporaryNumbersApiResponse())}
          />

          <Spinner
            isOpen={
              portabilityState.validatePortInCheck.loading ||
              phoneNumberData.getRandomTemporaryNumber.loading
            }
          />
          {phoneNumber && tempPhoneNumber && (
            <OSContainer variant="page-content" mt={10} px={6}>
              <OSSection flexDirection={["column", "row"]} name="keepPhoneNumber" mt={2}>
                <Flex
                  sx={{
                    gridColumnStart: 1,
                    gridColumnEnd: [1, 2, 3],
                    alignItems: "center",
                  }}>
                  <Text
                    variant={"subtitle1"}
                    mt={0}
                    sx={{ lineHeight: ["snug", "normal", "relaxed"] }}>
                    {intl.formatMessage({ id: "lbl.port-in-message" })}
                  </Text>
                </Flex>
                <Flex
                  sx={{
                    my: [5, 2],
                    width: "100%",
                    flexDirection: ["column", "column", "column", "row"],
                  }}>
                  <Flex
                    py={1}
                    sx={{
                      alignItems: "center",
                      flexDirection: ["column", "row"],
                      width: "100%",
                    }}>
                    <Text
                      variant="attribute"
                      color="primary"
                      mr={[0, 9.5]}
                      sx={{ width: ["100%", "40%", "30%"], textAlign: "left" }}>
                      {intl.formatMessage({ id: "lbl.use-temporary-number" })}
                    </Text>
                    <Flex mt={[2, 0]} sx={{ alignItems: "center", width: ["100%", "70%"] }}>
                      <Tooltip
                        position="bottom"
                        value={intl.formatMessage({ id: "lbl.use-temporary-number-tooltip" })}>
                        <OSIcon name="tooltip-info" />
                      </Tooltip>
                      <Text variant="heading5" ml={5.5}>
                        {formatPhoneNo(tempPhoneNumber, portabilityConfig.separatorType)}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex
                    py={1}
                    sx={{
                      alignItems: "center",
                      flexDirection: ["column", "row"],
                      width: "100%",
                      justifyContent: "flex-start",
                    }}>
                    <Text
                      variant="attribute"
                      color="primary"
                      mr={[0, 9.5]}
                      sx={{ width: ["100%", "40%", "30%"], textAlign: "left" }}>
                      {intl.formatMessage({ id: "lbl.your-ported-number" })}
                    </Text>
                    <Flex mt={[2, 0]} sx={{ alignItems: "center", width: ["100%", "70%"] }}>
                      <Tooltip
                        position="bottom"
                        value={intl.formatMessage({ id: "lbl.your-ported-number-tooltip" })}>
                        <OSIcon name="tooltip-info" />
                      </Tooltip>
                      <Text variant="heading5" ml={5.5}>
                        {formatPhoneNo(phoneNumber, portabilityConfig.separatorType)}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  sx={{
                    gridColumnStart: 1,
                    gridColumnEnd: [1, 2, 3],
                    alignItems: "center",
                  }}>
                  <Text
                    variant={"subtitle1"}
                    mt={0}
                    sx={{ lineHeight: ["snug", "normal", "relaxed"] }}>
                    {intl.formatMessage(
                      {
                        id: `${
                          portinType === PORTIN_TYPE.MOBILE
                            ? "lbl.port-in-message-complete-mobile"
                            : "lbl.port-in-message-complete-landline"
                        }`,
                      },
                      {
                        n: <br />,
                        bullet: "\u2022",
                      }
                    )}
                  </Text>
                </Flex>
              </OSSection>
            </OSContainer>
          )}
          <OSContainer variant="page-content" mt={7} px={6}>
            <OSSection name="agreeSection">
              <Flex sx={{ flexDirection: "column" }}>
                <Text variant="summaryLabel" mr={0} mb={9} sx={{ lineHeight: "relaxed" }}>
                  <FormattedMessage id="lbl.i-agree-to-port-in-message" values={{ n: <br /> }} />
                </Text>
                <Label sx={{ alignItems: "center" }}>
                  <OSCheckbox
                    mr={[7, 2]}
                    key={"agree" + agreeCheck}
                    disabled={
                      !portabilityState.validatePortInCheck.success ||
                      !phoneNumberData.getRandomTemporaryNumber?.success
                    }
                    checked={agreeCheck}
                    onChange={
                      portabilityState.validatePortInCheck.success &&
                      phoneNumberData.getRandomTemporaryNumber?.success
                        ? methods.handleSubmit(onAgreeCheck)
                        : undefined
                    }
                  />
                  <Text variant="summaryValue" py={0} sx={{ whiteSpace: "pre-line" }}>
                    <FormattedMessage id="lbl.i-agree-to-port-in" />
                  </Text>
                </Label>
              </Flex>
            </OSSection>
          </OSContainer>
        </form>
      </FormProvider>
    </Flex>
  );
});

export default KeepPhoneNumber;
