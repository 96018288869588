import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import LangCode from "../../config/intl/LangCode";
import _ from "lodash";
import { Text } from "@theme-ui/components";
/**
 * Plain Amount component.
 *
 * @param {Object} props Component props.
 * @param {Number} props.amount The amount.
 * @param {String} props.currencySymbol The currency symbol.
 * @param {Boolean} props.isMonthly Flag indicating if the payment is monthly based.
 * @param {String} props.color Text color.
 * @param {String} props.type Amount type.
 * @param {*} props.props Rest of the props.
 */
const AmountPlain = ({
  amount,
  currencySymbol = "$",
  isMonthly = false,
  color = "primary",
  ...props
}) => {
  const intl = useIntl();
  const personalInfoState = useSelector((state) => state.personalInformation);

  const preciseAmount = _.round(amount, 2).toFixed(2);
  const parsedAmount = preciseAmount.split(".");
  const isEnglishLang = LangCode[personalInfoState.language] === LangCode.English;

  return (
    <Text {...props}>
      {/*  Currency(English case) and integer part */}
      {isEnglishLang && (
        <Text
          as="sup"
          sx={{
            fontSize: "50%",
            color: color,
          }}>
          {currencySymbol}
        </Text>
      )}
      <Text
        sx={{
          color: color,
        }}>
        {parsedAmount[0]}
      </Text>
      {/*  Decimal and monthly part + currency(French case) */}
      <Text
        as="sup"
        sx={{
          fontSize: "50%",
          color: color,
        }}>
        {parsedAmount[1]}
      </Text>
      {!isEnglishLang && (
        <Text
          as="sup"
          sx={{
            fontSize: "50%",
            color: color,
          }}>
          {currencySymbol}
        </Text>
      )}
      {isMonthly && (
        <Text
          sx={{
            fontSize: "45%",
            color: color,
          }}>
          {`/${intl.formatMessage({ id: "lbl.monthly" })}`}
        </Text>
      )}
    </Text>
  );
};

export default AmountPlain;
