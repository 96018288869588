import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text, Button } from "@theme-ui/components";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import qs from "qs";
import { find } from "lodash";
import Attribute from "../../../components/attribute";
import PriceOffer from "../../../components/price-offer";
import { OSContainer, OSSwitch } from "../../../components/base";
import { OSIcon } from "../../../components/icons";
import { Heading3, Heading4 } from "../../../components/heading";

import HTMLRenderer from "../../../components/html-renderer";
import {
  deleteItem,
  deleteSession,
  updateItem,
  clearGetItemsApiResponse,
  clearUpdateItemApiResponse,
  removeBundleDiscounts,
  clearGetBundlePriceApiResponse,
} from "../../../config/redux/slices";

import {
  PRICE_TYPE,
  PERSONAL_INFORMATION_FLOW,
  OLD_INSTALLMENT_OPTION,
  SIM_TYPE,
  EXTRA_INFO,
  PRICING_OPTION,
} from "../../../common/Constants";
import { StyledModalMessage } from "../../../components/modals";
import { storefrontURLs } from "../../../config/security/Deployment";
import Store from "store2";
import { getExtraInfoValues } from "../../../common/StringUtilities";

const Device = ({
  device,
  activeTabIndex,
  priceOffers,
  selectedPriceOffer,
  onSelectPriceOffer,
  onBundlePriceOffersChange,
  setChangeItem,
  numberOfBundles,
  updateCurrentItem,
  linesTitles,
  simType,
  onChangeSimType,
  ...props
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [modal, setModal] = useState({
    isChange: false,
    isRemove: false,
    message: "",
    onClick: null,
  });
  const [showIFrame, setshowIFrame] = useState(false);
  const { planFlags } = useSelector((state) => state.items);
  const { externalCartId } = useSelector((state) => state.session);
  const { flow } = useSelector((state) => state.personalInformation);
  const planExists = planFlags.planExists;

  const devicePrice = priceOffers.find((o) => o.code === device.sku)?.price[0]?.price;
  const deviceTax = priceOffers.find((o) => o.code === device.sku)?.price[0]?.tax;
  const deviceFullPrice =
    devicePrice !== undefined &&
    deviceTax !== undefined &&
    Math.round((devicePrice + deviceTax + Number.EPSILON) * 100) / 100;
  const simTypeToggleChecked = simType === SIM_TYPE.eSIM;
  const hideUpfrontPayment =
    device.pricingOption !== PRICING_OPTION.RETAIL &&
    find(
      priceOffers
        .flatMap((p) => p.price)
        .map((p) => p.extraInfo)
        .map((e) => getExtraInfoValues(e)),
      (extraInfo) => extraInfo[EXTRA_INFO.HIDE_UPFRONT_PAYMENT]
    )?.[EXTRA_INFO.HIDE_UPFRONT_PAYMENT] === "Y";
  const pricingOption =
    device?.extraInfo?.length > 0
      ? getExtraInfoValues(device.extraInfo)[EXTRA_INFO.SELECTED_PRICING_OPTION]
      : "";

  const simTypeVisible = () => {
    if (device.extraInfo && device.extraInfo.length > 0) {
      let deviceExtraInfo = getExtraInfoValues(device.extraInfo);
      let bothSimCapability = deviceExtraInfo["BOTH_SIM"];
      return bothSimCapability && bothSimCapability === "Y";
    }
    return false;
  };

  useEffect(() => {
    if (selectedPriceOffer) return;

    let defaultPriceOffer = priceOffers[0];
    if (device.pricingOption === PRICING_OPTION.RETAIL) {
      defaultPriceOffer = priceOffers.find((priceOffer) => priceOffer.code === device.sku);
    } else {
      let filteredPriceOffers = priceOffers.filter((priceOffer) => {
        const minDownPaymentPrice = priceOffer.price.find(
          (price) => price.type === PRICE_TYPE.NONE
        );
        const rcPrice = priceOffer.price.find((price) => price.type === PRICE_TYPE.RECURRING);
        return minDownPaymentPrice?.price !== rcPrice?.fullPrice;
      });
      if (filteredPriceOffers.length > 0) {
        defaultPriceOffer = filteredPriceOffers[0];
      }
    }

    const rcPrice = device.prices.find((price) => price.type === PRICE_TYPE.RECURRING);
    const otPrice = device.prices.find((price) => price.type === PRICE_TYPE.ONE_TIME);

    if (rcPrice?.description?.length > 0 || otPrice?.description?.length > 0) {
      for (var i = 0; i < priceOffers.length; i++) {
        const priceOffer = priceOffers[i];
        const rcPriceOffer = priceOffer.price.find((price) => price.type === PRICE_TYPE.RECURRING);
        const otPriceOffer = priceOffer.price.find((price) => price.type === PRICE_TYPE.ONE_TIME);
        if (rcPrice?.price === rcPriceOffer?.price && otPrice?.price === otPriceOffer?.price) {
          defaultPriceOffer = priceOffer;
        }
      }
    }

    onSelectPriceOffer(defaultPriceOffer);
  }, [
    device.prices,
    device.pricingOption,
    device.sku,
    deviceFullPrice,
    onSelectPriceOffer,
    priceOffers,
    selectedPriceOffer,
  ]);

  const onSelectPrice = (priceOffer) => {
    onSelectPriceOffer(priceOffer);
  };

  const clearSelectedPriceOffer = () => {
    onSelectPriceOffer({
      ...selectedPriceOffer,
      price: [
        {
          code: "BYOD",
          description: "",
          extraInfo: "",
          fullPrice: 0,
          price: 0,
          tax: 0,
          termsAmount: -1,
          termsUnit: "NONE",
          type: PRICE_TYPE.RECURRING,
        },
      ],
    });
  };

  const onRemove = async () => {
    try {
      onSelectPriceOffer(null);
      dispatch(clearGetBundlePriceApiResponse());
      dispatch(clearGetItemsApiResponse());
      dispatch(deleteItem({ itemId: device.id }));
      const index = activeTabIndex === 0 ? 0 : activeTabIndex - 1;
      Store("tabindex", index);
    } catch (err) {
      console.log(err);
    }
  };
  const onDelete = async () => {
    await dispatch(deleteSession());
  };

  const onChooseDifferentDevice = async () => {
    setChangeItem("DEVICE");
    setshowIFrame(true);
  };

  useEffect(() => {
    const handler = (event) => {
      if (storefrontURLs.base.includes(event.origin)) {
        location.reload();
      }
    };
    window.addEventListener("message", handler, false);
    return () => {
      window.removeEventListener("message", handler, false);
    };
  }, []);

  const clearModal = () =>
    setModal({ isChange: false, isRemove: false, message: "", onClick: null });

  const byod = async () => {
    clearSelectedPriceOffer();

    const contact = linesTitles[activeTabIndex];

    let itemUpdateRequest = {
      id: device.id,
      itemId: device.itemId,
      planCode: device.planCode,
      planShortDescription: device.planShortDescription,
      category: device.category,
      sku: "",
      skuDescription: "",
      contactFirstName: contact.firstName,
      contactLastName: contact.lastName,
      media: "",
      attributes: [],
      extraInfo: "",
      shortDescription: intl.formatMessage({ id: "lbl.byop-description" }),
      paymentOption: "RETAIL",
      prices: [
        {
          code: "BYOD",
          price: 0,
          type: PRICE_TYPE.RECURRING,
          termsAmount: -1,
          termsUnit: "NONE",
          fullPrice: 0,
          description: null,
        },
      ],
    };
    dispatch(clearGetItemsApiResponse());
    dispatch(removeBundleDiscounts(device.id));
    await dispatch(updateItem(itemUpdateRequest));
    await dispatch(clearUpdateItemApiResponse());
    updateCurrentItem(itemUpdateRequest);
  };

  const onSimTypeUpdate = (simTypeToggleChecked) => {
    onChangeSimType(simTypeToggleChecked ? SIM_TYPE.eSIM : SIM_TYPE.SIM);
  };

  const ModalButtons = () => {
    return modal.isChange ? (
      <>
        <Button
          mr={20}
          id={"confirmChangeDevice-" + device.itemId}
          className="track"
          variant="simple-action"
          onClick={() => {
            modal.onClick();
            clearModal();
          }}>
          <FormattedMessage id="btn.ok" />
        </Button>

        <Button
          id={"cancelChangeDevice-" + device.itemId}
          className="track"
          variant="simple-action"
          onClick={() => {
            clearModal();
          }}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </>
    ) : (
      <Flex
        sx={{
          width: "90%",
          justifyContent: "space-evenly",
        }}>
        <Button
          px={[0, 0, 0]} //overwrite px varinat:simple-action from base-theme.js
          id={"confirmRemoveDevice-" + device.itemId}
          className="track"
          variant="simple-action"
          onClick={() => {
            modal.onClick();
            clearModal();
          }}>
          <FormattedMessage id="btn.remove" />
        </Button>

        {flow !== PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE && (
          <Button
            px={[0, 0, 0]}
            id={"useBYOP-" + device.itemId}
            className="track"
            variant="simple-action"
            onClick={() => {
              byod();
              clearModal();
            }}>
            <FormattedMessage id="btn.byod" />
          </Button>
        )}

        <Button
          id={"cancelRemoveDevice-" + device.itemId}
          className="track"
          variant="simple-action"
          px={[0, 0, 0]}
          onClick={() => {
            clearModal();
          }}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </Flex>
    );
  };

  return (
    <Box sx={{ width: "100%" }} {...props}>
      <StyledModalMessage
        isOpen={modal.isChange || modal.isRemove}
        message={modal.message}
        onRequestClose={clearModal}
        type="error">
        <ModalButtons />
      </StyledModalMessage>
      <Flex
        sx={{
          alignItems: ["flex-start", "flex-start", "center"],
          justifyContent: "space-between",
          flexDirection: ["column", "column", "row"],
        }}>
        <Heading3 color="primary">
          {intl.formatMessage({
            id:
              flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE ? "lbl.new-device" : "lbl.device",
          })}
        </Heading3>
        {device.oldInstallmentOption == OLD_INSTALLMENT_OPTION.KEEP && (
          <Text variant="lineDescription" sx={{ fontWeight: "normal" }}>
            <FormattedMessage
              id="lbl.active-easyTab-info"
              values={{
                sup: (chunks) => (
                  <Text
                    as="sup"
                    mr={0}
                    sx={{ display: "inline", fontSize: "45%", color: "ternary" }}>
                    {chunks}
                  </Text>
                ),
              }}
            />
          </Text>
        )}
        {!showIFrame && (
          <Flex
            mt={[2, 2, "none"]}
            mx={["none", "none", 10]}
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: ["space-between", "space-between", "flex-start"],
              width: ["100%", "100%", "auto"],
            }}>
            <Flex
              id={"changeDevice-" + device.sku + "-" + device.itemId}
              className="track"
              onClick={() =>
                setModal({
                  isChange: true,
                  message: intl.formatMessage({ id: "lbl.change-device-msg" }),
                  onClick: onChooseDifferentDevice,
                })
              }
              sx={{ cursor: "pointer", alignItems: "center" }}>
              <Text
                variant="subtitle1"
                mt={0}
                color="ternary"
                sx={{ cursor: "pointer", textDecoration: "underline" }}>
                {intl.formatMessage({ id: "lbl.choose-different-device" })}
              </Text>
              <OSIcon name="chevron-right" ml={2} />
            </Flex>
            <Flex
              id={"removeDevice-" + device.sku + "-" + device.itemId}
              className="track"
              onClick={() =>
                setModal({
                  isRemove: true,
                  message: intl.formatMessage({
                    id:
                      flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE
                        ? "lbl.remove-plan-msg"
                        : numberOfBundles > 1
                        ? "lbl.remove-device-msg"
                        : "lbl.remove-last-device-msg",
                  }),
                  onClick: numberOfBundles > 1 ? onRemove : onDelete,
                })
              }
              sx={{ cursor: "pointer", alignItems: "center" }}>
              <Text
                variant="subtitle1"
                mt={0}
                ml={8}
                color="ternary"
                sx={{ cursor: "pointer", textDecoration: "underline" }}>
                {intl.formatMessage({ id: "lbl.remove-items" })}
              </Text>
              <OSIcon name="trash" ml={2} />
            </Flex>
          </Flex>
        )}
      </Flex>
      {showIFrame ? (
        <Flex mt={5} bg="whiteSmoke" sx={{ borderRadius: "2lg", width: "100%" }}>
          <iframe
            src={
              storefrontURLs.devices +
              `?` +
              qs.stringify({ cartId: externalCartId, itemId: device.itemId })
            }
            width="100%"
            height={window.innerHeight}
            style={{ border: 0 }}
          />
        </Flex>
      ) : (
        <OSContainer variant="device-and-plan-section">
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: ["center", "center", "flex-start"],
              minWidth: ["50%", "25%", "20%"],
              flexDirection: "column",
              flex: 1,
            }}>
            <Flex
              sx={{
                width: ["100%", "100%", "100%"],
              }}>
              <Flex
                ml={["2rem", "6rem", "2rem"]}
                sx={{
                  justifyContent: "flex-start",
                  minWidth: "7rem",
                  width: "7rem",
                }}>
                <Image src={device.thumbnailURL} alt="device" sx={{ objectFit: "contain" }} />
              </Flex>

              <Flex ml={[5, 5, 10]} sx={{ flexWrap: "wrap" }}>
                <Heading4 sx={{ minWidth: "5rem", textAlign: "center" }}>
                  {device.shortDescription}
                </Heading4>
                {device.attributes.length > 0 && (
                  <Flex
                    mt={[5, 5, 4]}
                    sx={{
                      gap: 1.5,
                      alignItems: "center",
                      flexWrap: "wrap",
                      width: "100%",
                      minWidth: ["15rem", "100%", "100%"],
                    }}>
                    {device.attributes.map((attribute, index) => (
                      <Attribute key={index} value={attribute.value} />
                    ))}
                  </Flex>
                )}
                <Flex
                  mt={[5, 5, 5]}
                  sx={{
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    minWidth: ["50%", "25%", "60%", "70%"],
                  }}>
                  <Box
                    sx={{
                      alignItems: ["center", "center", "flex-start"],
                      flexDirection: "column",
                      width: ["100%", "100%", "90%"],
                    }}
                    mt={5}>
                    <Text variant="lineDescription">
                      <HTMLRenderer htmlString={device.skuDescription} />
                    </Text>
                    {simTypeVisible() && (
                      <Flex
                        sx={{
                          flexDirection: ["column", "row", "row"],
                          mt: ["", "10", "10"],
                          width: "100%",
                          justifyContent: ["center", "space-between", "space-between"],
                        }}>
                        <Flex>
                          <Heading4 sx={{ fontWeight: "medium", color: "primary" }}>
                            {intl.formatMessage({ id: "lbl.select-sim-type" })}
                          </Heading4>
                        </Flex>
                        <Flex
                          sx={{
                            mt: ["3", "none", "none"],
                          }}>
                          <Heading4 sx={{ fontWeight: "normal", pr: "5" }}>SIM</Heading4>
                          <OSSwitch
                            id="simTypeToggle"
                            name="simTypeToggle"
                            variant="toggleSwitch"
                            ml="0.5rem"
                            checked={simTypeToggleChecked}
                            onChange={() => onSimTypeUpdate(!simTypeToggleChecked)}
                          />
                          <Heading4 sx={{ fontWeight: "normal", pl: "5" }}>eSIM</Heading4>
                        </Flex>
                      </Flex>
                    )}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            mt={[5, "4rem", "none"]}
            ml={[0, 0, "auto"]}
            mb={4}
            sx={{
              flexDirection: "column",
              minWidth: ["15rem", "70%", "45%", "30%"],
            }}>
            {priceOffers.map((priceOffer, index) => {
              const componentId = "priceOffer-" + priceOffer.code;
              const rcPrice = priceOffer.price.find((price) => price.type === PRICE_TYPE.RECURRING);
              const otPrice = priceOffer.price.find((price) => price.type === PRICE_TYPE.ONE_TIME);
              const flexDeferredPrice = priceOffer.price.find(
                (price) => price.type === PRICE_TYPE.FLEX_DEFERRED_AMOUNT
              );
              // Min down payment price will be present only for installment price offers
              const minDownPaymentPrice = priceOffer.price.find(
                (price) => price.type === PRICE_TYPE.NONE
              );
              const fullPrice = rcPrice ? rcPrice.fullPrice : deviceFullPrice;
              // For an installment price offer, if minimum down payment is equal to the device full price,
              // do not display this price offer
              if (minDownPaymentPrice && minDownPaymentPrice.price === fullPrice) {
                return null;
              }

              const isInstallmentPriceOffer =
                rcPrice && rcPrice.price > 0 && rcPrice.termsAmount > 0;

              const selectedRcPrice = selectedPriceOffer?.price.find(
                (price) => price.type === PRICE_TYPE.RECURRING
              );

              if (
                (isInstallmentPriceOffer && !planExists) ||
                (!isInstallmentPriceOffer && hideUpfrontPayment)
              ) {
                return null;
              }

              return (
                <PriceOffer
                  id={componentId}
                  key={index}
                  rcPrice={rcPrice}
                  otPrice={otPrice}
                  flexDeferredPrice={flexDeferredPrice}
                  minDownPaymentPrice={minDownPaymentPrice}
                  description={intl.formatMessage({
                    id: isInstallmentPriceOffer ? "lbl.easyTab" : "lbl.full-price",
                  })}
                  deviceFullPrice={fullPrice}
                  onChangeDownPayment={onBundlePriceOffersChange}
                  selected={
                    selectedPriceOffer?.code === priceOffer.code &&
                    selectedRcPrice?.termsAmount === rcPrice?.termsAmount
                  }
                  onSelect={() => onSelectPrice(priceOffer)}
                  showRadio={priceOffers.length > 0}
                  isDevicePriceOffer={true}
                  notLast={index !== priceOffers.length - 1}
                  showUpfrontPayment={!hideUpfrontPayment}
                  pricingOption={pricingOption}
                />
              );
            })}
          </Flex>
        </OSContainer>
      )}
    </Box>
  );
};

export default Device;
