import React from "react";
import { Flex } from "@theme-ui/components";
import BaseSkeleton from "./BaseSkeleton";

const HomeDeliverySkeleton = () => {
  return (
    <Flex
      sx={{
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
        minWidth: ["100%", "100%", "22rem"],
      }}>
      <BaseSkeleton width="100%" height="2.625rem" />
      <Flex
        sx={{
          gap: 1.5,
          justifyContent: "center",
          flexWrap: "wrap",
          mt: 1.5,
          width: "100%",
        }}>
        <BaseSkeleton width="100%" height="4.0625rem" />
      </Flex>
    </Flex>
  );
};

export default HomeDeliverySkeleton;
