import React from "react";
import { ITEM_CATEGORY } from "../../common/Constants";
import Bundle from "./item-categories/Bundle";
import Shipping from "./item-categories/Shipping";
import Accessory from "./item-categories/Accessory";

/**
 * @typedef {Object} SessionItemAttribute
 * @property {Number} id                          - Item attribute id.
 * @property {String} code                        - Item attribute code.
 * @property {String} value                       - Item attribute value.
 */
/**
 * @typedef {Object} SessionItem
 * @property {Number} id                          - Item's id.
 * @property {String} sku                         - Item's sku.
 * @property {String} category                    - Item's category.
 * @property {String} price                       - Item's price.
 * @property {String} thumbnailURL                - Item's thumbnail URL.
 * @property {String} shortDescription            - Item's short description.
 * @property {SessionItemAttribute[]}  attributes - List of item's attributes.
 */
/**
 * Renders a cart item based on item's category.
 *
 * @param {Object} props Component props.
 * @param {SessionItem} props.item Cart item.
 */
const CartItem = ({ item, plusTax, showPrices, lineDescription }) => {
  switch (item.category) {
    case ITEM_CATEGORY.BUNDLE:
      return (
        <Bundle
          item={item}
          plusTax={plusTax}
          showPrices={showPrices}
          lineDescription={lineDescription}
        />
      );
    case ITEM_CATEGORY.ACCESSORY:
      return <Accessory item={item} showPrices={showPrices} plusTax={plusTax} />;
    case ITEM_CATEGORY.SHIPPING:
      return <Shipping item={item} showPrices={showPrices} />;
    default:
      return null;
  }
};

export default CartItem;
