import React, { forwardRef, useLayoutEffect, useRef, useState } from "react";
import { Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";

import { useWindowSize } from "../../hooks";
import { FLOW } from "./Constants";
import HRTriangle from "../../components/HRTriangle";

const Option = forwardRef(({ title, selected, id, className, ...props }, ref) => {
  return (
    <Flex
      className={className}
      id={id}
      ref={ref}
      bg="cardBg"
      p={5}
      sx={{
        maxWidth: ["100%", "100%", "17.625rem"],
        width: "100%",
        minWidth: "16rem",
        border: 1,
        borderColor: selected ? "secondary" : "cardBorderColor",
        borderRadius: "2lg",
        boxShadow: "sm",
        cursor: "pointer",
        ":hover": !selected && {
          borderColor: "cardBorderHoverColor",
        },
      }}
      {...props}>
      <Flex
        className={className}
        id={id}
        py={3}
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}>
        <Text variant="heading2" sx={{ textAlign: "center" }}>
          {title}
        </Text>
      </Flex>
    </Flex>
  );
});

const PageHeader = ({ selectedFlow, onChangeFlow }) => {
  const intl = useIntl();
  const windowSize = useWindowSize();

  const headerContainer = useRef(null);
  const pickOption = useRef(null);
  const keepOption = useRef(null);

  const [hrTrianglePosition, setHrTrianglePosition] = useState(459);
  const [hrWidth, setHrWidth] = useState(0);

  useLayoutEffect(() => {
    if (selectedFlow === FLOW.PICK) {
      setHrTrianglePosition(
        pickOption.current.offsetLeft -
          headerContainer.current.offsetLeft +
          pickOption.current.offsetWidth / 2
      );
    }

    if (selectedFlow === FLOW.KEEP) {
      setHrTrianglePosition(
        keepOption.current.offsetLeft -
          headerContainer.current.offsetLeft +
          keepOption.current.offsetWidth / 2
      );
    }
    setHrWidth(
      headerContainer && headerContainer.current ? headerContainer.current.offsetWidth : 0
    );
  }, [selectedFlow, windowSize.width]);

  return (
    <>
      <Flex
        mb={selectedFlow && 9.5}
        mt={2}
        px={[8, 8, 8, 9]}
        ref={headerContainer}
        sx={{
          alignItems: ["flex-start", "flex-start", "flex-start", "center"],
          flexDirection: ["column", "column", "column", "row"],
        }}>
        <Flex
          sx={{
            alignItems: "center",
            height: "100%",
            justifyContent: "flex-start",
            width: ["auto", "auto", "auto", "14.375rem"],
          }}>
          <Text variant="heading3normal">
            {intl.formatMessage({ id: "lbl.its-time-to-pick-phone-no" })}
          </Text>
        </Flex>

        <Flex
          ml={["none", "none", "none", 16]}
          mt={[5, 5, 5, "none"]}
          sx={{ flexDirection: ["column", "row"] }}>
          <Option
            ref={pickOption}
            title={intl.formatMessage({ id: "lbl.pick-a-new-number" }, { n: <br /> })}
            onClick={() => selectedFlow !== FLOW.PICK && onChangeFlow(FLOW.PICK)}
            selected={selectedFlow === FLOW.PICK}
          />

          <Option
            id="keepCurrentPhoneNo"
            className="track"
            ref={keepOption}
            title={intl.formatMessage({ id: "lbl.keep-my-phone-number" })}
            onClick={() => selectedFlow !== FLOW.KEEP && onChangeFlow(FLOW.KEEP)}
            selected={selectedFlow === FLOW.KEEP}
            ml={[0, 4]}
            mt={[4, 0]}
          />
        </Flex>
      </Flex>

      {selectedFlow !== FLOW.NONE && (
        <HRTriangle trianglePosition={hrTrianglePosition} width={hrWidth} />
      )}
    </>
  );
};

export default PageHeader;
