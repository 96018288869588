import React, { useEffect, useState } from "react";
import { Flex, Box, Text, Spinner, Button } from "@theme-ui/components";
import { FacadeMockup } from "../RestMockup";
import { useDispatch, useSelector } from "react-redux";
import { getItems as getDevices } from "../../../config/redux/slices";
import { StyledModalMessage } from "../../../components/modals";
import GetErrorDescription from "../../../components/get-error-description";

var plans = [
  {
    planCode: "GFSHARETEST",
    planShortDescription: "GFSHARETEST",
    planDescription: "GFSHARETEST - grand father share",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 10,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "ECRODA60",
    planShortDescription: "ECRODA60",
    planDescription: "ECRODA60 - roll over",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 10,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "ECCBD60",
    planShortDescription: "ECCBD60",
    planDescription: "ECCBD60 - big data",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 10,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "ALEXVOS",
    planShortDescription: "ALEXVOS",
    planDescription: "CANUNL55A5 - this plan is for testing sharing groups.",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 10,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "ALEXVOSTEST",
    planShortDescription: "ALEXVOSTEST",
    planDescription: null,
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 20,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "ALEXVOS4",
    planShortDescription: "ALEXVOS4",
    planDescription: "30NWAA - this plan is for testing sharing groups.",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "ALEXVOS2",
    planShortDescription: "ALEXVOS2",
    planDescription: "ENLOY40A - this plan is for testing sharing groups.",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 40,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "OSTEST",
    planShortDescription: "This plan is for test ",
    planDescription: "Test res com",
    packageCode: "TEST3", //doesnt change
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 40,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "OSANDREINW",
    planShortDescription: "30 Talk & Text 200 - (C)",
    planDescription:
      "200 Nationwide Anytime Minutes; Unlimited 6pm Nights & Weekends; Unlimited Text, Picture and Video Messages; Call Display, Voicemail Lite; Call Waiting and Conference Calling; My Eastlink Family Member Calling",
    packageCode: "TESTRESCOM",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 50,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "OSANDREI",
    planShortDescription: "Worry Free 15GB",
    planDescription:
      "All Worry-Free Data Plans Include: Unlimited Text, Picture & Video Messaging; Unlimited Family Account Calling; Voicemail (25 messages), Call Details, Conference Calling and Call Waiting; Add up to 10 lines and share Data & Minutes",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "OSTEST3",
    planShortDescription: "Incompatible Plan",
    planDescription: "test incompatibility between device and plan",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "DISCNEW3",
    planShortDescription: "DISCNEW3",
    planDescription: "DISCNEW3",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "DISCNEW2",
    planShortDescription: "DISCNEW2",
    planDescription: "DISCNEW2",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "DISCNEW1",
    planShortDescription: "DISCNEW11",
    planDescription: "DISCNEW11",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "TOATE",
    planShortDescription: "Pack cu de toate  ",
    planDescription: "Pack cu de toate ",
    packageCode: "", //doesnt change
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 40,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "TOATEF",
    planShortDescription: "De toate feature ",
    planDescription: "De toate feature",
    packageCode: "", //doesnt change
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 40,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "TOATEF2",
    planShortDescription: "Toate feature 2 ",
    planDescription: "Toate feature 2",
    packageCode: "", //doesnt change
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 40,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "TOATEF3",
    planShortDescription: "Toate feature 3 ",
    planDescription: "Toate feature 3",
    packageCode: "", //doesnt change
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 40,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
  {
    planCode: "SHARINGBD",
    planShortDescription: "LG + Sponsor disc test",
    planDescription: "LG + Sponsor disc test",
    packageCode: "",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
  },
];

const DeviceMockup = () => {
  const { getItems } = useSelector((state) => state.items);
  const dispatch = useDispatch();
  const [cartId, setCartId] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [currentDevice, setCurrentDevice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
  });

  const changePlan = async (index) => {
    setLoading(true);
    const newItem = {
      ...currentDevice,
      ...plans[index],
    };
    try {
      await FacadeMockup.POST("/checkout", {
        cartId: cartId,
        items: [newItem],
      });
      window.top.postMessage(newItem, "*");
    } catch (error) {
      setLoading(false);
      setModal({ isOpen: true, message: <GetErrorDescription error={error} /> });
    }
  };

  useEffect(() => {
    const parseParams = () => {
      const temp = window.location.search.split("&");
      setCartId(temp[0].split("=")[1]);
      setDeviceId(temp[1].split("=")[1]);
    };

    if (window.location.search) parseParams();
  }, []);

  useEffect(() => {
    if (getItems.items.length === 0 && !getItems.loading) {
      dispatch(getDevices());
    }

    if (deviceId) {
      const found = getItems.items.find((item) => item.itemId == deviceId);
      setCurrentDevice(found);
    }
  }, [deviceId, dispatch, getItems]);

  if (!currentDevice || loading) {
    return (
      <Flex
        sx={{
          width: "100%",
          height: "20rem",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}>
        <Spinner />
      </Flex>
    );
  }

  const clearModal = () => setModal({ isOpen: false, message: "" });
  return (
    <Flex
      px="10rem"
      bg="WhiteSmoke"
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "10px",
      }}>
      <StyledModalMessage
        isOpen={modal.isOpen}
        message={modal.message}
        onRequestClose={clearModal}
        type="error">
        <Button variant="simple-action" onClick={clearModal}>
          Ok
        </Button>
      </StyledModalMessage>

      <Flex my="4rem" sx={{ width: "100%", alignItems: "center" }}>
        <Text ml="auto" mr="auto" sx={{ fontSize: "4xl", fontWeight: "medium" }}>
          Choose a different plan
        </Text>
      </Flex>

      <Flex
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "2rem" }}>
          {plans.map((plan, index) => (
            <Flex
              onClick={() => changePlan(index)}
              bg="white"
              key={index}
              px="1.5rem"
              py="2rem"
              sx={{
                border: "1px solid",
                borderColor: "border",
                borderRadius: "0.5rem",
                width: "18rem",
                height: "20rem",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "space-between",
                ":hover": {
                  borderColor: "cardBorderHoverColor",
                },
              }}>
              <Text
                sx={{
                  fontWeight: "semibold",
                  fontSize: "xl",
                  textAlign: "center",
                }}>
                {plan.planShortDescription}
              </Text>
              <Text
                sx={{
                  fontSize: "sm",
                  height: "8rem",
                }}>
                {plan.planDescription}
              </Text>
              <Text
                sx={{
                  fontWeight: "semibold",
                  fontSize: "2xl",
                }}>
                ${plan.prices[1].price.toFixed(2)}
              </Text>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Flex>
  );
};

export default DeviceMockup;
