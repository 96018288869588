import Modal from "../modals/Modal";

const TransparentModal = Modal.styled`
  height: fit-content;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: transparent;
  width: 300px;
  overflow: auto;
  transition: opacity ease 500ms;
`;

export default TransparentModal;
