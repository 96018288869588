import React, { useLayoutEffect, useRef, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Flex, Text } from "@theme-ui/components";
import {
  HIDDEN_ATTRIBUTES,
  PRICE_TERMS_UNIT,
  PRICE_TYPE,
  PERSONAL_INFORMATION_FLOW,
  PACKAGE_MODIFIED,
  PRICING_OPTION,
  EXTRA_INFO,
} from "../../common/Constants";
import { formatPhoneNo, getExtraInfoValues } from "../../common/StringUtilities";
import Amount from "../../components/amount";
import CollapsibleHeader from "../../components/collapsible-header/CollapsibleHeader";
import { Heading5 } from "../../components/heading";
import { OSIcon } from "../../components/icons";
import { useWindowSize } from "../../hooks";
import * as PlanUtils from "../device-and-plan/additional-services/PlanUtils";
import { getNoOfDeferredMonths } from "../../components/plan-price-offer/PlanPriceOffer";
import DeferredDiscounts from "../device-and-plan/bundle-items/DeferredDiscounts";
import dayjs from "dayjs";
import AmountDescription from "../../components/amount-description";

const DeviceAndPlanSummary = ({ ...props }) => {
  const { summaryData } = props;
  const intl = useIntl();
  const windowSize = useWindowSize();
  const [collapsed, setCollapsed] = useState(false);
  const [hrTrianglePosition, setHrTrianglePosition] = useState(459);
  const [hrWidth, setHrWidth] = useState(0);
  const summaryContainer = useRef(null);

  useLayoutEffect(() => {
    setHrTrianglePosition(summaryContainer.current.offsetWidth / 4);
    setHrWidth(
      summaryContainer && summaryContainer.current ? summaryContainer.current.offsetWidth : 0
    );
  }, [windowSize.width]);

  const getItemAttributes = (itemAttributes) => {
    if (itemAttributes && itemAttributes.length > 0) {
      return itemAttributes
        .filter((attr) => !HIDDEN_ATTRIBUTES.includes(attr.code))
        .map((attr) => attr.value)
        .join(", ");
    }
    return "";
  };

  const buildDeviceAmountComponent = (prices) => {
    const oneTimePrice = prices.find((price) => price.type === PRICE_TYPE.ONE_TIME);
    const recurringPrice = prices.find((price) => price.type === PRICE_TYPE.RECURRING);

    return (
      <Flex
        sx={{
          justifyContent: "flex-end",
          alignItems: "start",
        }}>
        {recurringPrice && (
          <Amount
            amount={recurringPrice.price + recurringPrice.tax}
            isMonthly={recurringPrice}
            description={
              recurringPrice.termsAmount > 0 && (
                <AmountDescription
                  pricingOption={summaryData.pricingOption}
                  termsAmount={recurringPrice.termsAmount}
                />
              )
            }
            color="ternary"
          />
        )}
        {recurringPrice && oneTimePrice && <OSIcon name="plus" color="primary" />}
        {oneTimePrice && (
          <Amount
            amount={oneTimePrice.price + oneTimePrice.tax}
            description={intl.formatMessage({ id: "lbl.upfront-payment" })}
            color="ternary"
          />
        )}
      </Flex>
    );
  };

  const buildOldDeviceAmountComponent = (prices) => {
    const oldEasyTabPrice = prices.find((price) => price.type === PRICE_TYPE.OLD_INSTALLMENT);

    return (
      oldEasyTabPrice && (
        <Flex sx={{ flexDirection: "column" }}>
          <Heading5 mt={!summaryData.deviceShortDesc && "none"}>
            {intl.formatMessage({ id: "lbl.old-device" })}
            {isDeviceWithTax && (
              <Text variant="subtitle1" pl={2}>
                {`(${intl.formatMessage({ id: "lbl.tax-included" })})`}
              </Text>
            )}
          </Heading5>

          <Flex sx={{ justifyContent: "space-between" }}>
            <Text variant="summaryValue">
              <FormattedMessage
                textComponent="span"
                id="lbl.old-easy-tab"
                values={{
                  sup: (chunks) => (
                    <Text
                      as="sup"
                      mr={0}
                      sx={{ display: "inline", fontSize: "45%", color: "inherit" }}>
                      {chunks}
                    </Text>
                  ),
                }}
              />
            </Text>
            <Text sx={{ justifyContent: "flex-end" }}>
              <Amount
                amount={oldEasyTabPrice.price}
                color="ternary"
                description={intl.formatMessage({ id: "lbl.upfront-payment" })}
              />
            </Text>
          </Flex>
        </Flex>
      )
    );
  };

  const prepareServicesContent = (key, text, amount, monthlyAmount, oneTimeCharge) => {
    return (
      <Flex sx={{ justifyContent: "space-between", padding: "3px" }} key={key}>
        <Text variant="summaryValue" sx={{ minWidth: "50%", pr: 3 }}>
          {text}
        </Text>
        <Amount
          amount={amount}
          color="ternary"
          isMonthly={monthlyAmount}
          description={oneTimeCharge && intl.formatMessage({ id: "lbl.one-time" })}
        />
      </Flex>
    );
  };

  const buildPlanAmountComponent = (prices) => {
    const recurringPrice = prices.find((price) => price.type === PRICE_TYPE.PLAN_RECURRING);
    const oneTimePrice = prices.find((price) => price.type === PRICE_TYPE.PLAN_ONE_TIME);
    const isFullPrice = !recurringPrice && oneTimePrice;
    const isMonthlyPrice =
      !isFullPrice && recurringPrice && recurringPrice.termsUnit === PRICE_TERMS_UNIT.MONTHS;

    return (
      (isFullPrice && <Amount amount={oneTimePrice.price} color="ternary" />) ||
      (isMonthlyPrice && (
        <Amount
          amount={recurringPrice.price}
          isMonthly={isMonthlyPrice}
          description={
            recurringPrice.termsAmount > 0 &&
            intl.formatMessage(
              { id: "lbl.for-x-months" },
              { noOfMonths: recurringPrice.termsAmount }
            )
          }
          color="ternary"
        />
      ))
    );
  };

  const buildDeferredDiscountsComponent = (prices) => {
    return (
      prices && (
        <Flex>
          <DeferredDiscounts prices={prices} />
        </Flex>
      )
    );
  };

  const { isDeviceWithTax, isPlanWithTax, isSpecialServicesWithTax, isAdditionalServicesWithTax } =
    useMemo(() => {
      const recurringPrice = summaryData.prices.find(
        (price) => price.type === PRICE_TYPE.PLAN_RECURRING
      );
      const oneTimePrice = summaryData.prices.find(
        (price) => price.type === PRICE_TYPE.PLAN_ONE_TIME
      );
      const e911 = summaryData.prices.find((price) => price.type === PRICE_TYPE.PLAN_E911);
      const planConnectionFee = summaryData.prices.find(
        (price) => price.type === PRICE_TYPE.PLAN_CONNECTION_FEE
      );
      const planServiceDiscounts = summaryData.prices
        .filter(
          (price) =>
            (price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT ||
              price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING) &&
            price.price !== 0
        )
        .filter((price) => getNoOfDeferredMonths(price) == 0);
      const additionalRecurringPrice = summaryData.prices.find(
        (price) => price.type === PRICE_TYPE.PLAN_ADDITIONAL_RECURRING
      );
      const additionalOneTimePrice = summaryData.prices.find(
        (price) => price.type === PRICE_TYPE.PLAN_ADDITIONAL_ONE_TIME
      );

      let isPlanWithTax =
        (recurringPrice && recurringPrice.tax > 0) || (oneTimePrice && oneTimePrice.tax > 0);
      let isSpecialServicesWithTax =
        (e911 && e911.tax > 0) ||
        (planConnectionFee && planConnectionFee.tax > 0) ||
        (planServiceDiscounts && planServiceDiscounts.find((price) => price.tax !== 0));
      let isAdditionalServicesWithTax =
        (additionalRecurringPrice && additionalRecurringPrice.tax !== 0) ||
        (additionalOneTimePrice && additionalOneTimePrice.tax !== 0);

      let devicePrices = summaryData.prices.filter(
        (price) =>
          (PRICE_TYPE.ONE_TIME === price.type || PRICE_TYPE.RECURRING === price.type) &&
          price.price !== 0
      );
      let isDeviceTaxFree =
        devicePrices.length === 0 ||
        (devicePrices[0].extraInfo &&
          devicePrices[0].extraInfo.length > 0 &&
          Object.fromEntries(devicePrices[0].extraInfo.split(";").map((elem) => elem.split("=")))[
            "IS_TAX_FREE"
          ] === "Y");
      return {
        isDeviceWithTax: !isDeviceTaxFree,
        isPlanWithTax: isPlanWithTax,
        isSpecialServicesWithTax: isSpecialServicesWithTax,
        isAdditionalServicesWithTax: isAdditionalServicesWithTax,
      };
    }, [summaryData.prices]);

  const prepareAdditionalServicesContent = (product) => {
    let additionalServicesContent = [];

    product.services
      .filter((service) => service.visible)
      .filter((service) => !service.mandatory)
      .filter((service) => service.description)
      .filter((service) => service.instances.find((instance) => instance.selected))
      .forEach((service, index) => {
        additionalServicesContent.push(
          prepareServicesContent(
            service.code + index,
            service.invoiceDescription,
            (service.rcFee > 0 && service.rcFee) || (service.setupFee > 0 && service.setupFee),
            service.rcFee > 0
          )
        );
      });
    product.subproducts
      .filter((subProduct) => PlanUtils.canDisplayProduct(subProduct))
      .forEach((subProduct) => {
        additionalServicesContent.push(prepareAdditionalServicesContent(subProduct));
      });

    return additionalServicesContent;
  };

  const prepareSpecialServicesContent = (prices) => {
    let specialServicesContent = [];
    const e911 = prices.find((price) => price.type === PRICE_TYPE.PLAN_E911);
    const planOneTime = prices.find((price) => price.type === PRICE_TYPE.PLAN_CONNECTION_FEE);
    const planServiceDiscounts = prices
      .filter(
        (price) =>
          (price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT ||
            price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING) &&
          price.price !== 0
      )
      .filter((price) => getNoOfDeferredMonths(price) == 0);

    if (e911) {
      specialServicesContent.push(
        prepareServicesContent(
          e911.type,
          e911.description ? e911.description : "E911",
          e911.price > 0 && e911.price,
          true
        )
      );
    }

    if (planOneTime) {
      specialServicesContent.push(
        prepareServicesContent(
          planOneTime.type,
          planOneTime.description ? planOneTime.description : "Connection",
          planOneTime.price > 0 && planOneTime.price,
          false,
          true
        )
      );
    }

    if (planServiceDiscounts && planServiceDiscounts.length > 0) {
      planServiceDiscounts.map((discount) =>
        specialServicesContent.push(
          prepareServicesContent(
            discount.type,
            discount.description,
            discount.price,
            discount.termsUnit === PRICE_TERMS_UNIT.MONTHS,
            discount.termsUnit !== PRICE_TERMS_UNIT.MONTHS
          )
        )
      );
    }

    return specialServicesContent;
  };

  const getEasyTabFlexEndDate = (prices) => {
    const recuringPrice = prices.find((price) => price.type === PRICE_TYPE.RECURRING);
    if (
      recuringPrice?.extraInfo &&
      getExtraInfoValues(recuringPrice.extraInfo)[EXTRA_INFO.FLEX_END_DATE_PERIOD]
    ) {
      return dayjs(
        getExtraInfoValues(recuringPrice.extraInfo)[EXTRA_INFO.FLEX_END_DATE_PERIOD]
      ).format("LL");
    }
    return null;
  };

  const additionalServicesContent = prepareAdditionalServicesContent(summaryData.product);
  const specialServicesContent = prepareSpecialServicesContent(summaryData.prices);
  const shouldDisplayAdditionalServices = additionalServicesContent.flat().length > 0;
  const shouldDisplaySpecialServices = specialServicesContent.flat().length > 0;
  const hugPlanChanged =
    summaryData.flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE &&
    summaryData.product.packageModified === PACKAGE_MODIFIED.PACKAGE_CHANGED;

  return (
    <Box ref={summaryContainer} mb={!collapsed && 6}>
      <CollapsibleHeader
        primaryTitle={
          <Flex
            sx={{
              flexDirection: [
                "column",
                "column",
                summaryData.temporaryNumber && summaryData.bundleDescription && collapsed
                  ? "column"
                  : "row",
              ],
            }}>
            <Flex sx={{ flexDirection: ["column", "row", "row"] }}>
              {summaryData.bundleDescription && (
                <Text
                  mr={4}
                  sx={{
                    overflowWrap: "break-word",
                  }}>{`${summaryData.bundleDescription}`}</Text>
              )}
              <Text>{`${formatPhoneNo(summaryData.phoneNumber, "-")}`}</Text>
            </Flex>
            <Text sx={{ fontWeight: "normal" }}>
              {summaryData.temporaryNumber &&
                `(${intl.formatMessage({ id: "lbl.temporary-no" })} ${formatPhoneNo(
                  summaryData.temporaryNumber,
                  "-"
                )})`}
            </Text>
          </Flex>
        }
        headerDetails={[
          summaryData.deviceShortDesc
            ? `${summaryData.deviceShortDesc} ${getItemAttributes(summaryData.deviceAttributes)}`
            : "",
          summaryData.planShortDesc,
        ]}
        hrWidth={hrWidth}
        hrTrianglePosition={hrTrianglePosition}
        collapsed={collapsed}
        onCollapsed={() => setCollapsed(!collapsed)}
      />

      {!collapsed && (
        <Flex
          sx={{
            flexDirection: ["column", "column", "row"],
            justifyContent: "flex-start",
            px: [8, 8, 9],
            gap: [8, 8, 10, 14],
          }}>
          <Box sx={{ width: ["100%", "100%", "43.33%", "33.33%"] }}>
            {summaryData.deviceShortDesc && (
              <Flex
                sx={{
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}>
                <Heading5 mt="none">
                  {intl.formatMessage({
                    id:
                      summaryData.flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE
                        ? "lbl.new-device"
                        : "lbl.device",
                  })}
                  {isDeviceWithTax && (
                    <Text variant="subtitle1" pl={2}>
                      {`(${intl.formatMessage({ id: "lbl.tax-included" })})`}
                    </Text>
                  )}
                </Heading5>

                <Flex
                  sx={{
                    display: "grid",
                    flexDirection: "row",
                    gap: 4,
                    gridTemplateColumns: ["1fr 1fr", "1.2fr 0.8fr", "0.85fr 1.15fr"],
                  }}>
                  <Flex sx={{ flexDirection: "column" }}>
                    <Text variant="summaryValue">{summaryData.deviceShortDesc}</Text>
                    <Text variant="summaryValue">
                      {getItemAttributes(summaryData.deviceAttributes)}
                    </Text>
                  </Flex>
                  {buildDeviceAmountComponent(summaryData.prices)}
                </Flex>
                {summaryData.pricingOption === PRICING_OPTION.EASYTABFLEX &&
                  getEasyTabFlexEndDate(summaryData.prices) && (
                    <Flex mt={4}>
                      <Text variant="summaryLabel">
                        <FormattedMessage
                          id="lbl.easyTab-flex-return-date"
                          values={{
                            sup: (chunks) => (
                              <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%" }}>
                                {chunks}
                              </Text>
                            ),
                            date: getEasyTabFlexEndDate(summaryData.prices),
                          }}
                        />
                      </Text>
                    </Flex>
                  )}
              </Flex>
            )}
            {buildOldDeviceAmountComponent(summaryData.prices)}
            <Flex sx={{ flexDirection: "column" }}>
              <Heading5 mt={!summaryData.deviceShortDesc && "none"}>
                {intl.formatMessage({ id: hugPlanChanged ? "lbl.new-plan" : "lbl.plan" })}
                {isPlanWithTax && (
                  <Text variant="subtitle1" pl={2}>
                    {`(${intl.formatMessage({ id: "lbl.plus-tax" })})`}
                  </Text>
                )}
              </Heading5>

              <Flex sx={{ justifyContent: "space-between" }}>
                <Text variant="summaryValue">{summaryData.planShortDesc}</Text>
                <Text sx={{ justifyContent: "flex-end" }}>
                  {buildPlanAmountComponent(summaryData.prices)}
                </Text>
              </Flex>
              {hugPlanChanged && (
                <Flex mt={4}>
                  <Text variant="summaryLabel">
                    {intl.formatMessage({ id: "lbl.plan-change-info" })}
                  </Text>
                </Flex>
              )}
              {buildDeferredDiscountsComponent(summaryData.prices)}
            </Flex>
          </Box>
          <Box sx={{ width: ["100%", "100%", "56.66%"] }}>
            <Flex
              sx={{
                justifyContent: "space-between",
                flexDirection: ["column", "column", "row"],
                gap: [8, 8, 8],
              }}>
              {shouldDisplaySpecialServices && (
                <Flex
                  sx={{
                    flex: "0 0 50%",
                    flexDirection: "column",
                  }}>
                  <Heading5 mt="none">
                    {intl.formatMessage({ id: "header.fees-and-discounts" })}
                    {isSpecialServicesWithTax && (
                      <Text variant="subtitle1" pl={2}>
                        {`(${intl.formatMessage({ id: "lbl.plus-tax" })})`}
                      </Text>
                    )}
                  </Heading5>
                  <Box>{specialServicesContent}</Box>
                </Flex>
              )}
              {shouldDisplayAdditionalServices && (
                <Flex
                  sx={{
                    flex: "0 0 50%",
                    flexDirection: "column",
                  }}>
                  <Heading5 mt="none">
                    {intl.formatMessage({ id: "header.additional-services" })}
                    {isAdditionalServicesWithTax && (
                      <Text variant="subtitle1" pl={2}>
                        {`(${intl.formatMessage({ id: "lbl.plus-tax" })})`}
                      </Text>
                    )}
                  </Heading5>
                  {additionalServicesContent}
                </Flex>
              )}
            </Flex>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default DeviceAndPlanSummary;
