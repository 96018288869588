import _ from "lodash";
import { ComponentType, NONE_ME_SRV_CODE } from "../../../common/Constants";
import { Flex, Text } from "@theme-ui/components";

/**
 * Filter out the services that shouldn't be displayed.
 * @param {Array<Service>} serviceArray The services array.
 * @returns {Array<Service>} Return a new array containing only the services that should be displayed.
 **/
export function getServicesForDisplay(serviceArray) {
  let validServices = [];

  _.each(serviceArray, (service) => {
    if (isMultipleInstanceService(service)) {
      const nrOfInstancesLeft = getNumberOfInstancesLeft(service);

      if (
        canDisplayService(service, false) &&
        (nrOfInstancesLeft > 0 || nrOfInstancesLeft === -1)
      ) {
        validServices.push(service);
      }
    } else {
      const serviceId = _.keys(service.instances)[0];
      const isActive = service.instances[serviceId] && service.instances[serviceId].status;
      if (canDisplayService(service, isActive) || isAServiceWithEditableFeatures(service)) {
        validServices.push(service);
      }
    }
  });

  return validServices;
}

/**
 * Decide if a service can be displayed or not based on defined criteria.
 * @param {Object} service The service object.
 * @param {Boolean} isActive The service active flag.
 * @returns {Boolean} True if all conditions are met, so the service card can be displayed.
 **/
export function canDisplayService(service, isActive) {
  let isNoneRadioOption = service.type === ComponentType.RADIO && service.code === NONE_ME_SRV_CODE;
  // skip displaying mandatory or not visible or none mutual exclusive services
  if (service.mandatory || !service.visible || isNoneRadioOption) {
    return false;
  }

  // skip displaying services that can't be modified
  const canBeModified = service.addPermission || service.editPermission;
  if (!canBeModified) {
    return false;
  }

  return isActive || service.addPermission;
}

function isAServiceWithEditableFeatures(service) {
  let isSomeFeatureVisible = service.instances.some(
    (instance) =>
      instance.featuresGroups.length > 0 &&
      instance.featuresGroups.some((fg) => fg.visible && fg.features.some((feat) => feat.visible))
  );

  return (
    !service.visible &&
    service.instances[0].selected &&
    !isMultipleInstanceService(service) &&
    isSomeFeatureVisible
  );
}

/**
 * Decide if a service cannot be disabled based on defined criteria.
 * @param {Object} service The service object.
 * @param {Boolean} wasInitiallyActive The service active flag.
 * @returns {Boolean} True if all conditions are met, so the service cannot be disabled.
 **/
export function cannotDisableService(service, wasInitiallyActive) {
  const displayAsDisabled = service.addPermission === true && service.editPermission === false;
  const notValidCombination = service.addPermission === false && service.editPermission === true;

  return wasInitiallyActive === true && (displayAsDisabled || notValidCombination);
}

/**
 * Decide if a product can be displayed or not based on defined criteria.
 * @param {Object} product The product object.
 * @returns {Boolean} True if all conditions are met, so the product card can be displayed.
 **/
export function canDisplayProduct(product) {
  return product.visible || subProductHasVisibleServicesOrFeatures(product);
}

/**
 * True if is a multiple instance service.
 * @param {Object} service The service object.
 * @returns {Boolean} True if is a multiple instance service.
 **/
export function isMultipleInstanceService(service) {
  return (
    service.type !== ComponentType.RADIO &&
    (service.maxNumberOfInstances === -1 || service.maxNumberOfInstances > 1)
  );
}

export function getCurrentNoOfInstances(service) {
  let currentNumberOfInstances = 0;
  _.each(service.instances, (value, key) => {
    if (_.toNumber(key) > 0) currentNumberOfInstances++;
  });
  return currentNumberOfInstances;
}

/**
 * Get the number of service instances left until the maximum number will be reached.
 * @param {Object} service The service object.
 * @returns {Number} Number of service instances left.
 **/
export function getNumberOfInstancesLeft(service) {
  if (service.maxNumberOfInstances === -1) return service.maxNumberOfInstances;

  let currentNumberOfInstances = getCurrentNoOfInstances(service);

  return _.toNumber(service.maxNumberOfInstances) - currentNumberOfInstances;
}

export function subProductHasVisibleServicesOrFeatures(subproduct) {
  return (
    subproduct.selected &&
    (subproduct.services.find((service) => {
      return canDisplayService(service) || isAServiceWithEditableFeatures(service);
    }) ||
      subproduct.subproducts.find((subSubProduct) => {
        return subProductHasVisibleServicesOrFeatures(subSubProduct);
      }))
  );
}

export function getCharges(product) {
  let setupFees = 0;
  let rcFees = 0;
  if (!product) {
    return { setupFees, rcFees };
  }
  for (let service of product.services) {
    let noOfInstances = service.instances?.filter((instance) => instance.selected).length;
    rcFees += service.rcFee * noOfInstances;
    setupFees += service.setupFee * noOfInstances;
  }
  for (let subProduct of product.subproducts?.filter((p) => p.selected)) {
    let subProdFees = getCharges(subProduct);
    setupFees += subProdFees.setupFees;
    rcFees += subProdFees.rcFees;
  }

  return { setupFees, rcFees };
}

export function groupSubProducts(subProducts) {
  const subProductGroups = _.groupBy(subProducts, (subProduct) => subProduct.type);
  let subProductGroupsMap = new Map();
  _.each(subProductGroups, (subProducts, key) => {
    subProductGroupsMap.set(
      key,
      subProducts.filter((p) => canDisplayProduct(p))
    );
  });
  return subProductGroupsMap;
}

export function groupAllServicesByProduct(product) {
  let subProductGroupsMap = new Map();
  subProductGroupsMap.set(
    product.code,
    product.services.filter((service) => service.type === "RADIO")
  );
  return subProductGroupsMap;
}

export function groupServices(services) {
  const serviceGroups = _.groupBy(services, (service) => service.type);
  let serviceGroupsMap = new Map();
  _.each(serviceGroups, (services, key) => {
    if (services.length > 0) {
      serviceGroupsMap.set(key, services);
    }
  });
  return serviceGroupsMap;
}

export function getOsDescriptionDetails(serviceOsDescription) {
  let serviceDetails = [];
  const serviceOsDescriptionDetails = serviceOsDescription.split("|");

  for (let i = 0; i < serviceOsDescriptionDetails.length; i++) {
    serviceDetails.push(<>{serviceOsDescriptionDetails[i]}</>);
  }

  return (
    <Flex sx={{ flexDirection: "column", flex: 1, gap: "0.3rem" }}>
      {serviceDetails.map((serviceDetail, index) => (
        <Text key={index} pr={5} variant={"lineDescription"} sx={{ textAlign: "left" }}>
          {serviceDetail}
        </Text>
      ))}
    </Flex>
  );
}
