import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FacadeAPI } from "../../../rest/RestClient";

const INITIAL_STATE = {
  computeTax: {
    loading: false,
    success: false,
    error: null,
    response: null,
  },
};

export const computeTax = createAsyncThunk("tax", async (amount) => {
  return await FacadeAPI.POST("/tax", { amount: amount });
});

const taxSlice = createSlice({
  name: "tax",
  initialState: INITIAL_STATE,
  extraReducers: {
    [computeTax.pending]: (state) => {
      state.computeTax.loading = true;
      state.computeTax.success = false;
      state.computeTax.error = null;
      state.computeTax.response = null;
    },
    [computeTax.fulfilled]: (state, action) => {
      state.computeTax.loading = false;
      state.computeTax.success = true;
      state.computeTax.response = action.payload;
    },
    [computeTax.rejected]: (state, action) => {
      state.computeTax.loading = false;
      state.computeTax.success = false;
      state.computeTax.error = action.error;
    },
  },
});

export default taxSlice.reducer;
