import React from "react";
import { Box } from "@theme-ui/components";
const Error = ({ color = "svgStrokePrimary", size = "iconLarge", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 54"
    sx={{ width: size, height: size, stroke: color, FlexShrink: 0 }}
    {...props}>
    <rect width="54" height="54" fill="none" />
    <g transform="translate(6 6)">
      <g opacity="0.4">
        <circle
          cx="20.5"
          cy="20.5"
          r="20.5"
          fill="#fff"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <line
        x1="14"
        y2="14"
        transform="translate(13.308 13.307)"
        fill="#fff"
        stroke="#e81849"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        x2="14"
        y2="14"
        transform="translate(13.308 13.307)"
        fill="#fff"
        stroke="#e81849"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </Box>
);

export default Error;
