import React from "react";
import { Box } from "@theme-ui/components";

const OutOfStock = ({ color = "svgStrokeSecondary", size = "iconSmall", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 16.628"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <g transform="translate(0.5 0.5)">
      <g>
        <g transform="translate(-896.619 -685.969)" opacity="0.4">
          <g transform="translate(896.119 685.969)">
            <path
              d="M14,0V9.737H0V0"
              transform="translate(2 5.891)"
              fill="#fff"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              d="M17,4,14.8,0H2.2L0,4Z"
              transform="translate(0.5)"
              fill="#fff"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <rect
              width="17"
              height="2"
              transform="translate(0.5 4)"
              fill="#fff"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
        <path
          d="M12011,5841.38l2.324-2.323,2.322-2.324"
          transform="translate(-12004.617 -5828.625)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1"
        />
        <path
          d="M0,4.647,2.324,2.324,4.647,0"
          transform="translate(11.028 8.106) rotate(90)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </Box>
);

export default OutOfStock;
